import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import { routes } from '@/router';
import { useLang } from '@/hooks';

function NeedAuthModal(): JSX.Element {
  const showExpiredUserModal = useSelector((st) => st.user.showExpiredUserModal);
  const { user: { setShowExpiredUserModal, logOut } } = useDispatch();
  const history = useHistory();
  const { getTranslation, keys } = useLang();

  const onCancel = () => {
    setShowExpiredUserModal(false);
  };

  const onOk = () => {
    logOut();
    setShowExpiredUserModal(false);
    history.push(routes.logIn.path);
  };

  return (
    <Modal
      visible={showExpiredUserModal}
      onCancel={onCancel}
      onOk={onOk}
      title={getTranslation(keys.user_expired_title)}
    >
      <p>{getTranslation(keys.user_expired_text)}</p>
    </Modal>
  );
}

export default NeedAuthModal;
