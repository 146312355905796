import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useService } from '@/hooks';
import { objKeysOnlyWithVal } from '@/helpers';

export interface Props {
  isGrowthShown?: boolean;
}

export function useContainer(props: Props) {
  const filterData = useSelector((st) => st.compareCompaniesFilter);
  const { get } = useService();
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [tablesData, setTablesData] = useState({});

  const namedResponse = useCallback((res, target) => ({ data: res.data.results, target }), []);

  useEffect(() => {
    const requestTargets = [
      {
        type: 'sector',
        url: 'finance/sectors/ratios',
        target: filterData.sectorId,
        query: objKeysOnlyWithVal({
          category: filterData.categoryIds.length ? filterData.categoryIds : null,
        }),
      },
    ];

    if (filterData.industryId) {
      requestTargets.push({
        type: 'industry',
        url: 'finance/industries/ratios',
        target: filterData.industryId,
        query: objKeysOnlyWithVal({
          sector: [filterData.sectorId],
          category: filterData.categoryIds.length ? filterData.categoryIds : null,
        }),
      });
    }

    if (filterData.sphereId) {
      requestTargets.push({
        type: 'sphere',
        url: 'finance/spheres/ratios',
        target: filterData.sphereId,
        query: objKeysOnlyWithVal({
          sector: [filterData.sectorId],
          industry: [filterData.industryId],
          category: filterData.categoryIds.length ? filterData.categoryIds : null,
        }),
      });
    }

    setLoading(true);

    Promise.all(requestTargets.map((item) => get({ url: item.url, query: item.query })
      .then((res) => namedResponse(res, item))))
      .then((res) => {
        setRawData(res);
        setLoading(false);
      });
  }, [filterData.sectorId, filterData.categoryIds, filterData.industryId, filterData.sphereId]);

  useEffect(() => {
    let filtered = [];

    // select specific sectors
    rawData.forEach((item) => {
      filtered.push(item.data.find((sector) => sector.id === item.target.target));
    });

    // select specific year and if growth selected
    filtered = filtered.map((item) => {
      const newItem = { ...item };
      let ratios;
      const ratiosByYear = newItem.ratios[filterData.year] || {};

      if (props.isGrowthShown) {
        ratios = ratiosByYear['Growth Rate'];
      } else {
        delete ratiosByYear['Growth Rate'];
        ratios = ratiosByYear;
      }

      return {
        ...item,
        ratios: ratios || {},
      };
    });

    // filter if specific ratio and growth selected
    if (props.isGrowthShown && filterData.growth && filterData.growth !== 'ALL') {
      filtered = filtered.map((item) => {
        const ratios = {};

        Object.keys(item.ratios).forEach((ratio) => {
          if (ratio === filterData.growth) {
            ratios[ratio] = item.ratios[ratio];
          }
        });


        // filter if some records selected
        if (filterData.growthRecords.length) {
          const ratioKey = Object.keys(ratios)[0];
          const ratio = {};

          Object.keys(ratios[ratioKey]).forEach((record) => {
            if (filterData.growthRecords.includes(record)) {
              ratio[record] = ratios[ratioKey][record];
            }
          });

          ratios[ratioKey] = ratio;
        }

        return {
          ...item,
          ratios,
        };
      });
    } else if (props.isGrowthShown && !filterData.growth) {
      filtered = [];
    }

    // filter if specific ratio and growth NOT selected
    if (!props.isGrowthShown && filterData.ratio && filterData.ratio !== 'ALL') {
      filtered = filtered.map((item) => {
        const ratios = {};

        Object.keys(item.ratios).forEach((ratio) => {
          if (ratio === filterData.ratio) {
            ratios[ratio] = item.ratios[ratio];
          }
        });

        // filter if some records selected
        if (filterData.ratioRecords.length) {
          const ratioKey = Object.keys(ratios)[0];
          const ratio = {};

          Object.keys(ratios[ratioKey]).forEach((record) => {
            if (filterData.ratioRecords.includes(record)) {
              ratio[record] = ratios[ratioKey][record];
            }
          });

          ratios[ratioKey] = ratio;
        }

        return {
          ...item,
          ratios,
        };
      });
    } else if (!props.isGrowthShown && filterData.ratio === 'CLEARED') {
      filtered = [];
    }

    // generate table columns
    const cols = [];

    // uncomment if you want check side by side record match
    // cols.push({
    //   dataIndex: 'record',
    //   title: 'RECORD',
    // })

    filtered.forEach((item) => {
      cols.push({
        dataIndex: item.id,
        title: item.title,
      });
    });

    setColumns(cols);

    // prepare data for tables
    const ratiosBySector = {};

    filtered.forEach((item) => {
      ratiosBySector[item.id] = item.ratios;
    });


    // prepare data for tables
    const tables = {};

    // table blueprint
    Object.keys(ratiosBySector).forEach((sectorId) => {
      Object.keys(ratiosBySector[sectorId]).forEach((ratio) => {
        tables[ratio] = {};

        Object.keys(ratiosBySector[sectorId][ratio]).forEach((ratioRecord) => {
          tables[ratio][ratioRecord] = { record: ratioRecord };
        });
      });
    });

    // fill tables with data
    Object.keys(ratiosBySector).forEach((sectorId) => {
      Object.keys(ratiosBySector[sectorId]).forEach((ratio) => {
        Object.keys(ratiosBySector[sectorId][ratio]).forEach((ratioRecord) => {
          tables[ratio][ratioRecord] = {
            ...tables[ratio][ratioRecord],
            [sectorId]: ratiosBySector[sectorId][ratio][ratioRecord],
          };
        });
      });
    });

    setTablesData(tables);
  }, [rawData, filterData, props.isGrowthShown]);

  return {
    loading,
    columns,
    tablesData,
  };
}
