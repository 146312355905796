import { sessionKeys, sessionDuration } from '@/constants';

interface State {
  token: string;
  refreshToken: string;
  isAuthorized: boolean;
  showNeedAuthModal: boolean;
  showExpiredUserModal: boolean;
  whenSendRefreshToken: number;
}

interface Reducers {
  setAuthInfo: (state: State, payload: { token: string; refreshToken: string }) => State;
  setShowNeedAuthModal: (state: State, payload: boolean) => State;
  setShowExpiredUserModal: (state: State, payload: boolean) => State;
  logOut: (state: State) => State;
}

const user = {
  state: {
    token: sessionStorage.getItem(sessionKeys.USER_TOKEN),
    refreshToken: sessionStorage.getItem(sessionKeys.USER_REFRESH_TOKEN),
    isAuthorized: !!sessionStorage.getItem(sessionKeys.USER_TOKEN) && !!sessionStorage.getItem(sessionKeys.USER_REFRESH_TOKEN),
    showNeedAuthModal: false,
    showExpiredUserModal: false,
    whenSendRefreshToken: Number(sessionStorage.getItem(sessionKeys.WHEN_SEND_REFRESH_TOKEN)),
  } as State,
  reducers: {
    setAuthInfo(state, payload) {
      const whenSendRefreshToken = Date.now() + sessionDuration;

      sessionStorage.setItem(sessionKeys.USER_TOKEN, payload.token);
      sessionStorage.setItem(sessionKeys.USER_REFRESH_TOKEN, payload.refreshToken);
      sessionStorage.setItem(sessionKeys.WHEN_SEND_REFRESH_TOKEN, String(whenSendRefreshToken));

      return {
        ...state,
        ...payload,
        isAuthorized: true,
        showNeedAuthModal: false,
        whenSendRefreshToken,
      };
    },
    logOut(state) {
      sessionStorage.removeItem(sessionKeys.USER_TOKEN);
      sessionStorage.removeItem(sessionKeys.USER_REFRESH_TOKEN);

      return {
        ...state,
        token: null,
        refreshToken: null,
        isAuthorized: false,
        whenSendRefreshToken: null,
      };
    },
    setShowNeedAuthModal(state, payload) {
      return {
        ...state,
        showNeedAuthModal: payload,
      };
    },
    setShowExpiredUserModal(state, payload) {
      return {
        ...state,
        showExpiredUserModal: payload,
      };
    },
  } as Reducers,
};

export default user;
