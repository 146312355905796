import * as React from 'react';
import { Table } from 'antd';

import { useContainer } from './container';
import { Layout } from '../components';

const FoundersDirectors = (): JSX.Element => {
  const {
    directors, directorsCols, founders, foundersCols,
  } = useContainer();

  return (
    <Layout>
      <Table columns={foundersCols} dataSource={founders} pagination={false} />
      <br />
      <Table columns={directorsCols} dataSource={directors} pagination={false} />
    </Layout>
  );
};

export default FoundersDirectors;
