import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { comparisonType } from '@/@types/general';
import { useLang, useQuery } from '@/hooks';
import { urlWithQuery } from '@/helpers';
import { routes } from '@/router';

export interface Props {
  isDataLoading: boolean;
  ratiosData: Record<string, any>;
  selectedYears: Array<Record<string, any>>;
  selectedRecords: Array<string>;
  isGrowthRatiosShown: boolean;
  activeRatioName: string;
}

interface Container {
  columns: Array<Record<string, any>>;
  containerTableData: Array<Record<string, any>>;
  byIndustryTableData: Array<Record<string, any>>;
  extendedTableData: Record<string, any>;
  general: any;
}

export const useContainer = (props: Props): Container => {
  const { getTranslation, keys } = useLang();
  const location = useLocation();
  const { query } = useQuery();
  const general = useSelector((st) => st.general);
  const [columns, setColumns] = React.useState([]);
  const [containerTableData, setContainerTableData] = React.useState([]);
  const [byIndustryTableData, setByIndustryTableData] = React.useState([]);
  const [extendedTableData, setExtendedTableData] = React.useState({});

  const colRenderForIndustry = (text, record) => {
    if (!record.resultFor) {
      return text;
    }

    const filters = query.filters ? JSON.parse(query.filters) : {};

    if (record.resultFor === 'sphere') {
      const companiesPath = urlWithQuery(routes.companies.path, { ...filters, sphere: [record.id] });

      return <Link to={companiesPath}>{text}</Link>;
    }

    filters[record.resultFor] = [record.id];
    const filterOnRecordClick = `${location.pathname}?filters=${JSON.stringify(filters)}`;

    return <Link to={filterOnRecordClick}>{text}</Link>;
  };

  // set columns
  React.useEffect(() => {
    if (general.comparisonType === comparisonType.BY_YEARS) {
      setColumns([
        {
          dataIndex: 'record', title: getTranslation(keys.sectorSlashRatio), render: colRenderForIndustry, width: 300, fixed: 'left',
        },
        ...props.selectedYears.map((year) => ({ dataIndex: year.value, title: year.title, width: 100 })),
      ]);
    } else {
      setColumns([
        {
          dataIndex: 'record', title: getTranslation(keys.record), render: colRenderForIndustry, width: 300, fixed: 'left',
        },
        ...props.selectedRecords.map((record) => ({ dataIndex: record, title: record, width: 200 })),
      ]);
    }
  }, [props.selectedYears, props.selectedRecords, general.comparisonType, query.filters]);

  // set data for container table
  React.useEffect(() => {
    if (props.ratiosData.data) {
      setContainerTableData(props.ratiosData.data.map((item) => ({
        ...item, record: item.title, key: item.id, ratios: item.ratios,
      })));
    }
  }, [props.ratiosData, columns]);

  // set data for extended table
  React.useEffect(() => {
    if (props.ratiosData.data) {
      let filteredData = props.ratiosData.data;

      // filter by year
      filteredData = filteredData.map((item) => {
        const ratios = {};
        const years = props.selectedYears.map((y) => String(y.value));

        Object.keys(item.ratios).forEach((year) => {
          if (years.includes(year)) {
            ratios[year] = item.ratios[year];
          }
        });

        return {
          ...item,
          ratios,
        };
      });

      // filter by growth rates
      filteredData = filteredData.map((item) => {
        const ratios = {};
        if (props.isGrowthRatiosShown) {
          Object.keys(item.ratios).forEach((year) => {
            ratios[year] = item.ratios[year]['Growth Rate'];
          });
        } else {
          Object.keys(item.ratios).forEach((year) => {
            ratios[year] = {};

            Object.keys(item.ratios[year]).forEach((ratio) => {
              if (ratio !== 'Growth Rate') {
                ratios[year][ratio] = item.ratios[year][ratio];
              }
            });
          });
        }

        return {
          ...item,
          ratios,
        };
      });

      // filter and keep only selected ratio data
      filteredData = filteredData.map((item) => {
        const ratios = {};

        Object.keys(item.ratios).forEach((year) => {
          ratios[year] = {};

          Object.keys(item.ratios[year]).forEach((ratio) => {
            if (ratio.toUpperCase() === props.activeRatioName) {
              ratios[year] = item.ratios[year][ratio];
            }
          });
        });

        return {
          ...item,
          ratios,
        };
      });

      // filter by selected records
      filteredData = filteredData.map((item) => {
        const ratios = {};

        Object.keys(item.ratios).forEach((year) => {
          ratios[year] = {};

          Object.keys(item.ratios[year]).forEach((record) => {
            if (props.selectedRecords.includes(record)) {
              ratios[year][record] = item.ratios[year][record];
            }
          });
        });

        return {
          ...item,
          ratios,
        };
      });

      // prepare data based on comparison type
      if (general.comparisonType === comparisonType.BY_INDUSTRY) {
        const preparedData = {};
        filteredData.forEach((item) => {
          const year = Object.keys(item.ratios)[0];

          if (item.ratios[year]) {
            preparedData[item.id] = { ...item.ratios[year], record: item.title };
          }
        });

        const preparedDataSet = [];

        Object.keys(preparedData).forEach((recordId) => {
          const containerData = containerTableData.find((item) => String(item.id) === recordId);

          if (containerData) {
            preparedDataSet.push({
              ...preparedData[recordId], resultFor: containerData.resultFor, id: recordId, key: recordId,
            });
          }
        });

        setByIndustryTableData(preparedDataSet);
      } else {
        const preparedData = {};
        const preparedDataSet = {};

        filteredData.forEach((item) => {
          preparedData[item.id] = item.ratios;
        });

        Object.keys(preparedData).forEach((recordId) => {
          const dataByYear = [];
          const colData = {};

          Object.keys(preparedData[recordId]).forEach((year) => {
            Object.keys(preparedData[recordId][year]).forEach((record) => {
              dataByYear.push({ [year]: preparedData[recordId][year][record], record });
            });
          });

          dataByYear.forEach((item) => {
            colData[item.record] = { ...colData[item.record], ...item };
          });

          preparedDataSet[recordId] = Object.values(colData);
        });

        setExtendedTableData(preparedDataSet);
      }
    }
  }, [props.ratiosData, props.selectedYears, props.isGrowthRatiosShown, props.activeRatioName, props.selectedRecords, general.comparisonType, columns, containerTableData]);

  return {
    columns,
    containerTableData,
    extendedTableData,
    general,
    byIndustryTableData,
  };
};
