const objKeysOnlyWithVal = (obj: Record<string, any>): Record<string, any> => {
  const keys = Object.keys(obj);
  const finalObj = {};

  keys.forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      finalObj[key] = obj[key];
    }
  });

  return finalObj;
};

export default objKeysOnlyWithVal;
