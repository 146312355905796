import * as React from 'react';
import {
  Form, Select, Row, Col, Button,
} from 'antd';

import { useLang } from '@/hooks';

import { useContainer } from './container';

function IndustrySelector() {
  const {
    form,
    filterOptions,
    filterData,
    setSector,
    setIndustry,
    setSphere,
    onAdd,
    setCategory,
  } = useContainer();
  const { getTranslation, keys } = useLang();

  return (
    <Form form={form} layout="vertical">
      <Row gutter={10}>
        <Col span={6}>
          <Form.Item label={getTranslation(keys.sector)}>
            <Select onChange={setSector} value={filterData?.sector?.id} allowClear>
              {
                filterOptions.sectors.map(({ title, id }) => (
                  <Select.Option value={id} key={id}>
                    { title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={getTranslation(keys.industry)}>
            <Select onChange={setIndustry} value={filterData?.industry?.id} disabled={!filterData.sector} allowClear>
              {
                filterOptions.industries.map(({ title, id }) => (
                  <Select.Option value={id} key={id}>
                    { title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={getTranslation(keys.sphere)}>
            <Select onChange={setSphere} value={filterData?.sphere?.id} disabled={!filterData.industry} allowClear>
              {
                filterOptions.spheres.map(({ title, id }) => (
                  <Select.Option value={id} key={id}>
                    { title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={getTranslation(keys.category)}>
            <Select onChange={setCategory} allowClear mode="multiple">
              {
                filterOptions.categories.map(({ title, id }) => (
                  <Select.Option value={id} key={id}>
                    { title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={6}>
          <Button
            type="primary"
            disabled={Object.keys(filterData).length === 0 || (filterData.category && Object.keys(filterData).length === 1)}
            onClick={onAdd}
          >
            {getTranslation(keys.add)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default IndustrySelector;
