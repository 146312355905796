import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useLang, useQuery, useService } from '@/hooks';
import { routes } from '@/router';
import { urlWithQuery, objKeysOnlyWithVal } from '@/helpers';
import { Button } from 'antd';

export interface Props {
  onSelect?: (Array) => void;
  onUnselect?: (industry) => void;
  selectedIndustries?: { id: string }[];
  onRecordClick?: (record) => void;
}

export const useContainer = (props: Props) => {
  const history = useHistory();
  const { get } = useService();
  const { useEffect, useState } = React;
  const { getTranslation, keys } = useLang();
  const { query, updateQuery } = useQuery();
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const setDataForTable = ({ data: { results } }, type): void => {
    setDataSource(results.map((r) => ({ ...r, type })));

    setIsLoading(false);
  };

  useEffect(() => {
    console.log(query.filters ? JSON.parse(query.filters) : {})
    console.log(query.filters)
    setFilters(query.filters ? JSON.parse(query.filters) : {});
  }, [query.filters]);

  useEffect(() => {
    setIsLoading(true);

    if (filters.industries) {
      get({ url: 'finance/spheres', query: objKeysOnlyWithVal({ industry: filters.industries, category: filters.category,sector: filters.sector }) })
        .then((res) => {
          const result = { ...res };
          console.log(result)
          if (filters.sphere) {
            result.data.results = result.data.results.filter((record) => filters.sphere.includes(record.id));
          }
          setDataForTable(result, 'sphere');
        })
        .catch(() => { setIsLoading(false); });
    } else if (filters.sector) {
      get({ url: 'finance/industries', query: objKeysOnlyWithVal({ sector: filters.sector, category: filters.category }) })
        .then((res) => setDataForTable(res, 'industries'))
        .catch(() => { setIsLoading(false); });
    } else {
      get({ url: 'finance/sectors', query: objKeysOnlyWithVal({ category: filters.category }) })
        .then((res) => setDataForTable(res, 'sector'))
        .catch(() => { setIsLoading(false); });
    }
  }, [JSON.stringify(filters)]);

  const columns = [
    {
      title: getTranslation(keys.record),
      dataIndex: 'title',
      render: (value, record): JSX.Element => {
        if (record.type !== 'sphere') {
          const updatedFilters = { ...filters };
          updatedFilters[record.type] = [record.id];
          const onClick = () => {
            updateQuery({ target: 'filters', value: JSON.stringify(updatedFilters) });

            if (props.onRecordClick) {
              props.onRecordClick(record);
            }
          };

          return <a onClick={onClick}>{value}</a>;
        }

        const companiesPath = urlWithQuery(routes.companies.path, { ...filters, sphere: [record.id] });
        console.log(companiesPath)


        return <a onClick={() =>  history.push(companiesPath)}>{value}</a>;
      },
    },
    {
      title: getTranslation(keys.revenue),
      dataIndex: 'revenue',
    },
    {
      title: getTranslation(keys.ebitda_margin),
      dataIndex: 'ebitda_margin',
    },
    {
      title: getTranslation(keys.net_profit_margin),
      dataIndex: 'net_profit_margin',
    },
    {
      title: getTranslation(keys.debt_equity),
      dataIndex: 'debt_equity',
    },
    {
      title: getTranslation(keys.roe),
      dataIndex: 'roe',
    },
    {
      title: getTranslation(keys.employees),
      dataIndex: 'empl',
    },
  ];

  const rowSelection = props.onSelect && props.selectedIndustries && props.onUnselect ? {
    renderCell: (checked, record) => {
      const industry = {
        id: record.id,
        title: record.title,
        type: record.type === 'industries' ? 'industry' : record.type,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const isSelected = !!props.selectedIndustries.find((item) => item.id === industry.id && item.type === industry.type);
      const onBtnClick = (): void => {
        if (isSelected) {
          props.onUnselect(industry);
        } else {
          props.onSelect(industry);
        }
      };

      return (
        <div onClick={onBtnClick}>
          {
            isSelected ? (
              <Button size="small" style={{ backgroundColor: 'gray', color: 'white', width: '10rem' }}>{getTranslation(keys.unselect)}</Button>
            ) : (
              <Button size="small" style={{ backgroundColor: '#008080', color: 'white', width: '10rem' }}>{getTranslation(keys.select)}</Button>
            )
          }
        </div>
      );
    },
  } : undefined;

  return {
    columns,
    dataSource,
    isLoading,
    rowSelection,
  };
};
