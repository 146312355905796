import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export interface Props {
  availableYears: Array<string>;
  selectedYears: Array<string>;
  setSelectedYears: (selectedYears) => void;
}

export const useContainer = () => {
  const { companyId, showConsolidated } = useSelector((st) => ({ companyId: st.company.companyId, showConsolidated: st.company.showConsolidated }));
  const [selectedPage, setSelectedPage] = useState<string>();
  const history = useHistory();

  const changeSelectedPage = useCallback((path) => {
    history.push(path);
  }, []);

  useEffect(() => {
    setSelectedPage(history.location.pathname);
  }, [history.location.pathname]);

  return {
    companyId,
    selectedPage,
    changeSelectedPage,
    showConsolidated,
  };
};
