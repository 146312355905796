import * as React from 'react';
import {
  Form,
  Select,
  Popover,
  Button,
  Row,
  Col,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { useLang } from '@/hooks';
import { useContainer, Props } from './container';

const Filters = ({ singleIndustry }: Props): JSX.Element => {
  const { getTranslation, keys } = useLang();
  const {
    form, onFilter, onFilterReset, onFormChange, formState, filterItemWidth, filterOptions,
  } = useContainer({ singleIndustry });

  return (
    <Form
      name="industries"
      layout="vertical"
      className="industries-filters"
      form={form}
      onValuesChange={onFormChange}
    >
      <Row gutter={10}>
        <Col span={filterItemWidth}>
          <Form.Item label={getTranslation(keys.sector)} name="sector">
            <Select mode={!singleIndustry ? 'multiple' : undefined}>
              {
                filterOptions.sector.map((item) => (
                  <Select.Option value={item.id} key={`${item.id}-${item.title}`}>
                    { item.title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={filterItemWidth}>
          <Form.Item label={getTranslation(keys.industries)} name="industries">
            <Select mode={!singleIndustry ? 'multiple' : undefined} disabled={!formState.sector || formState.sector.length !== 1}>
              {
                filterOptions.industries.map((item) => (
                  <Select.Option value={item.id} key={`${item.id}-${item.title}`}>
                    { item.title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={filterItemWidth}>
          <Form.Item label={getTranslation(keys.sphere)} name="sphere">
            <Select mode={!singleIndustry ? 'multiple' : undefined} disabled={!formState.industries || formState.industries.length !== 1}>
              {
                filterOptions.sphere.map((item) => (
                  <Select.Option value={item.id} key={`${item.id}-${item.title}`}>
                    { item.title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={filterItemWidth}>
          <Form.Item
            label={(
              <div>
                {getTranslation(keys.category)}
                <Popover content={<div>Info about categories</div>}>
                  <InfoCircleOutlined />
                </Popover>
              </div>
            )}
            name="category"
          >
            <Select mode="multiple">
              {
                filterOptions.category.map((item) => (
                  <Select.Option value={item.id} key={`${item.id}-${item.title}`}>
                    { item.title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={2}>
          <Button onClick={onFilterReset}>Reset</Button>
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={onFilter}>Filter</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Filters;
