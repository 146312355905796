import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Menu, Button, Layout, Select,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import logo from '@/assets/images/favicon.png';
import { routes } from '@/router';
import { useLang } from '@/hooks';
import { useContainer } from './container';
import './styles.less';

const Header = (): JSX.Element => {
  const { getTranslation, keys } = useLang();
  const { activePath, isUserAuthorized, onLogOut } = useContainer();
  const history = useHistory();
  const { data, active } = useSelector((st) => st.lang);
  const { lang: { changeLang } } = useDispatch();

  return (
    <Layout.Header className="main-layout-header">
      <img src={logo} alt="Ebit" className="main-layout-header__logo" />
      <Menu
        mode="horizontal"
        theme="dark"
        className="main-layout-header__nav"
        selectedKeys={[activePath]}
      >
        <Menu.Item key={routes.home.path}>
          <Link to={routes.home.path}>
            { getTranslation(keys.home) }
          </Link>
        </Menu.Item>
        <Menu.Item key={routes.companies.path}>
          <Link to={routes.companies.path}>
            { getTranslation(keys.companies) }
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={routes.industries.path}>
            { getTranslation(keys.industries) }
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={routes.compareAnalyse.routes.companyCompare.path}>
            { getTranslation(keys.compare_analyse) }
          </Link>
        </Menu.Item>
        {/* <Menu.Item> */}
        {/*  { getTranslation(keys.market_analyse) } */}
        {/* </Menu.Item> */}
        {/* <Menu.Item> */}
        {/*  { getTranslation(keys.benchmark) } */}
        {/* </Menu.Item> */}
        {/* <Menu.Item> */}
        {/*  { getTranslation(keys.rating) } */}
        {/* </Menu.Item> */}
        <Menu.Item>
          <Link to={routes.pricing.path}>
            { getTranslation(keys.prices) }
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={routes.charts.path}>
            { getTranslation(keys.charts) }
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={routes.contactSales.path}>
            { getTranslation(keys.contact_sales) }
          </Link>
        </Menu.Item>
      </Menu>
      <div className="main-layout-header__actions">
        <div className="main-layout-header__actions__auth">
          {
            isUserAuthorized ? (
              <Button size="small" onClick={onLogOut}>
                { getTranslation(keys.log_out) }
              </Button>
            ) : (
              <Button type="primary" size="small" onClick={() => history.push(routes.logIn.path)}>
                { getTranslation(keys.sign_in) }
              </Button>
            )
          }
          <Select defaultValue={active} size="small" onChange={changeLang}>
            {
              Object.keys(data).map((lang) => <Select.Option value={lang}>{lang.toUpperCase()}</Select.Option>)
            }
          </Select>
        </div>
      </div>
    </Layout.Header>
  );
};

export default Header;
