import { useSelector, useDispatch } from 'react-redux';

export const useContainer = () => {
  const { companyId, showConsolidated } = useSelector((st) => ({
    companyId: st.company.companyId,
    showConsolidated: st.company.showConsolidated,
  }));
  const { company: { toggleShowConsolidated } } = useDispatch();

  const generateKeyForSidebarItem = (route) => `${route.open({ id: companyId })}{${route.titleKey}}`;

  return {
    companyId,
    toggleShowConsolidated,
    showConsolidated,
    generateKeyForSidebarItem,
  };
};
