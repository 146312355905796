import * as React from 'react';
import { LazyExoticComponent, FunctionComponent } from 'react';

import { translationKeys, financialStatementsTypes, userTypes } from '@/constants';

export interface Route {
  path: string;
  component: LazyExoticComponent<FunctionComponent>;
  titleKey: string;
  open?: (args) => string;
  routes?: Record<string, Route>;
}

const routes = {
  home: {
    path: '/',
    component: React.lazy(() => import('@/wrapped/Home')),
    titleKey: translationKeys.home,
  } as Route,
  companies: {
    path: '/companies',
    component: React.lazy(() => import('@/wrapped/Companies')),
    titleKey: translationKeys.companies,
  } as Route,
  compareAnalyse: {
    path: '/compare',
    component: React.lazy(() => import('@/layouts/CompareAnalyse')),
    routes: {
      companyCompare: {
        path: '/compare/company',
        component: React.lazy(() => import('@/wrapped/CompareCompany')),
        titleKey: translationKeys.compare_company,
        open: ({ companyId }) => `/compare/company?selectedCompanies=[${companyId}]`,
      },
      industryCompare: {
        path: '/compare/industry',
        component: React.lazy(() => import('@/wrapped/CompareIndustry')),
        titleKey: translationKeys.compare_industry,
      },
    } as Record<string, Route>,
  },
  industries: {
    path: '/industries',
    component: React.lazy(() => import('@/wrapped/Industries')),
    routes: {
      ratios: {
        keyRatios: {
          path: '/industries/ratios/KEY RATIOS',
          open: ({ filters }): string => `/industries/ratios/KEY RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.key_ratios,
        },
        assetManagementRatio: {
          path: '/industries/ratios/ASSET MANAGEMENT RATIOS',
          open: ({ filters }): string => `/industries/ratios/ASSET MANAGEMENT RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.asset_management_ratio,
        },
        liquidityRatio: {
          path: '/industries/ratios/LIQUIDITY RATIOS',
          open: ({ filters }): string => `/industries/ratios/LIQUIDITY RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.liquidity_ratio,
        },
        leverageRatios: {
          path: '/industries/ratios/LEVERAGE RATIOS',
          open: ({ filters }): string => `/industries/ratios/LEVERAGE RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.leverage_ratios,
        },
        coverageRatios: {
          path: '/industries/ratios/COVERAGE RATIOS',
          open: ({ filters }): string => `/industries/ratios/COVERAGE RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.coverage_ratios,
        },
        profitabilityRatios: {
          path: '/industries/ratios/PROFITABILITY RATIOS',
          open: ({ filters }): string => `/industries/ratios/PROFITABILITY RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.profitability_ratios,
        },
        cashFlowRatios: {
          path: '/industries/ratios/CASH FLOW RATIOS',
          open: ({ filters }): string => `/industries/ratios/CASH FLOW RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.cash_flow_ratios,
        },
        solvencyRatios: {
          path: '/industries/ratios/SOLVENCY RATIOS',
          open: ({ filters }): string => `/industries/ratios/SOLVENCY RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.solvency_ratios,
        },
        verticalAnalysisOfBalanceSheet: {
          path: '/industries/ratios/Vertical Analysis of Balance Sheet',
          open: ({ filters }): string => `/industries/ratios/Vertical Analysis of Balance Sheet${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.vertical_analysis_of_balance_sheet,
        },
        verticalAnalysisOfProfitLoss: {
          path: '/industries/ratios/Vertical Analysis of Profit And Loss',
          open: ({ filters }): string => `/industries/ratios/Vertical Analysis of Profit And Loss${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.vertical_analysis_of_profit_loss,
        },
      } as Record<string, Route>,
      growthRate: {
        keyRatios: {
          path: '/industries/growth-rate/KEY RATIOS',
          open: ({ filters }): string => `/industries/growth-rate/KEY RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.key_ratios,
        },
        assetManagementRatio: {
          path: '/industries/growth-rate/ASSET MANAGEMENT RATIOS',
          open: ({ filters }): string => `/industries/growth-rate/ASSET MANAGEMENT RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.asset_management_ratio,
        },
        liquidityRatio: {
          path: '/industries/growth-rate/LIQUIDITY RATIOS',
          open: ({ filters }): string => `/industries/growth-rate/LIQUIDITY RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.liquidity_ratio,
        },
        leverageRatios: {
          path: '/industries/growth-rate/LEVERAGE RATIOS',
          open: ({ filters }): string => `/industries/growth-rate/LEVERAGE RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.leverage_ratios,
        },
        coverageRatios: {
          path: '/industries/growth-rate/COVERAGE RATIOS',
          open: ({ filters }): string => `/industries/growth-rate/COVERAGE RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.coverage_ratios,
        },
        profitabilityRatios: {
          path: '/industries/growth-rate/PROFITABILITY RATIOS',
          open: ({ filters }): string => `/industries/growth-rate/PROFITABILITY RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.profitability_ratios,
        },
        cashFlowRatios: {
          path: '/industries/growth-rate/CASH FLOW RATIOS',
          open: ({ filters }): string => `/industries/growth-rate/CASH FLOW RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.cash_flow_ratios,
        },
        solvencyRatios: {
          path: '/industries/growth-rate/SOLVENCY RATIOS',
          open: ({ filters }): string => `/industries/growth-rate/SOLVENCY RATIOS${filters ? `?filters=${filters}` : ''}`,
          component: React.lazy(() => import('@/wrapped/Industry/Ratios')),
          titleKey: translationKeys.solvency_ratios,
        },
      } as Record<string, Route>,
      financialStatements: {
        balance: {
          path: `/industries/financial-statements/${financialStatementsTypes['1'].key}`,
          component: React.lazy(() => import('@/wrapped/Industry/FinancialStatements')),
          titleKey: translationKeys.balance,
        },
        profitLoss: {
          path: `/industries/financial-statements/${financialStatementsTypes['2'].key}`,
          component: React.lazy(() => import('@/wrapped/Industry/FinancialStatements')),
          titleKey: translationKeys.profit_loss,
        },
        cashFlow: {
          path: `/industries/financial-statements/${financialStatementsTypes['3'].key}`,
          component: React.lazy(() => import('@/wrapped/Industry/FinancialStatements')),
          titleKey: translationKeys.cash_flow,
        },
        all: {
          path: '/industries/financial-statements',
          component: React.lazy(() => import('@/wrapped/Industry/FinancialStatements')),
          titleKey: translationKeys.all,
        },
      } as Record<string, Route>,
    },
  },
  company: {
    path: '/companies/:companyId',
    open: ({ id }): string => `/companies/${id}/profile/about`,
    component: React.lazy(() => import('@/wrapped/Company/Profile/About')),
    routes: {
      charts: {},
      ratios: {
        keyRatios: {
          path: '/companies/:companyId/ratios/KEY RATIOS',
          open: ({ id }): string => `/companies/${id}/ratios/KEY RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.key_ratios,
        },
        assetManagementRatio: {
          path: '/companies/:companyId/ratios/ASSET MANAGEMENT RATIOS',
          open: ({ id }): string => `/companies/${id}/ratios/ASSET MANAGEMENT RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.asset_management_ratio,
        },
        liquidityRatio: {
          path: '/companies/:companyId/ratios/LIQUIDITY RATIOS',
          open: ({ id }): string => `/companies/${id}/ratios/LIQUIDITY RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.liquidity_ratio,
        },
        leverageRatios: {
          path: '/companies/:companyId/ratios/LEVERAGE RATIOS',
          open: ({ id }): string => `/companies/${id}/ratios/LEVERAGE RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.leverage_ratios,
        },
        coverageRatios: {
          path: '/companies/:companyId/ratios/COVERAGE RATIOS',
          open: ({ id }): string => `/companies/${id}/ratios/COVERAGE RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.coverage_ratios,
        },
        profitabilityRatios: {
          path: '/companies/:companyId/ratios/PROFITABILITY RATIOS',
          open: ({ id }): string => `/companies/${id}/ratios/PROFITABILITY RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.profitability_ratios,
        },
        cashFlowRatios: {
          path: '/companies/:companyId/ratios/CASH FLOW RATIOS',
          open: ({ id }): string => `/companies/${id}/ratios/CASH FLOW RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.cash_flow_ratios,
        },
        solvencyRatios: {
          path: '/companies/:companyId/ratios/SOLVENCY RATIOS',
          open: ({ id }): string => `/companies/${id}/ratios/SOLVENCY RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.solvency_ratios,
        },
        verticalAnalysisOfBalanceSheet: {
          path: '/companies/:companyId/ratios/Vertical Analysis of Balance Sheet',
          open: ({ id }): string => `/companies/${id}/ratios/Vertical Analysis of Balance Sheet`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.vertical_analysis_of_balance_sheet,
        },
        verticalAnalysisOfProfitLoss: {
          path: '/companies/:companyId/ratios/Vertical Analysis of Profit And Loss',
          open: ({ id }): string => `/companies/${id}/ratios/Vertical Analysis of Profit And Loss`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.vertical_analysis_of_profit_loss,
        },
        all: {
          path: '/companies/:companyId/ratios/all',
          open: ({ id }): string => `/companies/${id}/ratios/all`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.all,
        },
      } as Record<string, Route>,
      growthRate: {
        keyRatios: {
          path: '/companies/:companyId/growth-rate/KEY RATIOS',
          open: ({ id }): string => `/companies/${id}/growth-rate/KEY RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.key_ratios,
        },
        assetManagementRatio: {
          path: '/companies/:companyId/growth-rate/ASSET MANAGEMENT RATIOS',
          open: ({ id }): string => `/companies/${id}/growth-rate/ASSET MANAGEMENT RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.asset_management_ratio,
        },
        liquidityRatio: {
          path: '/companies/:companyId/growth-rate/LIQUIDITY RATIOS',
          open: ({ id }): string => `/companies/${id}/growth-rate/LIQUIDITY RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.liquidity_ratio,
        },
        leverageRatios: {
          path: '/companies/:companyId/growth-rate/LEVERAGE RATIOS',
          open: ({ id }): string => `/companies/${id}/growth-rate/LEVERAGE RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.leverage_ratios,
        },
        coverageRatios: {
          path: '/companies/:companyId/growth-rate/COVERAGE RATIOS',
          open: ({ id }): string => `/companies/${id}/growth-rate/COVERAGE RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.coverage_ratios,
        },
        profitabilityRatios: {
          path: '/companies/:companyId/growth-rate/PROFITABILITY RATIOS',
          open: ({ id }): string => `/companies/${id}/growth-rate/PROFITABILITY RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.profitability_ratios,
        },
        cashFlowRatios: {
          path: '/companies/:companyId/growth-rate/CASH FLOW RATIOS',
          open: ({ id }): string => `/companies/${id}/growth-rate/CASH FLOW RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.cash_flow_ratios,
        },
        solvencyRatios: {
          path: '/companies/:companyId/growth-rate/SOLVENCY RATIOS',
          open: ({ id }): string => `/companies/${id}/growth-rate/SOLVENCY RATIOS`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.solvency_ratios,
        },
        all: {
          path: '/companies/:companyId/growth-rate/all',
          open: ({ id }): string => `/companies/${id}/growth-rate/all`,
          component: React.lazy(() => import('@/wrapped/Company/Ratios')),
          titleKey: translationKeys.all,
        },
      } as Record<string, Route>,
      financialStatements: {
        balance: {
          path: `/companies/:companyId/financial-statements/${financialStatementsTypes['1'].key}`,
          open: ({ id }): string => `/companies/${id}/financial-statements/${financialStatementsTypes['1'].key}`,
          component: React.lazy(() => import('@/wrapped/Company/FinancialStatements')),
          titleKey: translationKeys.balance,
        },
        profitLoss: {
          path: `/companies/:companyId/financial-statements/${financialStatementsTypes['2'].key}`,
          open: ({ id }): string => `/companies/${id}/financial-statements/${financialStatementsTypes['2'].key}`,
          component: React.lazy(() => import('@/wrapped/Company/FinancialStatements')),
          titleKey: translationKeys.profit_loss,
        },
        cashFlow: {
          path: `/companies/:companyId/financial-statements/${financialStatementsTypes['3'].key}`,
          open: ({ id }): string => `/companies/${id}/financial-statements/${financialStatementsTypes['3'].key}`,
          component: React.lazy(() => import('@/wrapped/Company/FinancialStatements')),
          titleKey: translationKeys.cash_flow,
        },
        all: {
          path: `/companies/:companyId/financial-statements/${financialStatementsTypes['0'].key}`,
          open: ({ id }): string => `/companies/${id}/financial-statements/${financialStatementsTypes['0'].key}`,
          component: React.lazy(() => import('@/wrapped/Company/FinancialStatements')),
          titleKey: translationKeys.all,
        },
      } as Record<string, Route>,
      profile: {
        about: {
          path: '/companies/:companyId/profile/about',
          open: ({ id }): string => `/companies/${id}/profile/about`,
          component: React.lazy(() => import('@/wrapped/Company/Profile/About')),
          titleKey: translationKeys.about,
        },
        affiliatesCompanies: {
          path: '/companies/:companyId/profile/affiliates-companies',
          open: ({ id }): string => `/companies/${id}/profile/affiliates-companies`,
          component: React.lazy(() => import('@/wrapped/Company/Profile/AffiliatesCompanies')),
          titleKey: translationKeys.affiliates_companies,
        },
        audit: {
          path: '/companies/:companyId/profile/audit',
          open: ({ id }): string => `/companies/${id}/profile/audit`,
          component: React.lazy(() => import('@/wrapped/Company/Profile/Audit')),
          titleKey: translationKeys.audit,
        },
        foundersDirectors: {
          path: '/companies/:companyId/profile/founders-directors',
          open: ({ id }): string => `/companies/${id}/profile/founders-directors`,
          component: React.lazy(() => import('@/wrapped/Company/Profile/FoundersDirectors')),
          titleKey: translationKeys.founders_directors,
        },
      } as Record<string, Route>,
    },
  },
  charts: {
    path: '/charts',
    titleKey: translationKeys.charts,
    routes: {
      main: {
        path: '/charts',
        component: React.lazy(() => import('@/components/ChartsMain')),
        titleKey: translationKeys.charts,
      },
      companies: {
        path: '/charts/companies',
        component: React.lazy(() => import('@/wrapped/CompaniesChart')),
        titleKey: translationKeys.companies_chart,
      },
      industries: {
        path: '/charts/industries',
        component: React.lazy(() => import('@/wrapped/IndustriesChart')),
        titleKey: translationKeys.industries_chart,
      },
      compare: {
        path: '/charts/compare',
        component: React.lazy(() => import('@/wrapped/CompareCharts')),
        titleKey: translationKeys.compare_analyse,
      },
    } as Record<string, Route>,
  } as Route,
  logIn: {
    path: '/log-in',
    component: React.lazy(() => import('@/wrapped/LogIn')),
    titleKey: translationKeys.log_in,
  } as Route,
  pricing: {
    path: '/prices',
    component: React.lazy(() => import('@/wrapped/Pricing')),
    titleKey: translationKeys.prices,
  } as Route,
  register: {
    path: '/register/:userType/:typeId',
    open: ({ userType = userTypes.STANDARD, typeId }): string => `/register/${userType}/${typeId}`,
    component: React.lazy(() => import('@/wrapped/Register')),
    titleKey: translationKeys.prices,
  } as Route,
  contactSales: {
    path: '/contact-sales',
    component: React.lazy(() => import('@/wrapped/ContactSales')),
    titleKey: translationKeys.prices,
  } as Route,
};

export default routes;
