import { useEffect, useState } from 'react';

import { useService } from '@/hooks';

export interface Props {
  selectedIndustries: [];
  filtersAndCompany: Record<string, any>;
}

export function useContainer(props: Props) {
  const { get } = useService();
  const { filtersAndCompany, selectedIndustries } = props;
  const [showCharts, setShowCharts] = useState(false);
  const [companyRawRatios, setCompanyRawRatios] = useState();
  const [industriesRawRatios, setIndustriesRawRatios] = useState<any>();
  const [companyRatios, setCompanyRatios] = useState<any>([]);
  const [industriesRatios, setIndustriesRatios] = useState<any>([]);

  const prepareIndustryRequest = (record) => {
    const target: any = {
      url: 'finance/sectors/ratios',
      query: {
        sector: [record.id],
      },
    };

    if (record.type === 'sphere') {
      target.url = 'finance/spheres/ratios';
      target.query = {
        ...target.query,
        sphere: [record.id],
      };
    } else if (record.type === 'industry') {
      target.url = 'finance/sectors/ratios';
      target.query = {
        ...target.query,
        industry: [record.id],
      };
    }

    if (record.category) {
      target.query.category = record.category.map((cat) => cat.id);
    }

    return {
      target,
      record,
    };
  };

  useEffect(() => {
    setShowCharts(!!filtersAndCompany && !!selectedIndustries);
  }, [filtersAndCompany, selectedIndustries]);

  useEffect(() => {
    if (showCharts) {
      get({
        url: 'reports/ratios',
        query: {
          company_id: filtersAndCompany.company.id,
          report_type: 1,
        },
      }).then(({ data: { results } }) => {
        setCompanyRawRatios(results);
      });

      const industriesPromises = selectedIndustries
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
        .filter((industry) => industry.type !== 'category')
        .map(prepareIndustryRequest)
        .map(({ record, target }) => get(target).then(({ data: { results } }) => ({ results, record })));

      Promise.all(industriesPromises).then((res) => {
        const preparedRaw = [];

        res.forEach((item) => {
          preparedRaw.push(item.results.find((industry) => industry.id === item.record.id));
        });

        setIndustriesRawRatios(preparedRaw);
      });
    }
  }, [filtersAndCompany, selectedIndustries, showCharts]);

  useEffect(() => {
    if (companyRawRatios && industriesRawRatios) {
      const preparedCompany = { name: filtersAndCompany.company.org_name };
      const filters = filtersAndCompany.filterValues;

      filters.years.forEach((year) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        preparedCompany[year] = companyRawRatios[year][filters.ratio][filters.ratioRecord];
      });

      const preparedIndustries = [];

      industriesRawRatios.forEach((industry) => {
        const data = { name: industry.title };

        filters.years.forEach((year) => {
          data[year] = industry.ratios[year][filters.ratio][filters.ratioRecord];
        });

        preparedIndustries.push(data);
      });

      setCompanyRatios([preparedCompany]);
      setIndustriesRatios(preparedIndustries);
    }
  }, [companyRawRatios, industriesRawRatios, filtersAndCompany]);

  return {
    showCharts,
    companyRatios,
    industriesRatios,
  };
}
