import * as React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

import { routes } from '@/router';
import { Header, NeedAuthModal, UserExpiredModal } from './components';

import { useContainer } from './container';

const Company = React.lazy(() => import('@/layouts/Company'));
const Industries = React.lazy(() => import('@/layouts/Industries'));
const CompareAnalyse = React.lazy(() => import('@/layouts/CompareAnalyse'));
const Charts = React.lazy(() => import('@/layouts/Charts'));

function Main(): JSX.Element {
  useContainer();

  return (
    <Layout>
      <Header />
      <NeedAuthModal />
      <UserExpiredModal />
      <Layout.Content>
        <Route path={routes.home.path} exact component={routes.home.component} />
        <Route path={routes.companies.path} exact component={routes.companies.component} />
        <Route path={routes.logIn.path} exact component={routes.logIn.component} />
        <Route path={routes.pricing.path} exact component={routes.pricing.component} />
        <Route path={routes.register.path} exact component={routes.register.component} />
        <Route path={routes.contactSales.path} exact component={routes.contactSales.component} />
        <Route path={routes.company.path}>
          <Company />
        </Route>
        <Route path={routes.industries.path}>
          <Industries />
        </Route>
        <Route path={routes.compareAnalyse.path}>
          <CompareAnalyse />
        </Route>
        <Route path={routes.charts.path}>
          <Charts />
        </Route>
      </Layout.Content>
    </Layout>
  );
}

export default Main;
