import * as React from 'react';
import { useSelector } from 'react-redux';

import { useLang, useService } from '@/hooks';

interface Container {
  columns: Array<Record<string, any>>;
  dataSource: Array<Record<string, any>>;
}

export const useContainer = (): Container => {
  const [dataSource, setDataSource] = React.useState([]);
  const { getTranslation, keys } = useLang();
  const { get } = useService();
  const { companyId } = useSelector((st) => ({ companyId: st.company.companyId }));

  const columns = [
    {
      title: getTranslation(keys.title),
      dataIndex: 'title',
    },
    {
      title: getTranslation(keys.category),
      dataIndex: 'category',
    },
    {
      title: getTranslation(keys.type_of_opinion),
      dataIndex: 'type_of_opinion',
      render: (val) => val.title,
    },
    {
      title: getTranslation(keys.year),
      dataIndex: 'year',
    },   
     {
      title: getTranslation(keys.document),
      dataIndex: 'link',
      render: val => <a target='_blank' rel="noopener noreferrer" href={val}>{"PDF"}</a>
    },
  ];

  React.useEffect(() => {
    get({ url: `finance/companies/${companyId}/auditors` }).then(({ data: { results } }) => {
      setDataSource(results);
    });
  }, [companyId]);

  return {
    columns,
    dataSource,
  };
};
