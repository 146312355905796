import * as React from 'react';
import { Row, Col, Collapse } from 'antd';

import { Sidebar } from '@/layouts/CompareAnalyse';

import { Filters, Table, SelectedCompanies } from './components';
import { useContainer, Props } from './container';
import './styles.less';

const Companies = ({ withCompare, onCompareCompaniesClick }: Props): JSX.Element => {
  const {
    onFilterReset, setFilterData, filterData, setSelectedCompanies, selectedCompanies,
  } = useContainer();

  return (
    <div className="companies-list">
      <Row className="companies-filters-container" style={{ marginLeft: 0, marginRight: 0 }} gutter={10}>
        { withCompare && <Col span={4}><Sidebar /></Col> }
        <Col span={withCompare ? 20 : 22} offset={withCompare ? 0 : 1}>
          <Collapse defaultActiveKey="filters">
            <Collapse.Panel key="filters" header="AddIndustry">
              <Filters
                onFilterReset={onFilterReset}
                onFilterSubmit={setFilterData}
              />
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="companies-table-container">
        <Col span={withCompare ? 24 : 22} offset={withCompare ? 0 : 1}>
          {
            selectedCompanies.length > 0 && (
              <SelectedCompanies
                setSelectedCompanies={setSelectedCompanies}
                onCompareCompaniesClick={onCompareCompaniesClick}
              />
            )
          }
          <br />
          <Table
            filterData={filterData}
            withCompare={withCompare}
            onSelectedCompaniesChange={setSelectedCompanies}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Companies;
