import * as React from 'react';
import {
  BrowserRouter, Switch,
} from 'react-router-dom';
import { Spin } from 'antd';

import { useContainer } from './container';

const Main = React.lazy(() => import('@/layouts/Main'));

const Router = (): JSX.Element => {
  useContainer();

  return (
    <BrowserRouter>
      <React.Suspense fallback={<Spin />}>
        <Switch>
          <Main />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default Router;
