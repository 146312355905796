import * as React from 'react';
import { Route } from 'react-router-dom';
import { Collapse, Layout } from 'antd';

import { routes } from '@/router';
import { renderRouteObj } from '@/helpers';
import { IndustriesFilters } from '@/components';
import { Sidebar } from './components';

const Industries = (): JSX.Element => (
  <Route path={routes.industries.path}>
    <Layout>
      <Route path={routes.industries.path}>
        <Sidebar />
      </Route>
      <Layout.Content>
        <Route path={routes.industries.path} exact>
          <Collapse defaultActiveKey="filters">
            <Collapse.Panel key="filters" header="AddIndustry">
              <IndustriesFilters />
            </Collapse.Panel>
          </Collapse>
        </Route>
        <Route path={routes.industries.routes.financialStatements.all.path}>
          <Collapse defaultActiveKey="statement_filters">
            <Collapse.Panel key="statement_filters" header="AddIndustry">
              <IndustriesFilters singleIndustry />
            </Collapse.Panel>
          </Collapse>
        </Route>
        <Route path={routes.industries.path} exact>
          <routes.industries.component />
        </Route>
        {
          renderRouteObj(routes.industries.routes.ratios)
        }
        {
          renderRouteObj(routes.industries.routes.growthRate)
        }
        {
          renderRouteObj(routes.industries.routes.financialStatements)
        }
      </Layout.Content>
    </Layout>
  </Route>
);

export default Industries;
