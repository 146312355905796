import * as React from 'react';

import { ChartsMain } from '@/components';

function Home(): JSX.Element {
  return (
    <div className="home-page">
      <ChartsMain />
    </div>
  );
}

export default Home;
