import { Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { useService } from '@/hooks';

export const useContainer = ({ onFormSubmit, maxSelectedYears, withCategory }) => {
  const [form] = Form.useForm();
  const { get } = useService();
  const [companiesForAutocomplete, setCompaniesForAutocomplete] = useState([]);
  const [ratios, setRatios] = useState([]);
  const [ratioRecords, setRatioRecords] = useState([]);
  const [selectedRatio, setSelectedRatio] = useState<string>();
  const [selectedRatioRecord, setSelectedRatioRecord] = useState<string>();
  const [years, setYears] = useState([]);
  const [categories, setCategories] = useState([]);
  const [disableYears, setDisableYear] = useState(false);

  const onAutocomplete = useCallback((q) => {
    if (q && q.length > 2) {
      get({ url: 'finance/companies', query: { q } }).then(({ data: { results } }) => {
        setCompaniesForAutocomplete(results.map(({ org_name }) => ({ value: org_name })));
      });
    }
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      get({ url: 'finance/companies', query: { q: values.q } }).then(({ data: { results } }) => {
        onFormSubmit({
          company: results.find((company) => company.org_name === values.q),
          filterValues: values,
        });
      });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }, [form]);

  const onRatioChange = (newVal) => {
    setSelectedRatio(newVal);
    setSelectedRatioRecord(undefined);
    form.setFieldsValue({ ...form.getFieldsValue, ratioRecord: undefined });

    get({ url: 'finance/ratios' })
      .then(({ data: { results } }) => {
        setRatioRecords(Object.keys(results[newVal]));
      });
  };

  const onFormValuesChange = ({ years }) => {
    if (years) {
      if (years.length === maxSelectedYears) {
        setDisableYear(true);
      } else {
        setDisableYear(false);
      }
    }
  };

  useEffect(() => {
    get({ url: 'finance/ratios' })
      .then(({ data: { results } }) => {
        const rawRatios = Object.keys(results);

        setRatios(rawRatios);
        setSelectedRatio(rawRatios[0]);
        setRatioRecords(Object.keys(results[rawRatios[0]]));
      });

    get({ url: 'finance/years' })
      .then(({ data: { results } }) => {
        setYears(results.map((item) => item));
      });
  }, []);

  useEffect(() => {
    get({ url: 'finance/categories' }).then(({ data: { results } }) => {
      setCategories(results);
    });
  }, [withCategory]);

  return {
    form,
    onAutocomplete,
    companiesForAutocomplete,
    onSubmit,
    ratios,
    ratioRecords,
    selectedRatio,
    selectedRatioRecord,
    setSelectedRatio,
    setSelectedRatioRecord,
    onRatioChange,
    years,
    onFormValuesChange,
    disableYears,
    categories,
  };
};
