import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise } from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { urlWithQuery } from '@/helpers';
import {
  API_HOST, NEED_AUTH_STATUS_CODE, sessionKeys, PACKAGE_EXPIRED_STATUS_CODE,
} from '@/constants';

interface MethodProps {
  url: string;
  query?: Record<string, any>;
  data?: any;
}

export interface Service {
  get?: ({ url, query }: MethodProps) => AxiosPromise;
  post?: ({ url, query, data }: MethodProps) => AxiosPromise;
  del?: ({ url, query }: MethodProps) => AxiosPromise;
  put?: ({ url, data, query }: MethodProps) => AxiosPromise;
}

const getHeaders = () => {
  const token = sessionStorage.getItem(sessionKeys.USER_TOKEN);

  if (!token) {
    return null;
  }

  return {
    Authorization: `Bearer ${token}`,
  };
};

const useService = (validateAuth = true): Service => {
  const lang = useSelector((st) => st.lang);
  const { user: { setShowNeedAuthModal, setShowExpiredUserModal } } = useDispatch();

  const errorHandler = (err) => {
    if (validateAuth) {
      if (err.response.status === NEED_AUTH_STATUS_CODE) {
        setShowNeedAuthModal(true);
      } else if (err.response.status === PACKAGE_EXPIRED_STATUS_CODE) {
        setShowExpiredUserModal(true);
      }
    }

    return Promise.reject(err);
  };

  const instance = (params?: AxiosRequestConfig): AxiosInstance => {
    const { method, data, ...rest } = params || {};

    return axios.create({
      baseURL: `${API_HOST}${lang.active}/api/`,
      ...rest,
    });
  };

  const get = ({ url, data, query }) => {
    const getInstance = instance();
    const withQuery = urlWithQuery(url, query);

    return getInstance({
      method: 'get', url: withQuery, data, headers: getHeaders(),
    }).catch(errorHandler);
  };
  const post = ({ url, data, query }) => {
    const postInstance = instance();
    const withQuery = urlWithQuery(url, query);

    return postInstance({
      method: 'post', url: withQuery, data, headers: getHeaders(),
    }).catch(errorHandler);
  };
  const del = ({ url, data, query }) => {
    const delInstance = instance();
    const withQuery = urlWithQuery(url, query);

    return delInstance({
      method: 'delete', url: withQuery, data, headers: getHeaders(),
    }).catch(errorHandler);
  };
  const put = ({ url, data, query }) => {
    const putInstance = instance();
    const withQuery = urlWithQuery(url, query);

    return putInstance({
      method: 'put', url: withQuery, data, headers: getHeaders(),
    }).catch(errorHandler);
  };

  return {
    get,
    post,
    del,
    put,
  };
};

export default useService;
