import * as React from 'react';

import { ColumnChart, LineChart } from '@/components';
import { useContainer, Props } from './container';

function Charts(props: Props): JSX.Element {
  const { ratios, filterValues } = props;

  if (!ratios || !filterValues) return null;
  const { showLine, chartData } = useContainer(props);

  if (!showLine) return <ColumnChart title={`${filterValues.q}, ${filterValues.ratio}, ${filterValues.ratioRecord}`} data={chartData} />;

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return <LineChart title={`${filterValues.q}, ${filterValues.ratio}, ${filterValues.ratioRecord}`} linesDataKeys={['value']} data={chartData} padding={{ left: 30, right: 30 }} />;
}

export default Charts;
