import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@/hooks';

export function useContainer() {
  const industries = useSelector(({ compareIndustries }) => compareIndustries.selectedIndustries);
  const {
    compareIndustries: {
      addIndustry,
      removeIndustry,
      clearIndustries,
    },
  } = useDispatch();
  const { query } = useQuery();
  const history = useHistory();
  const [backPath, setBackPath] = useState<string>();
  const [isCompareVisible, setIsCompareVisible] = useState(false);

  const onAddIndustryClick = (industry) => {
    addIndustry([industry]);
  };

  const onIndustryClick = (industry) => {
    const backQuery: any = {};

    if (industry.type === 'industries') {
      const filters = JSON.parse(query.filters);
      backQuery.sector = filters.sector;
    }

    setBackPath(`${history.location.pathname}?filters=${JSON.stringify(backQuery)}`);
  };

  const onBackClick = () => {
    const filters = JSON.parse(query.filters);

    if (!filters.industries) {
      setBackPath(null);
    }

    history.goBack();
  };

  const onCompareIndustriesClick = () => {
    setIsCompareVisible(!isCompareVisible);
  };
  const onAddIndustriesClick = () => {
    history.push(history.location.pathname);
    setIsCompareVisible(!isCompareVisible);
  };

  useEffect(() => () => {
    clearIndustries();
  }, []);

  return {
    addIndustry: onAddIndustryClick,
    removeIndustry,
    industries,
    onIndustryClick,
    onBackClick,
    backPath,
    isCompareVisible,
    onCompareIndustriesClick,
    onAddIndustriesClick,
  };
}
