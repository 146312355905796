import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { comparisonType } from '@/@types/general';

interface Container {
  comparisonType: comparisonType;
  setType: (type: comparisonType) => void;
}

export const useContainer = (): Container => {
  const { general: { setComparisonType } } = useDispatch();
  const general = useSelector((st) => st.general);

  useEffect(() => (): void => {
    setComparisonType(comparisonType.BY_YEARS);
  }, []);

  return {
    comparisonType: general.comparisonType,
    setType: setComparisonType,
  };
};
