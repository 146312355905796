import * as React from 'react';
import {
  Row, Col, PageHeader, Card,
} from 'antd';
import { RiseOutlined, FallOutlined } from '@ant-design/icons';

import { useLang } from '@/hooks';

import { MainTable, PieChart, ColumnChart } from '@/components';

import { useContainer } from './container';
import './styles.less';

function ChartsMain(): JSX.Element {
  const { getTranslation, keys } = useLang();
  const {
    annualCategoryData, annualCategoryTableData, annualSectorsData, annualSectorsTableData, annualSectorYear,
  } = useContainer();
  return (
    <div className="charts-main">
      <Col span={21} offset={1}>
        <PageHeader title={getTranslation(keys.charts_main_displayed_year_title)} ghost={false} />
      </Col>
      <Row gutter={10}>
        <Col span={7} offset={1}>
          <PieChart showPercent data={annualCategoryData.INCOME} innerRadius={40} title={getTranslation(keys.income)} />
        </Col>
        <Col span={7}>
          <PieChart showPercent data={annualCategoryData.NET_INCOME} innerRadius={40} title={getTranslation(keys.net_income)} />
        </Col>
        <Col span={7}>
          <PieChart showPercent data={annualCategoryData.NUMBER_OF_EMPLOYEES} innerRadius={40} title={getTranslation(keys.number_of_employees)} />
        </Col>
      </Row>
      <br />
      <Row gutter={10}>
        <Col span={7} offset={1}>
          <PieChart showPercent data={annualCategoryData.NUMBER_OF_COMPANIES} innerRadius={40} title={getTranslation(keys.number_of_companies)} />
        </Col>
        <Col span={14}>
          <MainTable size="small" columns={annualCategoryTableData.columns} dataSource={annualCategoryTableData.data} pagination={false} />
        </Col>
      </Row>
      <br />
      <Col span={21} offset={1}>
        <PageHeader title={getTranslation(keys.sectors_data)} ghost={false} />
      </Col>
      <Row gutter={10}>
        <Col span={10} offset={1}>
          <PieChart showPercent data={annualSectorsData.INCOME} title={getTranslation(keys.income)} />
        </Col>
        <Col span={10} offset={1}>
          <PieChart showPercent data={annualSectorsData.NET_INCOME} title={getTranslation(keys.net_income)} />
        </Col>
      </Row>
      <br />
      <Row gutter={10}>
        <Col span={21} offset={1}>
          <MainTable size="small" columns={annualSectorsTableData.columns} dataSource={annualSectorsTableData.incomeData} pagination={false} />
        </Col>
      </Row>
      <br />
      <Row gutter={10}>
        <Col span={10} offset={1}>
          <PieChart showPercent data={annualSectorsData.NUMBER_OF_EMPLOYEES} title={getTranslation(keys.number_of_employees)} />
        </Col>
        <Col span={10} offset={1}>
          <PieChart showPercent data={annualSectorsData.NUMBER_OF_COMPANIES} title={getTranslation(keys.number_of_companies)} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={21} offset={1}>
          <MainTable columns={annualSectorsTableData.columns} dataSource={annualSectorsTableData.infoData} pagination={false} />
        </Col>
      </Row>
      <br />
      <Col span={21} offset={1}>
        <PageHeader title={getTranslation(keys.charts_main_sector_data_by_year)} ghost={false} />
      </Col>
      <Row>
        <Col span={18} offset={3}>
          <ColumnChart data={annualSectorYear.ebitda_margin} showPercent title={getTranslation(keys.charts_main_ebitda_margin)} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={18} offset={3}>
          <ColumnChart data={annualSectorYear.net_profit_margin} showPercent title={getTranslation(keys.charts_main_net_profit_margin)} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={18} offset={3}>
          <ColumnChart data={annualSectorYear.roe} showPercent title={getTranslation(keys.charts_main_roe)} />
        </Col>
      </Row>
      <br />
      <Row gutter={10}>
        <Col span={10} offset={1}>
          <ColumnChart data={annualSectorYear.debt_equity} title={getTranslation(keys.charts_main_debt_equity)} />
        </Col>
        <Col span={10} offset={1}>
          <ColumnChart data={annualSectorYear.total_debt_to_ebitda} title={getTranslation(keys.charts_main_total_debt_to_ebitda)} />
        </Col>
      </Row>
      <br />
      <Row gutter={10}>
        <Col span={10} offset={1}>
          <ColumnChart data={annualSectorYear.total_debt_to_total_capitalization} title={getTranslation(keys.charts_main_total_debt_to_total_capitalization)} />
        </Col>
        <Col span={10} offset={1}>
          <ColumnChart data={annualSectorYear.dscr} title={getTranslation(keys.charts_main_dscr)} />
        </Col>
      </Row>
      {/* // incode dynamin */}
      {/* <br /> */}
      {/* <Col span={21} offset={1}> */}
      {/*  <PageHeader title={getTranslation(keys.income_dynamic)} ghost={false} /> */}
      {/* </Col> */}
      {/* <Row gutter={10}> */}
      {/*  <Col span={7} offset={1}> */}
      {/*    <ColumnChart data={incomeDynamic.income.data} title={getTranslation(keys.income_dynamic)} /> */}
      {/*  </Col> */}
      {/*  <Col span={2}> */}
      {/*    <Card style={{ textAlign: 'center' }}> */}
      {/*      { */}
      {/*            incomeDynamic.income.percent > 0 ? ( */}
      {/*              <RiseOutlined style={{ fontSize: '1.6rem', color: '#52c41a' }} /> */}
      {/*            ) : ( */}
      {/*              <FallOutlined style={{ fontSize: '1.6rem', color: '#eb2f96' }} /> */}
      {/*            ) */}
      {/*        } */}
      {/*      <span style={{ fontSize: '1.6rem', marginLeft: '0.5rem' }}>{ `${incomeDynamic.income.percent}%` }</span> */}
      {/*    </Card> */}
      {/*  </Col> */}
      {/*  <Col span={12}> */}
      {/*    <ColumnChart data={incomeDynamic.growthRates} title={getTranslation(keys.charts_main_income_dynamic_by_sector_and_year)} /> */}
      {/*  </Col> */}
      {/* </Row> */}
    </div>
  );
}

export default ChartsMain;
