import * as React from 'react';
import {
  Card, Button, Collapse, Row, Col,
} from 'antd';

import { Sidebar } from '@/layouts/CompareAnalyse';
import { IndustriesList, IndustrySelector, SelectedIndustries } from '@/components';
import { useLang } from '@/hooks';

import {
  DataFilters, RatiosTable, StatementsTable,
} from './components';
import { useContainer } from './container';

function CompareIndustry(): JSX.Element {
  const {
    addIndustry, industries, removeIndustry, onIndustryClick, onBackClick, backPath, isCompareVisible, onCompareIndustriesClick, onAddIndustriesClick,
  } = useContainer();
  const { getTranslation, keys } = useLang();

  if (isCompareVisible) {
    return (
      <>
        <Row gutter={10} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col span={4}>
            <Sidebar />
          </Col>
          <Col span={20}>
            <Collapse defaultActiveKey="filters">
              <Collapse.Panel key="filters" header="Filters">
                <DataFilters />
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
        <br />
        <SelectedIndustries onAddIndustriesClick={onAddIndustriesClick} />
        <br />
        <RatiosTable />
        <br />
        <RatiosTable isGrowthShown />
        <br />
        <StatementsTable />
      </>
    );
  }

  return (
    <div>
      <Row gutter={10}>
        <Col span={4}>
          <Sidebar />
        </Col>
        <Col span={20}>
          <Card>
            <IndustrySelector />
          </Card>
        </Col>
      </Row>
      <br />
      <SelectedIndustries onCompareIndustriesClick={onCompareIndustriesClick} />
      <br />
      {
        backPath && (
          <>
            <Card><Button onClick={onBackClick}>{getTranslation(keys.back)}</Button></Card>
            <br />
          </>
        )
      }
      <IndustriesList
        onSelect={addIndustry}
        onUnselect={removeIndustry}
        selectedIndustries={industries}
        onRecordClick={onIndustryClick}
      />
    </div>
  );
}

export default CompareIndustry;
