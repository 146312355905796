import * as React from 'react';
import { Layout as AntLayout, Tabs } from 'antd';

import { routes } from '@/router';
import { useLang } from '@/hooks';

import KeyHighlights from '../KeyHighlights';
import { Props, useContainer } from './container';
import './style.less';

const Layout = (props: Props): JSX.Element => {
  const { children } = props;
  const { companyId, openTab, activeTabKey } = useContainer();
  const tabRoutes = routes.company.routes.profile;
  const { getTranslation } = useLang();

  return (
    <AntLayout>
      <AntLayout.Content className="profile-content-container">
        <Tabs onChange={openTab} activeKey={activeTabKey} type="card">
          {
            Object.values(tabRoutes).map((route) => (
              <Tabs.TabPane
                tab={getTranslation(route.titleKey)}
                key={route.open({ id: companyId })}
              />
            ))
          }
        </Tabs>
        { children }
      </AntLayout.Content>
      <AntLayout.Sider style={{ backgroundColor: 'transparent' }} width={400}>
        <KeyHighlights />
      </AntLayout.Sider>
    </AntLayout>
  );
};

export default Layout;
