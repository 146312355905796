import * as React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

import { useLang } from '@/hooks';
import { routes } from '@/router';
import { useContainer } from './container';
import './styles.less';

const Sidebar = (): JSX.Element => {
  const { getTranslation, keys } = useLang();
  const { filters } = useContainer();

  return (
    <Layout.Sider
      width={250}
      className="industries-sidebar"
      theme="light"
    >
      <Menu>
        <Menu.SubMenu title={getTranslation(keys.ratios)}>
          {
            Object.values(routes.industries.routes.ratios).map((routeItem) => (
              <Menu.Item key={`${routeItem.path}-${routeItem.titleKey}`}>
                <Link to={routeItem.open({ filters })}>
                  { getTranslation(routeItem.titleKey) }
                </Link>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
        <Menu.SubMenu title={getTranslation(keys.growth_rate)}>
          {
            Object.values(routes.industries.routes.growthRate).map((routeItem) => (
              <Menu.Item key={`${routeItem.path}-${routeItem.titleKey}`}>
                <Link to={routeItem.open({ filters })}>
                  { getTranslation(routeItem.titleKey) }
                </Link>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
        <Menu.SubMenu title={getTranslation(keys.financial_statements)}>
          {
            Object.values(routes.industries.routes.financialStatements).map((routeItem) => (
              <Menu.Item key={`${routeItem.path}-${routeItem.titleKey}`}>
                <Link to={routeItem.path}>
                  { getTranslation(routeItem.titleKey) }
                </Link>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
        <Menu.Item>
          <Link to={routes.charts.path}>
            { getTranslation(keys.charts) }
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
