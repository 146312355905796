import { useState } from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useService } from '@/hooks';
import { routes } from '@/router';

export function useContainer() {
  const [form] = Form.useForm();
  const { post } = useService(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { user: { setAuthInfo } } = useDispatch();

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      setErrorMessage('');

      post({ url: 'auth/token', data: values })
        .then(({ data }) => {
          setAuthInfo({ token: data.access, refreshToken: data.refresh });
          setLoading(false);
          history.push(routes.home.path);
        })
        .catch(({ response: { data: { detail } } }) => {
          setErrorMessage(detail);
          setLoading(false);
        });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return {
    form,
    onSubmit,
    loading,
    errorMessage,
  };
}
