import * as React from 'react';

import { MainTable } from '@/components';

import { useContainer, Props } from './container';

function RatiosTable(props: Props) {
  const { columns, tablesData, loading } = useContainer(props);

  if (!Object.keys(tablesData).length) {
    return null;
  }

  return (
    <>
      {
        Object.keys(tablesData).sort().map((tableKey) => (
          <MainTable
            scroll={{ x: 500 }}
            columns={columns.map((col, i) => (i === 0 ? ({ ...col, title: tableKey }) : col))}
            pagination={false}
            dataSource={Object.values(tablesData[tableKey])}
            loading={loading}
            style={{ marginBottom: '0.9rem' }}
            size="small"
          />
        ))
      }
    </>
  );
}

export default RatiosTable;
