import * as React from 'react';

import { ColumnChart } from '@/components';

import { useContainer, Props } from './container';

function Charts(props: Props): JSX.Element {
  const { showCharts, companyRatios, industriesRatios } = useContainer(props);
  const { filtersAndCompany, selectedIndustries } = props;

  if (!showCharts) return null;

  return (
    <ColumnChart
      title={`${filtersAndCompany.company.org_name}, 
              ${filtersAndCompany.filterValues.ratio}, 
              ${filtersAndCompany.filterValues.ratioRecord}${selectedIndustries.map(({ title }, i) => i === 0 ? `, ${title}` : title).join(', ')}
              `}
      data={[...companyRatios, ...industriesRatios]}
      bars={filtersAndCompany.filterValues.years.map((y) => ({ dataKey: y }))}
      withLegend
    />
  );
}

export default Charts;
