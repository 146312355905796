import * as React from 'react';

import { MainTable } from '@/components';
import { comparisonType } from '@/@types/general';

import { DataExport } from './DataExport';
import { useContainer, Props } from './container';

const Table = (props: Props): JSX.Element => {
  const { isDataLoading } = props;
  const {
    columns, containerTableData, extendedTableData, general, byIndustryTableData,
  } = useContainer(props);

  if (general.comparisonType === comparisonType.BY_INDUSTRY) {
    return (
      <MainTable
        columns={columns}
        dataSource={byIndustryTableData}
        loading={isDataLoading}
        pagination={false}
        scroll={{ x: 1500 }}
      />
    );
  }


  return (
    <>
      <div className="data-export-clmn">
        <DataExport columns={columns} data={extendedTableData} pages={containerTableData} />
      </div>
      <MainTable
        columns={columns}
        dataSource={containerTableData}
        loading={isDataLoading}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <MainTable
              columns={columns}
              dataSource={extendedTableData[record.id]}
              loading={isDataLoading}
              pagination={false}
              showHeader={false}
            />
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
      />
    </>
  );
};

export default Table;
