type StatementId = 0 | 1 | 2 | 3;

interface State {
  sectorId?: number;
  industryId?: number;
  sphereId?: number;
  categoryIds: number[];
  ratio?: string;
  ratioRecords: string[];
  growth?: string;
  growthRecords: string[];
  statementId?: StatementId;
  year?: string;
}

interface Reducers {
  setSector: (state: State, payload?: number, record?: Record<string, any>) => State;
  setIndustry: (state: State, payload?: number, record?: Record<string, any>) => State;
  setSphere: (state: State, payload?: number, record?: Record<string, any>) => State;
  setCategoryIds: (state: State, payload: number[]) => State;
  setRatio: (state: State, payload?: string) => State;
  setRatioRecords: (state: State, payload?: string[]) => State;
  setGrowth: (state: State, payload?: string) => State;
  setGrowthRecords: (state: State, payload?: string[]) => State;
  setYear: (state: State, payload: string) => State;
  setStatementId: (state: State, payload: StatementId) => State;
  resetFilters: (state: State) => State;
}

const compareCompaniesFilter = {
  state: {
    categoryIds: [],
    ratioRecords: [],
    growthRecords: [],
    ratio: 'ALL',
  } as State,
  reducers: {
    setSector(state, id, record) {
      return {
        ...state,
        sectorId: id,
        sectorTitle: record ? record.children : undefined,
        industryId: undefined,
        sphereId: undefined,
      };
    },
    setIndustry(state, id, record) {
      return {
        ...state,
        industryId: id,
        industryTitle: record ? record.children : undefined,
        sphereId: undefined,
      };
    },
    setSphere(state, id, record) {
      return {
        ...state,
        sphereId: id,
        sphereTitle: record ? record.children : undefined,
      };
    },
    setCategoryIds(state, payload) {
      return {
        ...state,
        categoryIds: payload,
      };
    },
    setRatio(state, payload) {
      return {
        ...state,
        ratio: payload || 'CLEARED',
        ratioRecords: [],
      };
    },
    setRatioRecords(state, payload) {
      return {
        ...state,
        ratioRecords: payload,
      };
    },
    setGrowth(state, payload) {
      return {
        ...state,
        growth: payload,
        growthRecords: [],
      };
    },
    setGrowthRecords(state, payload) {
      return {
        ...state,
        growthRecords: payload,
      };
    },
    setYear(state, payload) {
      return {
        ...state,
        year: payload,
      };
    },
    setStatementId(state, payload) {
      return {
        ...state,
        statementId: payload,
      };
    },
    resetFilters() {
      return {
        categoryIds: [],
        ratioRecords: [],
        growthRecords: [],
        ratio: 'ALL',
      };
    },
  } as Reducers,
};

export default compareCompaniesFilter;
