import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { comparisonType } from '@/@types/general';

export interface Props {
  selectedYears: Array<string>;
  availableYears: Array<string>;
  onYearsChange: (newValue) => void;
  onRecordsChange: (newValue) => void;
  pageData: Record<string, any>;
  activeRatioName: string;
  showGrowthRates?: boolean;
}

export const useContainer = (props: Props) => {
  const { general, companyId } = useSelector((st) => ({ general: st.general, companyId: st.company.companyId }));
  const [selectMultipleYear, setSelectMultipleYear] = useState(false);
  const [recordsOptions, setRecordsOptions] = useState<Array<{ value: string; title: string }>>([]);
  const [selectedPage, setSelectedPage] = useState<string>();
  const history = useHistory();

  const changeSelectedPage = useCallback((path) => {
    history.push(path);
  }, []);

  useEffect(() => {
    setSelectMultipleYear(general.comparisonType === comparisonType.BY_YEARS);
  }, [general.comparisonType]);

  useEffect(() => {
    if (props.pageData && props.availableYears) {
      const singleYearData = !Array.isArray(props.pageData) ? props.pageData[props.availableYears[0]] : props.pageData[0].data[props.availableYears[0]];

      if (props.activeRatioName) {
        const activeRatioDataKeys = Object.keys(singleYearData[props.activeRatioName]);

        setRecordsOptions(activeRatioDataKeys.map((key) => ({
          title: key,
          value: key,
        })));
      } else {
        const rootKeys = Object.keys(singleYearData);
        let childKeys = [];

        rootKeys.forEach((key) => {
          childKeys = [...childKeys, ...Object.keys(singleYearData[key])];
        });

        setRecordsOptions(childKeys.map((key) => ({
          title: key,
          value: key,
        })));
      }
    }
  }, [props.pageData, props.availableYears, props.activeRatioName]);

  useEffect(() => {
    setSelectedPage(history.location.pathname);
  }, [history.location.pathname]);

  return {
    selectMultipleYear,
    recordsOptions,
    companyId,
    selectedPage,
    changeSelectedPage,
  };
};
