import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';

import { useService } from '@/hooks';
import { objKeysOnlyWithVal } from '@/helpers';

export function useContainer() {
  const [form] = Form.useForm();
  const { get } = useService();
  const {
    compareIndustries: {
      addIndustry,
      resetFilters: rxResetFilters,
    },
  } = useDispatch();
  const [filterData, setFilterData] = useState<any>({});
  const [filterOptions, setFilterOptions] = useState({
    sectors: [],
    industries: [],
    spheres: [],
    categories: [],
  });

  const setSector = (sectorId) => {
    if (!sectorId) {
      setFilterData((st) => ({
        ...st, sector: null, industry: null, sphere: null,
      }));
    } else {
      const sector = filterOptions.sectors.find((item) => item.id === sectorId);
      sector.type = 'sector';
      sector.category = filterData.category;

      setFilterData((st) => ({
        ...st, sector, industry: null, sphere: null,
      }));
    }
  };

  const setIndustry = (industryId) => {
    if (!industryId) {
      setFilterData((st) => ({ ...st, industry: null, sphere: null }));
    } else {
      const industry = filterOptions.industries.find((item) => item.id === industryId);
      industry.type = 'industry';
      industry.category = filterData.category;

      setFilterData((st) => ({
        ...st, industry, sphere: null,
      }));
    }
  };

  const setSphere = (sphereId) => {
    if (!sphereId) {
      setFilterData((st) => ({ ...st, sphere: null }));
    } else {
      const sphere = filterOptions.spheres.find((item) => item.id === sphereId);
      sphere.type = 'sphere';
      sphere.category = filterData.category;

      setFilterData((st) => ({ ...st, sphere }));
    }
  };

  const setCategory = (categoryIds, categories) => {
    if (!categories.length) {
      setFilterData((st) => ({ ...st, category: null }));
    } else {
      const category: any = {
        items: categories.map((cat) => ({ ...cat, id: cat.value, title: cat.children })),
      };
      category.type = 'category';
      const sector = filterData.sector ? { ...filterData.sector, category: [...(filterData?.sector?.category?.items || []), ...category.items] } : null;
      const industry = filterData.industry ? { ...filterData.industry, category: [...(filterData?.industry?.category?.items || []), ...category.items] } : null;
      const sphere = filterData.sphere ? { ...filterData.sphere, category: [...(filterData?.sphere?.category?.items || []), ...category.items] } : null;
      const newFilters = {
        ...filterData,
        category,
      };

      if (sector) {
        newFilters.sector = sector;
      }

      if (industry) {
        newFilters.industry = industry;
      }

      if (sphere) {
        newFilters.sphere = sphere;
      }

      setFilterData(newFilters);
    }
  };

  const resetFilters = () => {
    setFilterData({});
    rxResetFilters();
  };

  const onAdd = () => {
    addIndustry(Object.values(objKeysOnlyWithVal(filterData)));
    resetFilters();
  };

  useEffect(() => {
    get({ url: 'finance/sectors/filters' }).then(({ data: { results } }) => {
      setFilterOptions((st) => ({ ...st, sectors: results }));
    });
  }, []);

  useEffect(() => {
    get({ url: 'finance/categories' }).then(({ data: { results } }) => {
      setFilterOptions((st) => ({ ...st, categories: results }));
    });
  }, []);

  useEffect(() => {
    if (filterData.sector) {
      get({ url: 'finance/industries/filters', query: objKeysOnlyWithVal({ sector: [filterData.sector.id] }) }).then(({ data: { results } }) => {
        setFilterOptions((st) => ({ ...st, industries: results }));
      });
    }
  }, [filterData.sector]);

  useEffect(() => {
    if (filterData.industry) {
      get({ url: 'finance/spheres/filters', query: objKeysOnlyWithVal({ industry: [filterData.industry.id] }) }).then(({ data: { results } }) => {
        setFilterOptions((st) => ({ ...st, spheres: results }));
      });
    }
  }, [filterData.industry]);

  useEffect(() => () => {
    resetFilters();
  }, []);

  return {
    form,
    filterData: objKeysOnlyWithVal(filterData),
    filterOptions,
    setSector,
    setIndustry,
    setSphere,
    onAdd,
    setCategory,
  };
}
