import { useEffect, useState } from 'react';

export interface Props {
  ratios: Record<string, any>;
  filterValues: Record<string, any>;
}

export function useContainer(p: Props) {
  const [chartData, setChartData] = useState([]);
  const [showLine, setShowLine] = useState(false);

  useEffect(() => {
    const { ratios, filterValues } = p;
    const filteredRatios = {};

    filterValues.years.forEach((y) => {
      filteredRatios[y] = ratios[y];
    });

    Object.entries(filteredRatios).forEach(([year, value]) => {
      filteredRatios[year] = value[filterValues.ratio][filterValues.ratioRecord];
    });


    const data = Object.entries(filteredRatios).map(([year, value]) => ({ name: year, value, p: value }));

    setShowLine(filterValues.years.length > 1);

    setChartData(data);
  }, [p]);
  return {
    chartData,
    showLine,
  };
}
