import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useService, useLang } from '@/hooks';

export interface Props {
  selectedCompanies: any;
}

export function useContainer(props: Props) {
  const { get } = useService();
  const { getTranslation, keys } = useLang();
  const { compareCompaniesData: { setStatements } } = useDispatch();
  const filterData = useSelector((st) => st.compareCompaniesFilter);
  const [rawStatements, setRawStatements] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tablesData, setTablesData] = useState({});
  const [loading, setLoading] = useState(false);

  const responseWithCompany = useCallback((res, company) => ({ data: res.data.results, company }), []);

  useEffect(() => {
    const statementPromises = props.selectedCompanies
      .map((company) => get({ url: 'reports/financial-statements', query: { company_id: company.id, fsi: 0 } })
        .then((res) => responseWithCompany(res, company)));

    setLoading(true);

    Promise.all(statementPromises).then((res) => {
      setRawStatements(res);
      setLoading(false);
    });
  }, [props.selectedCompanies]);

  useEffect(() => {
    const cols: any = [{
      title: getTranslation(keys.record), dataIndex: 'record', fixed: 'left', ellipsis: true, width: 400,
    }];

    props.selectedCompanies.forEach((company) => {
      cols.push({
        title: company.name,
        dataIndex: company.id,
      });
    });

    setColumns(cols);
  }, [props.selectedCompanies]);

  useEffect(() => {
    let filtered = {};

    // modify and filer by year
    rawStatements.forEach((item) => {
      filtered[item.company.id] = item.data[filterData.year] || {};
    });

    // filter if there is selected statement
    if (filterData.statementId && filterData.statementId !== 0) {
      const newFiltered = {};

      Object.keys((filtered)).forEach((companyId) => {
        newFiltered[companyId] = {};

        Object.keys(filtered[companyId]).forEach((statementId) => {
          if (statementId === String(filterData.statementId)) {
            newFiltered[companyId][statementId] = filtered[companyId][statementId];
          }
        });
      });

      filtered = newFiltered;
    } else if (filterData.statementId === undefined || filterData.statementId === null) {
      filtered = {};
    }

    const tables = {};

    // prepare table blueprint
    Object.keys(filtered).forEach((companyId) => {
      Object.keys(filtered[companyId]).forEach((statementId) => {
        tables[statementId] = {};

        filtered[companyId][statementId].forEach((record) => {
          tables[statementId] = { ...tables[statementId], [record.title]: { record: record.title } };
        });
      });
    });

    // fill tables with data
    Object.keys(filtered).forEach((companyId) => {
      Object.keys(filtered[companyId]).forEach((statementId) => {
        filtered[companyId][statementId].forEach((record) => {
          tables[statementId][record.title] = {
            ...tables[statementId][record.title],
            [companyId]: filtered[companyId][statementId].find((item) => item.title === record.title).value,
            cssType: tables[statementId][record.title].cssType && tables[statementId][record.title].cssType.classNamesForTable ? tables[statementId][record.title].cssType : {
              classNamesForTable: filtered[companyId][statementId].find((item) => item.title === record.title).param,
            },
          };
        });
      });
    });

    setTablesData(tables);
    setStatements(tables);
  }, [rawStatements, filterData]);

  return {
    columns,
    tablesData,
    loading,
  };
}
