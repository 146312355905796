import { useService } from '@/hooks';
import { useState } from 'react';

export function useContainer() {
  const { get } = useService();
  const [ratios, setRatios] = useState();
  const [filterValues, setFilterValues] = useState();

  const onFilterSubmit = (val) => {
    get({
      url: 'reports/ratios',
      query: {
        company_id: val.company.id,
        report_type: 1,
      },
    }).then(({ data: { results } }) => {
      setRatios(results);
    });

    setFilterValues(val.filterValues);
  };

  return {
    onFilterSubmit,
    ratios,
    filterValues,
  };
}
