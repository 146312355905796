import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useService } from '@/hooks';

export interface Props {
  isGrowthShown?: boolean;
}

export function useContainer(props: Props) {
  const industriesState = useSelector((st) => st.compareIndustries);
  const { get } = useService();
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [tablesData, setTablesData] = useState({});

  const namedResponse = useCallback((res, target) => ({ data: res.data.results, target }), []);

  useEffect(() => {
    const requestTargets = [];

    industriesState.selectedIndustries.forEach((industry) => {
      if (industry.type === 'category') {
        return null;
      }

      const target: any = {
        type: industry.type,
        target: industry.id,
      };

      if (industry.category) {
        target.query = {};
        target.query.category = industry.category.map((cat) => cat.id)
      }

      if (industry.type === 'sector') {
        target.url = 'finance/sectors/ratios';
      } else if (industry.type === 'industry') {
        target.url = 'finance/industries/ratios';
      } else if (industry.type === 'sphere') {
        target.url = 'finance/spheres/ratios';
      }

      requestTargets.push(target);
    });

    setLoading(true);

    Promise.all(requestTargets.map((item) => get({ url: item.url, query: item.query })
      .then((res) => namedResponse(res, item))))
      .then((res) => {
        setRawData(res);
        setLoading(false);
      });
  }, [industriesState.selectedIndustries]);

  useEffect(() => {
    let filtered = [];

    // select specific sectors
    rawData.forEach((item) => {
      filtered.push(item.data.find((sector) => sector.id === item.target.target));
    });

    // select specific year and if growth selected
    filtered = filtered.map((item) => {
      const newItem = { ...item };
      let ratios;
      const ratiosByYear = newItem.ratios[industriesState.year] || {};

      if (props.isGrowthShown) {
        ratios = ratiosByYear['Growth Rate'];
      } else {
        delete ratiosByYear['Growth Rate'];
        ratios = ratiosByYear;
      }

      return {
        ...item,
        ratios: ratios || {},
      };
    });

    // filter if specific ratio and growth selected
    if (props.isGrowthShown && industriesState.growth && industriesState.growth !== 'ALL') {
      filtered = filtered.map((item) => {
        const ratios = {};

        Object.keys(item.ratios).forEach((ratio) => {
          if (ratio === industriesState.growth) {
            ratios[ratio] = item.ratios[ratio];
          }
        });


        // filter if some records selected
        if (industriesState.growthRecords.length) {
          const ratioKey = Object.keys(ratios)[0];
          const ratio = {};

          Object.keys(ratios[ratioKey]).forEach((record) => {
            if (industriesState.growthRecords.includes(record)) {
              ratio[record] = ratios[ratioKey][record];
            }
          });

          ratios[ratioKey] = ratio;
        }

        return {
          ...item,
          ratios,
        };
      });
    } else if (props.isGrowthShown && !industriesState.growth) {
      filtered = [];
    }

    // filter if specific ratio and growth NOT selected
    if (!props.isGrowthShown && industriesState.ratio && industriesState.ratio !== 'ALL') {
      filtered = filtered.map((item) => {
        const ratios = {};

        Object.keys(item.ratios).forEach((ratio) => {
          if (ratio === industriesState.ratio) {
            ratios[ratio] = item.ratios[ratio];
          }
        });

        // filter if some records selected
        if (industriesState.ratioRecords.length) {
          const ratioKey = Object.keys(ratios)[0];
          const ratio = {};

          Object.keys(ratios[ratioKey]).forEach((record) => {
            if (industriesState.ratioRecords.includes(record)) {
              ratio[record] = ratios[ratioKey][record];
            }
          });

          ratios[ratioKey] = ratio;
        }

        return {
          ...item,
          ratios,
        };
      });
    } else if (!props.isGrowthShown && industriesState.ratio === 'CLEARED') {
      filtered = [];
    }

    // generate table columns
    const cols = [];

    cols.push({
      dataIndex: 'record',
      title: 'RECORD',
    });

    filtered.forEach((item) => {
      cols.push({
        dataIndex: item.id,
        title: item.title,
      });
    });

    setColumns(cols);

    // prepare data for tables
    const ratiosBySector = {};

    filtered.forEach((item) => {
      ratiosBySector[item.id] = item.ratios;
    });


    // prepare data for tables
    const tables = {};

    // table blueprint
    Object.keys(ratiosBySector).forEach((sectorId) => {
      Object.keys(ratiosBySector[sectorId]).forEach((ratio) => {
        tables[ratio] = {};

        Object.keys(ratiosBySector[sectorId][ratio]).forEach((ratioRecord) => {
          tables[ratio][ratioRecord] = { record: ratioRecord };
        });
      });
    });

    // fill tables with data
    Object.keys(ratiosBySector).forEach((sectorId) => {
      Object.keys(ratiosBySector[sectorId]).forEach((ratio) => {
        Object.keys(ratiosBySector[sectorId][ratio]).forEach((ratioRecord) => {
          tables[ratio][ratioRecord] = {
            ...tables[ratio][ratioRecord],
            [sectorId]: ratiosBySector[sectorId][ratio][ratioRecord],
          };
        });
      });
    });

    setTablesData(tables);
  }, [rawData, industriesState, props.isGrowthShown]);

  return {
    loading,
    columns,
    tablesData,
  };
}
