import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useService, useQuery } from '@/hooks';

export const useContainer = () => {
  const { query } = useQuery();
  const { get } = useService();
  const location = useLocation();
  const [ratiosData, setRatiosData] = useState({});
  const [isGrowthRatiosShown, setIsGrowthRatiosShown] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [activeRatioName, setActiveRatioName] = useState<string>();
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);

  useEffect(() => {
    const filters = query.filters ? JSON.parse(query.filters) : {};
    const requestQuery: Record<string, any> = {};
    const manageRatiosResponse = (resultFor) => ({ data: { results } }) => {
      let data = results.map((item) => ({ ...item, resultFor }));

      if (resultFor === 'sphere' && filters.sphere) {
        data = data.filter((item) => filters.sphere.includes(item.id));
      }

      setRatiosData({ resultFor, data });

      setIsDataLoading(false);
    };

    setIsDataLoading(true);

    Object.keys(filters).forEach((fl) => {
      if (fl === 'industries') {
        requestQuery.industry = filters.industries;
      } else {
        requestQuery[fl] = filters[fl];
      }
    });

    if (filters.industries) {
      get({ url: 'finance/spheres/ratios', query: requestQuery })
        .then(manageRatiosResponse('sphere'));
    } else if (filters.sector) {
      get({ url: 'finance/industries/ratios', query: requestQuery })
        .then(manageRatiosResponse('industries'));
    } else {
      get({ url: 'finance/sectors/ratios', query: requestQuery })
        .then(manageRatiosResponse('sector'));
    }
  }, [query.filters]);

  useEffect(() => {
    let ratioName = location.pathname.split('/')[3];

    if (ratioName) {
      if (ratioName.includes('|')) {
        ratioName = ratioName.replace('|', '/');
      }

      ratioName = ratioName.replace(/-/g, ' ').toUpperCase();

      setActiveRatioName(ratioName);
    }

    setIsGrowthRatiosShown(location.pathname.split('/')[2] === 'growth-rate');
  }, [location.pathname]);

  return {
    ratiosData,
    isGrowthRatiosShown,
    isDataLoading,
    activeRatioName,
    selectedYears,
    setSelectedYears,
    selectedRecords,
    setSelectedRecords,
  };
};
