import * as React from 'react';
import {
  List, Col, Row, PageHeader, Form, Input, Button, Card, Alert,
} from 'antd';

import { useLang, useService } from '@/hooks';

function ContactSales(): JSX.Element {
  const { getTranslation, keys } = useLang();
  const { get, post } = useService();
  const [data, setData] = React.useState([]);
  const [form] = Form.useForm();
  const [successMessage, setSuccessMessage] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState();

  const onSubmit = async () => {
    setErrorMessage(null);

    try {
      const values = await form.validateFields();

      post({ url: 'm/contact', data: values })
        .then(() => {
          setSuccessMessage('Email was send successfully.');
        })
        .catch(({ response: { data: { message } } }) => {
          setErrorMessage(message);
        });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  React.useEffect(() => {
    get({ url: 'm/contact' }).then(({ data: { results } }) => {
      setData(Object.values(results));
    });
  }, []);

  return (
    <>
      <Row>
        <Col span={18} offset={3}>
          <PageHeader title={getTranslation(keys.contact_sales)} ghost={false} />
        </Col>
      </Row>
      <Row style={{ marginTop: '2rem' }} gutter={10}>
        <Col span={10} offset={3}>
          <Card>
            {
              successMessage ? <Alert message={successMessage} type="success" /> : (
                <Form layout="vertical" form={form}>
                  <Form.Item
                    label={getTranslation(keys.name)}
                    name="full_name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={getTranslation(keys.email)}
                    name="email"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={getTranslation(keys.phone)}
                    name="phone"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={getTranslation(keys.company_name)}
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={getTranslation(keys.message)}
                    name="message"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={onSubmit}
                    >
                      {getTranslation(keys.submit)}
                    </Button>
                  </Form.Item>
                  {
                    errorMessage && <Alert message={errorMessage} type="error" />
                  }
                </Form>
              )
            }
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <List
              bordered
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  {item}
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ContactSales;
