import * as React from 'react';
import {
  Form, Select, Row, Col,
} from 'antd';

import { useLang } from '@/hooks';

import { useContainer } from './container';

function DataFilters() {
  const {
    form,
    filterOptions,
    filterData,
    setRatio,
    setGrowth,
    setYear,
    setStatementId,
    setRatioRecords,
    setGrowthRecords,
  } = useContainer();
  const { getTranslation, keys } = useLang();

  return (
    <Form form={form} layout="vertical">
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label={getTranslation(keys.ratios)}>
            <Select onChange={setRatio} defaultValue="ALL" allowClear>
              {
              Object.keys(filterOptions.ratios).map((ratio) => (
                <Select.Option value={ratio} key={`ratio-${ratio}`}>
                  { ratio }
                </Select.Option>
              ))
            }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={getTranslation(keys.records)}>
            <Select
              onChange={setRatioRecords}
              mode="multiple"
              disabled={!filterData.ratio || filterData.ratio === 'ALL'}
              value={filterData.ratioRecords}
              allowClear
            >
              {
                filterOptions.ratioRecords.map((record) => (
                  <Select.Option value={record} key={`ratio-${record}`}>
                    { record }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label={getTranslation(keys.growth_rate)}>
            <Select onChange={setGrowth} allowClear>
              {
                Object.keys(filterOptions.growth).map((growth) => (
                  <Select.Option value={growth} key={`growth-${growth}`}>
                    { growth }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={getTranslation(keys.records)}>
            <Select
              onChange={setGrowthRecords}
              mode="multiple"
              disabled={!filterData.growth || filterData.growth === 'ALL'}
              value={filterData.growthRecords}
              allowClear
            >
              {
                filterOptions.growthRecords.map((record) => (
                  <Select.Option value={record} key={`growth-${record}`}>
                    { record }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label={getTranslation(keys.year)}>
            <Select onChange={setYear} value={filterData.year}>
              {
                filterOptions.years.map((year) => (
                  <Select.Option value={year} key={year}>
                    { year }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={getTranslation(keys.financial_statements)}>
            <Select onChange={setStatementId} allowClear>
              {
                filterOptions.statements.map((statement) => (
                  <Select.Option value={statement.key} key={statement.key}>
                    { getTranslation(statement.titleKey) }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default DataFilters;
