import { useState, useEffect, useCallback } from 'react';

export interface Props {
  selectedYears: Array<string>;
  tableData: any;
  tableTitle: string;
  style?: Record<string, any>;
}

interface Container {
  columns: Array<Record<string, any>>;
  dataSource: Array<Record<string, any>>;
}

export const useContainer = ({ selectedYears, tableData, tableTitle }: Props): Container => {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const sanitizeData = useCallback((data, years): void => {
    const filteredData = {};
    const sanitizedData = {};

    years.forEach((year) => {
      filteredData[year] = data[year];
    });

    Object.keys(filteredData).forEach((year) => {
      filteredData[year].forEach((item) => {
        sanitizedData[item.title] = {
          ...sanitizedData[item.title],
          [year]: item.value,
          key: item.title,
          cssType: {
            classNamesForTable: item.param,
          },
        };
      });
    });

    setDataSource(Object.values(sanitizedData));
  }, []);

  useEffect(() => {
    const cols = [{
      dataIndex: 'key',
      title: tableTitle,
    }];

    selectedYears.forEach((year) => {
      cols.push({
        dataIndex: year,
        title: year,
      });
    });

    setColumns(cols);

    if (tableData) {
      sanitizeData(tableData, selectedYears);
    }
  }, [selectedYears, tableData]);

  return {
    columns,
    dataSource,
  };
};
