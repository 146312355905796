import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useService, useLang } from '@/hooks';

export interface Props {
  selectedCompanies: any;
  isGrowthShown?: boolean;
}

export function useContainer(props: Props) {
  const { get } = useService();
  const { getTranslation, keys } = useLang();
  const { compareCompaniesData: { setRatios, setGrowth } } = useDispatch();
  const [rawCompaniesData, setRawCompaniesData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tablesData, setTablesData] = useState({});
  const filterData = useSelector((st) => st.compareCompaniesFilter);
  const [loading, setLoading] = useState(false);

  const responseWithCompany = useCallback((res, company) => ({ data: res.data.results, company }), []);

  useEffect(() => {
    const companyRatiosPromises = props.selectedCompanies.map((company) => get({
      url: 'reports/ratios',
      query: { company_id: company.id },
    }).then((res) => responseWithCompany(res, company)));

    setLoading(true);

    Promise.all(companyRatiosPromises).then((res) => {
      setRawCompaniesData(res);
      setLoading(false);
    });
  }, [props.selectedCompanies]);

  useEffect(() => {
    const cols: any = [{
      title: getTranslation(keys.record), dataIndex: 'record', fixed: 'left', ellipsis: true, width: 400,
    }];

    props.selectedCompanies.forEach((company) => {
      cols.push({
        title: company.name,
        dataIndex: company.id,
      });
    });

    setColumns(cols);
  }, [props.selectedCompanies]);

  useEffect(() => {
    let filtered = {};

    // modify and filter by year
    rawCompaniesData.forEach((item) => {
      filtered[item.company.id] = item.data[filterData.year] || {};
    });

    // modify data if there is shown growth or ratio
    if (props.isGrowthShown) {
      Object.keys(filtered).forEach((companyId) => {
        filtered[companyId] = filtered[companyId]['Growth Rate'] || {};
      });
    } else {
      Object.keys(filtered).forEach((companyId) => {
        delete filtered[companyId]['Growth Rate'];
      });
    }

    // select ratio based if there is growth shown
    const selectedRatio = props.isGrowthShown ? filterData.growth : filterData.ratio;
    const selectedRatioRecords = props.isGrowthShown ? filterData.growthRecords : filterData.ratioRecords;

    // filter if there is some selected ratio
    if (selectedRatio && selectedRatio !== 'ALL') {
      const newFiltered = {};

      Object.keys(filtered).forEach((companyId) => {
        newFiltered[companyId] = {};

        Object.keys(filtered[companyId]).forEach((ratio) => {
          if (ratio === selectedRatio) {
            newFiltered[companyId][ratio] = filtered[companyId][ratio];
          }
        });
      });

      filtered = newFiltered;
    } else if (!selectedRatio && props.isGrowthShown) {
      filtered = {};
    } else if (selectedRatio === 'CLEARED') {
      filtered = {};
    }


    // filter if there is some records selected
    if (selectedRatioRecords.length) {
      const newFiltered = {};

      Object.keys(filtered).forEach((companyId) => {
        newFiltered[companyId] = {};

        Object.keys(filtered[companyId]).forEach((ratio) => {
          newFiltered[companyId][ratio] = {};

          Object.keys(filtered[companyId][ratio]).forEach((ratioRecord) => {
            if (selectedRatioRecords.includes(ratioRecord)) {
              newFiltered[companyId][ratio][ratioRecord] = filtered[companyId][ratio][ratioRecord];
            }
          });
        });
      });

      filtered = newFiltered;
    }

    // prepare data for tables
    const tables = {};

    // table blueprint
    Object.keys(filtered).forEach((companyId) => {
      Object.keys(filtered[companyId]).forEach((ratio) => {
        tables[ratio] = {};

        Object.keys(filtered[companyId][ratio]).forEach((ratioRecord) => {
          tables[ratio][ratioRecord] = { record: ratioRecord };
        });
      });
    });

    // fill tables with data
    Object.keys(filtered).forEach((companyId) => {
      Object.keys(filtered[companyId]).forEach((ratio) => {
        Object.keys(filtered[companyId][ratio]).forEach((ratioRecord) => {
          tables[ratio][ratioRecord] = {
            ...tables[ratio][ratioRecord],
            [companyId]: filtered[companyId][ratio][ratioRecord],
          };
        });
      });
    });

    setTablesData(tables);

    if (props.isGrowthShown) {
      setGrowth(tables);
    } else {
      setRatios(tables);
    }
  }, [rawCompaniesData, filterData, props.isGrowthShown]);

  return {
    columns,
    tablesData,
    loading,
  };
}
