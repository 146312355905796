import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useLang, useService } from '@/hooks';

export const useContainer = () => {
  const { getTranslation, keys } = useLang();
  const { get } = useService();
  const { companyId } = useSelector((st) => ({ companyId: st.company.companyId }));
  const [founders, setFounders] = useState();
  const [directors, setDirectors] = useState();

  useEffect(() => {
    if (companyId) {
      get({ url: `finance/companies/${companyId}/founders` }).then(({ data: { results } }) => {
        setFounders(results);
      });

      get({ url: `finance/companies/${companyId}/directors` }).then(({ data: { results } }) => {
        setDirectors(results);
      });
    }
  }, [companyId]);

  const foundersCols = [
    {
      title: getTranslation(keys.founders),
      dataIndex: 'full_name',
    },
    {
      title: getTranslation(keys.percent),
      dataIndex: 'percentage',
    },
  ];

  const directorsCols = [
    {
      title: getTranslation(keys.directors),
      dataIndex: 'full_name',
    },
  ];


  return {
    directorsCols,
    founders,
    directors,
    foundersCols,
  };
};
