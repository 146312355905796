import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { routes } from '@/router';

interface Container {
  activePath: string;
  isUserAuthorized: boolean;
  onLogOut: () => void;
}

export const useContainer = (): Container => {
  const location = useLocation();
  const history = useHistory();
  const [activePath, setActivePath] = useState<string>();
  const isUserAuthorized = useSelector((st) => st.user.isAuthorized);
  const { user: { logOut } } = useDispatch();

  const onLogOut = () => {
    logOut();
    history.push(routes.home.path);
  };

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  return {
    activePath,
    isUserAuthorized,
    onLogOut,
  };
};
