import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useService, useQuery } from '@/hooks';

export function useContainer() {
  const { get } = useService();
  const { query } = useQuery();
  const location = useLocation();
  const [years, setYears] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [activeStatement, setActiveStatement] = useState<string>();
  const [rawData, setRawData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    get({ url: 'finance/years' })
      .then(({ data: { results } }) => {
        setYears(results);
        setSelectedYears(results.reverse().slice(0, 2).reverse());
      });
  }, []);


  useEffect(() => {
    setActiveStatement(location.pathname.split('/')[3] || '0');
  }, [location.pathname]);

  useEffect(() => {
    const requestQuery = query.filters ? JSON.parse(query.filters) : {};

    if (requestQuery.industries) {
      requestQuery.industry = requestQuery.industries;
      delete requestQuery.industries;
    }

    setIsLoading(true);

    get({ url: 'reports/fs-industries', query: requestQuery }).then(({ data: { results } }) => {
      setRawData(results);
      setIsLoading(false);
    });
  }, [query.filters]);

  return {
    years,
    setSelectedYears,
    selectedYears,
    rawData,
    activeStatement,
    isLoading,
  };
}
