import * as React from 'react';
import { Layout } from 'antd';
import { Route } from 'react-router-dom';

import { routes } from '@/router';

import { Sidebar } from './components';

function Charts(): JSX.Element {
  return (
    <Layout>
      <Sidebar />
      <Layout.Content>
        <Route path={routes.charts.routes.main.path} exact>
          <routes.charts.routes.main.component />
        </Route>
        <Route path={routes.charts.routes.companies.path} exact>
          <routes.charts.routes.companies.component />
        </Route>
        <Route path={routes.charts.routes.industries.path} exact>
          <routes.charts.routes.industries.component />
        </Route>
        <Route path={routes.charts.routes.compare.path} exact>
          <routes.charts.routes.compare.component />
        </Route>
      </Layout.Content>
    </Layout>
  );
}

export default Charts;
