import { useEffect, useState } from 'react';

import { useLang } from '@/hooks';
import { financialStatementsTypes } from '@/constants';

export interface Props {
  selectedYears: Array<number>;
  rawData: Record<string, any>;
  activeStatement: string;
  isLoading: boolean;
}

interface Container {
  columns: Array<Record<string, any>>;
  dataSource: Array<Record<string, any>>;
  multiTableDataSrc: Record<string, any>;
  isMultiTable: boolean;
}

export const useContainer = ({ selectedYears, rawData, activeStatement }: Props): Container => {
  const { getTranslation } = useLang();
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [multiTableDataSrc, setMultiTableDataSrc] = useState({});
  const [isMultiTable, setIsMultiTable] = useState(false);

  // set columns
  useEffect(() => {
    if (activeStatement) {
      const cols = [{
        dataIndex: 'key',
        title: getTranslation(financialStatementsTypes[activeStatement].titleKey),
      }];

      selectedYears.forEach((year) => {
        cols.push({
          dataIndex: String(year),
          title: String(year),
        });
      });

      setColumns(cols);
    }
  }, [JSON.stringify(selectedYears), activeStatement]);

  // manage table data
  useEffect(() => {
    if (rawData) {
      const filteredData = {};

      selectedYears.forEach((year) => {
        filteredData[year] = rawData[year];
      });

      // all statements selected
      if (activeStatement === '0') {
        const newMultiTableData = {};
        const dataSrc = {};

        selectedYears.forEach((year) => {
          if (rawData[year]) {
            Object.keys(rawData[year]).forEach((balanceType) => {
              newMultiTableData[balanceType] = { ...newMultiTableData[balanceType], [year]: rawData[year][balanceType] };
            });
          }
        });

        Object.keys(newMultiTableData).forEach((statementId) => {
          const sanitizedData = {};

          Object.keys(filteredData).forEach((year) => {
            if (filteredData[year]) {
              filteredData[year][statementId].forEach((item) => {
                sanitizedData[item.title] = {
                  ...sanitizedData[item.title],
                  [year]: item.value,
                  key: item.title,
                  cssType: {
                    classNamesForTable: item.param,
                  },
                };
              });
            }
          });

          dataSrc[statementId] = sanitizedData;
        });

        setMultiTableDataSrc(dataSrc);
        setIsMultiTable(true);
      } else { // selected some statement
        const sanitizedData = {};

        Object.keys(filteredData).forEach((year) => {
          if (filteredData[year]) {
            filteredData[year][activeStatement].forEach((item) => {
              sanitizedData[item.title] = {
                ...sanitizedData[item.title],
                [year]: item.value,
                key: item.title,
                cssType: {
                  classNamesForTable: item.param,
                },
              };
            });
          }
        });

        setDataSource(Object.values(sanitizedData));
        setIsMultiTable(false);
      }
    }
  }, [JSON.stringify(selectedYears), JSON.stringify(rawData), activeStatement]);

  return {
    columns,
    dataSource,
    multiTableDataSrc,
    isMultiTable,
  };
};
