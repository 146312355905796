import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useService, useLang } from '@/hooks';
import { financialStatementsTypes } from '@/constants';

export const useContainer = () => {
  const location = useLocation();
  const { get } = useService();
  const { getTranslation } = useLang();
  const { companyId, showConsolidated } = useSelector((st) => ({ companyId: st.company.companyId, showConsolidated: st.company.showConsolidated }));
  const [financialStatementId, setFinancialStatementId] = useState<string>();
  const [availableYears, setAvailableYears] = useState<Array<string>>([]);
  const [selectedYears, setSelectedYears] = useState<Array<string>>([]);
  const [tableData, setTableData] = useState<Record<string, any>>();
  const [multiTableData, setMultiTableData] = useState({});
  const [singleTableTitle, setSingleTableTitle] = useState<string>();

  useEffect(() => {
    setFinancialStatementId(location.pathname.split('/')[4]);
  }, [location]);

  useEffect(() => {
    if (financialStatementId && companyId) {
      get({
        url: 'reports/financial-statements',
        query: {
          company_id: companyId,
          fsi: financialStatementId,
          report_type: showConsolidated ? 2 : 1,
        },
      }).then(({ data }) => {
        const years = Object.keys(data.results);

        setAvailableYears(years);
        setSelectedYears(years.slice(Math.max(years.length - 2, 1)));
        setTableData(data.results);
      });
    }
  }, [financialStatementId, companyId, showConsolidated]);

  useEffect(() => {
    if (financialStatementId === '0' && tableData) {
      const years = Object.keys(tableData);
      const newMultiTableData = {};

      years.forEach((year) => {
        Object.keys(tableData[year]).forEach((balanceType) => {
          newMultiTableData[balanceType] = { ...newMultiTableData[balanceType], [year]: tableData[year][balanceType] };
        });
      });

      setMultiTableData(newMultiTableData);
    } else if (financialStatementId) {
      setSingleTableTitle(getTranslation(financialStatementsTypes[financialStatementId].titleKey));
    }
  }, [tableData, financialStatementId]);

  return {
    availableYears,
    selectedYears,
    tableData,
    setSelectedYears,
    financialStatementId,
    multiTableData,
    singleTableTitle,
  };
};
