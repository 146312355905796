import * as React from 'react';
import { Tabs } from 'antd';

import { useLang } from '@/hooks';
import { comparisonType } from '@/@types/general';
import { useContainer } from './container';
import './styles.less';

const TypeTabs = (): JSX.Element => {
  const { getTranslation, keys } = useLang();
  const { setType } = useContainer();

  return (
    <Tabs defaultActiveKey="BY_YEARS" onChange={setType}>
      <Tabs.TabPane tab={getTranslation(keys.by_year)} key={comparisonType.BY_YEARS} />
      <Tabs.TabPane tab={getTranslation(keys.by_industry)} key={comparisonType.BY_INDUSTRY} />
    </Tabs>
  );
};

export default TypeTabs;
