import * as React from 'react';

import { MainTable } from '@/components';

import { DataExport } from '@/components/CompaniesList/components/Table/DataExport';
import { useContainer, Props } from './container';

const Table = (props: Props) => {
  const {
    columns, dataSource, isGrowthRatiosShown, multiTableData,
  } = useContainer(props);
  const { activeRatioName } = props;

  if (activeRatioName) {
    return (
      <>
        <div className="data-export-clmn">
          <DataExport columns={columns} data={dataSource} />
        </div>
        <MainTable
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          showNumbersArrow={isGrowthRatiosShown}
        />
      </>
    );
  }

  return (
    <>
      {
                Object.keys(multiTableData).map((ratio) => {
                  const cols = [...columns];
                  cols[0] = { ...cols[0], title: ratio };

                  return (
                    <>
                      <div className="data-export-clmn">
                        <DataExport columns={cols} data={Object.values(multiTableData[ratio])} />
                      </div>
                      <MainTable
                        key={ratio}
                        columns={cols}
                        dataSource={Object.values(multiTableData[ratio])}
                        pagination={false}
                        showNumbersArrow={isGrowthRatiosShown}
                      />
                      <br />
                    </>
                  );
                })
            }
    </>
  );
};

export default Table;
