import * as React from 'react';

import { MainTable } from '@/components';
import { useLang } from '@/hooks';
import { financialStatementsTypes } from '@/constants';

import { DataExport } from '@/components/CompaniesList/components/Table/DataExport';
import { useContainer, Props } from './container';

const Table = (props: Props): JSX.Element => {
  const { getTranslation } = useLang();
  const { isLoading } = props;
  const {
    columns, dataSource, isMultiTable, multiTableDataSrc,
  } = useContainer(props);

  if (isMultiTable) {
    return (
      <>

        {
                    Object.keys(multiTableDataSrc).map((statementId) => (
                      <>
                        <div className="data-export-clmn">
                          <DataExport
                            columns={columns.map((col, i) => {
                              if (i === 0) {
                                return {
                                  ...col,
                                  title: getTranslation(financialStatementsTypes[statementId].titleKey),
                                };
                              }

                              return col;
                            })}
                            data={Object.values(multiTableDataSrc[statementId])}
                          />
                        </div>
                        <MainTable
                          columns={columns.map((col, i) => {
                            if (i === 0) {
                              return {
                                ...col,
                                title: getTranslation(financialStatementsTypes[statementId].titleKey),
                              };
                            }

                            return col;
                          })}
                          dataSource={Object.values(multiTableDataSrc[statementId])}
                          pagination={false}
                          loading={isLoading}
                        />
                      </>
                    ))
                }
        <br />
      </>
    );
  }

  return (
    <>
      <div className="data-export-clmn">
        <DataExport columns={columns} data={dataSource} />
      </div>
      <MainTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={isLoading}
      />
    </>

  );
};

export default Table;
