import * as React from 'react';

import { IndustriesList } from '@/components';

const Industries = (): JSX.Element => (
  <div className="industries-list">
    <IndustriesList />
  </div>
);

export default Industries;
