import * as React from 'react';
import {
  Col, Row, Form, Input, Button, Card, Divider, Alert,
} from 'antd';
import { useHistory } from 'react-router-dom';

import { useLang } from '@/hooks';
import { routes } from '@/router';

import { useContainer } from './container';

function LogIn(): JSX.Element {
  const { getTranslation, keys } = useLang();
  const history = useHistory();
  const {
    form, onSubmit, loading, errorMessage,
  } = useContainer();

  return (
    <Row style={{ marginTop: '2rem' }}>
      <Col span={10} offset={7}>
        <Card title={getTranslation(keys.log_in)}>
          <Form layout="vertical" form={form}>
            <Form.Item
              label={getTranslation(keys.email)}
              name="email"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={getTranslation(keys.password)}
              name="password"
              rules={[{ required: true }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={onSubmit} loading={loading} style={{ width: '100%' }}>
                { getTranslation(keys.submit) }
              </Button>
            </Form.Item>
            {
              errorMessage && <Alert message={errorMessage} type="error" closable />
            }
          </Form>
          <Divider>{ getTranslation(keys.or) }</Divider>
          <Button onClick={() => history.push(routes.pricing.path)} style={{ width: '100%' }}>
            { getTranslation(keys.register) }
          </Button>
        </Card>
      </Col>
    </Row>
  );
}

export default LogIn;
