import * as React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

import { routes } from '@/router';
import { renderRouteObj } from '@/helpers';
import { useContainer } from './container';
import { Sidebar, MetaInfo } from './components';

const Company = (): JSX.Element => {
  useContainer();

  return (
    <Route path={routes.company.path}>
      <Layout>
        <Route path={routes.company.path}>
          <Sidebar />
        </Route>
        <Layout.Content>
          <Route path={routes.company.path}>
            <MetaInfo />
          </Route>
          <Route path={routes.company.path} exact>
            <routes.company.component />
          </Route>
          {
            renderRouteObj(routes.company.routes.profile)
          }
          {
            renderRouteObj(routes.company.routes.ratios)
          }
          {
            renderRouteObj(routes.company.routes.growthRate)
          }
          {
            renderRouteObj(routes.company.routes.financialStatements)
          }
        </Layout.Content>
      </Layout>
    </Route>
  );
};

export default Company;
