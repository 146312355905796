import { useState, useEffect, useCallback } from 'react';
import { Form } from 'antd';
import { useHistory } from "react-router-dom";
import { useService, useQuery } from '@/hooks';

export interface Props {
  onFilterSubmit: (formValues) => void;
  onFilterReset: () => void;
}

interface FilterItem {
  title: string;
  id: number;
}

interface FilterData {
  legalForms?: Array<FilterItem>;
  categories?: Array<FilterItem>;
  sectors?: Array<FilterItem>;
  industries?: Array<FilterItem>;
  spheres?: Array<FilterItem>;
  fsFilters?: Array<FilterItem>;
}

export const useContainer = ({ onFilterSubmit, onFilterReset }: Props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { get } = useService();
  const { query } = useQuery();
  const [filterData, setFilterData] = useState<FilterData>({
    categories: [],
    sectors: [],
    industries: [],
    spheres: [],
    fsFilters: [],
  });
  const [selectedSectors, setSelectedSectors] = useState<FilterItem[]>([])
  const [companiesForAutocomplete, setCompaniesForAutocomplete] = useState([]);

  const namedResponse = useCallback((res, name) => ({ data: res.data.results, name }), []);

  const onSubmit = useCallback(() => {
    onFilterSubmit(form.getFieldsValue());
  }, [form]);

  const onReset = useCallback(() => {
    form.resetFields();
    onFilterReset();
    history.push('/companies')
  }, [form]);

  const onAutocomplete = useCallback((q) => {
    if (q && q.length > 2) {
      get({ url: 'finance/companies', query: { q } }).then(({ data: { results } }) => {
        setCompaniesForAutocomplete(results.map(({ org_name }) => ({ value: org_name })));
      });
    }
  }, []);

  useEffect(() => {
    const filterDataFromApi = {};

    Promise.all([
      get({ url: 'finance/categories' }).then((res) => namedResponse(res, 'categories')),
      get({ url: 'finance/sectors/filters' }).then((res) => namedResponse(res, 'sectors')),
      get({ url: 'finance/industries/filters' }).then((res) => namedResponse(res, 'industries')),
      get({ url: 'finance/spheres/filters' }).then((res) => namedResponse(res, 'spheres')),
      get({ url: 'finance/fs-filters' }).then((res) => namedResponse(res, 'fsFilters')),
    ]).then((values): void => {
      values.forEach(({ data, name }, i) => {
        filterDataFromApi[name] = data;

        if (i === 4) {
          setFilterData(filterDataFromApi);
        }
      });
    });
  }, []);

  const onIndustryChange = async (selected, industryType): Promise<void> => {
    const formValue = form.getFieldsValue();
    const updatedFilterData = { ...filterData };

    if (industryType === 'sector') {
      setSelectedSectors(selected)
      const industries = await get({ url: 'finance/industries/filters', query: { sector: selected } });
      const industriesIds = industries.data.results.map((item) => item.id);
      const spheres = await get({ url: 'finance/spheres/filters', query: { sector: selected, industry: industriesIds } });

      updatedFilterData.industries = industries.data.results;
      updatedFilterData.spheres = spheres.data.results;

      formValue.industry = undefined;
      formValue.sphere = undefined;

      form.setFieldsValue(formValue);
      setFilterData(updatedFilterData);
    } else {
      const spheres = await get({ url: 'finance/spheres/filters', query: { industry: selected, sector: selectedSectors } });

      updatedFilterData.spheres = spheres.data.results;
      formValue.sphere = undefined;

      form.setFieldsValue(formValue);
      setFilterData(updatedFilterData);
    }
  };

  useEffect(() => {
    const parsedQ: Record<string, any> = {};

    Object.keys(query).forEach((q) => {
      parsedQ[q] = JSON.parse(query[q]);
    });

    if (parsedQ.industries) {
      parsedQ.industry = parsedQ.industries;
      delete parsedQ.industries;
    }

    form.setFieldsValue(parsedQ);
    onSubmit();
  }, [query]);

  return {
    filterData,
    form,
    onSubmit,
    onReset,
    companiesForAutocomplete,
    onAutocomplete,
    onIndustryChange,
  };
};
