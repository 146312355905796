import * as React from 'react';
import { Table } from 'antd';

import { useContainer } from './container';
import { Layout } from '../components';

const AffiliatesCompanies = (): JSX.Element => {
  const { columns, dataSource } = useContainer();

  return (
    <Layout>
      <Table columns={columns} dataSource={dataSource} />
    </Layout>
  );
};

export default AffiliatesCompanies;
