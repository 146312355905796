import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useService } from '@/hooks';

export const useContainer = () => {
  const { get } = useService();
  const { companyId } = useSelector((st) => ({ companyId: st.company.companyId }));
  const [contactsTable, setContactsTable] = useState([]);
  const [keyData, setKeyData] = useState([]);
  const [keyCols, setKeyCols] = useState([]);

  useEffect(() => {
    if (companyId) {
      get({ url: `finance/companies/${companyId}/contacts` }).then(({ data: { results } }) => {
        const contactTable = [];

        results.forEach((contact) => {
          const singleTableData = [];

          Object.keys(contact).forEach((contactType) => {
            singleTableData.push({ key: contactType, value: contact[contactType] });
          });

          contactTable.push(singleTableData);
        });

        setContactsTable(contactTable);
      });

      get({ url: `finance/companies/${companyId}/indicators` }).then(({ data: { results } }) => {
        const records = {};

        Object.keys(results).forEach((year) => {
          Object.keys(results[year]).forEach((record) => {
            records[record] = {};
          });
        });

        Object.keys(results).forEach((year) => {
          Object.keys(records).forEach((record) => {
            records[record] = { ...records[record], [year]: results[year][record] };
          });
        });

        const columns = [{ dataIndex: 'title', title: null }];

        Object.keys(results).forEach((year) => {
          columns.push({ dataIndex: year, title: year });
        });

        const data = [];

        Object.keys(records).forEach((record) => {
          data.push({ title: record, ...records[record] });
        });

        setKeyData(data);
        setKeyCols(columns);
      });
    }
  }, [companyId]);

  const contactCols = [
    {
      dataIndex: 'key',
    },
    {
      dataIndex: 'value',
    },
  ];

  return {
    contactCols,
    contactsTable,
    keyData,
    keyCols,
  };
};
