import * as React from 'react';
import {
  LineChart as ReLineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid,
} from 'recharts';
import { Card } from 'antd';

import { CHART_COLORS } from '@/constants';

import './styles.less';

interface Props {
  linesDataKeys: [string];
  data: [Record<string, any>];
  xAxisFor?: string;
  title?: string;
  padding?: Record<string, number>;
}

function LineChart(props: Props): JSX.Element {
  const {
    xAxisFor = 'name', linesDataKeys, data, title, padding,
  } = props;

  return (
    <Card title={title} className="main-line-chart">
      <ReLineChart
        width={600}
        height={300}
        data={data}
        style={{ marginLeft: '50%', transform: 'translateX(-50%)' }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisFor} padding={padding} strokeWidth="3" />
        { linesDataKeys.map((lineDataKey, i) => <Line strokeWidth="3" key={lineDataKey} type="monotone" dataKey={lineDataKey} stroke={CHART_COLORS[i]} />) }
        <YAxis strokeWidth="3" />
        <Tooltip />
      </ReLineChart>
    </Card>
  );
}

export default LineChart;
