import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { classNamesForTable } from '@/constants';
import { numberWithCommas } from '@/helpers';

export const useContainer = ({ columns, showNumbersArrow }) => {
  const [formattedColumns, setFormattedColumns] = useState([]);

  const columnRenderer = useCallback((value, row) => {
    let classNameForItem = '';
    const placeholder = !value ? <span style={{ opacity: 0, width: 0, height: 0 }}>s</span> : null;

    if (row.cssType && row.cssType.classNamesForTable) {
      classNameForItem = classNamesForTable[row.cssType.classNamesForTable];
    }

    if (!Number.isInteger(value)) {
      return (
        <span className={classNameForItem} title={value}>
          {value?value : ""}
          {' '}
          {placeholder}
        </span>
      );
    }

    const prettyNumber = numberWithCommas(value);

    if (value < 0) {
      return (
        <span className={classNameForItem} style={{ color: 'red' }}>
          { showNumbersArrow && <CaretDownOutlined /> }
          {prettyNumber}
          { placeholder }
        </span>
      );
    }

    if (value > 0) {
      return (
        <span className={classNameForItem}>
          {showNumbersArrow && <CaretUpOutlined /> }
          {prettyNumber}
          { placeholder }
        </span>
      );
    }

    return <span className={classNameForItem}>{prettyNumber}</span>;
  }, [showNumbersArrow]);

  useEffect(() => {
    const updatedCols = columns.map((c) => ({ ...c, render: c.render || columnRenderer }));
    setFormattedColumns(updatedCols);
  }, [columns, columnRenderer]);

  useEffect(() => {
    const antCell = document.getElementsByClassName('ant-table-cell');

    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line no-restricted-syntax
    for (const el of antCell) {
      const withoutPadding = el.getElementsByClassName(classNamesForTable['1']).length > 0;

      if (withoutPadding && !el.className.includes('without-padding')) {
        el.className += ' without-padding';
      }
    }
  });

  return {
    formattedColumns,
  };
};
