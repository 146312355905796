import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { useService } from '@/hooks';
import { financialStatementsTypes } from '@/constants';

export function useContainer() {
  const [form] = Form.useForm();
  const filterData = useSelector((st) => st.compareIndustries);
  const { get } = useService();
  const {
    compareIndustries: {
      setRatio,
      setGrowth,
      setYear,
      setStatementId,
      setRatioRecords,
      setGrowthRecords,
      resetFilters,
    },
  } = useDispatch();
  const [filterOptions, setFilterOptions] = useState({
    years: [],
    statements: Object.values(financialStatementsTypes),
    ratios: {},
    ratioRecords: [],
    growth: {},
    growthRecords: [],
  });

  useEffect(() => {
    get({ url: 'finance/ratios' }).then(({ data: { results } }) => {
      const ratios = {
        ALL: null,
      };

      Object.keys(results).forEach((ratio) => {
        ratios[ratio] = Object.keys(results[ratio]);
      });

      setFilterOptions((st) => ({
        ...st,
        ratios,
        growth: ratios,
      }));
    });
  }, []);

  useEffect(() => {
    get({ url: 'finance/years' })
      .then(({ data: { results } }) => {
        setFilterOptions((st) => ({ ...st, years: results }));
        setYear(results[results.length - 1]);
      });
  }, []);

  useEffect(() => {
    if (filterData.ratio && filterData.ratio !== 'ALL') {
      setFilterOptions((st) => ({ ...st, ratioRecords: filterOptions.ratios[filterData.ratio] || [] }));
    }
  }, [filterData.ratio, filterOptions.ratios]);

  useEffect(() => {
    if (filterData.growth && filterData.growth !== 'ALL') {
      setFilterOptions((st) => ({ ...st, growthRecords: filterOptions.growth[filterData.growth] || [] }));
    }
  }, [filterData.growth, filterOptions.growth]);

  useEffect(() => () => {
    resetFilters();
  }, []);

  return {
    form,
    filterData,
    filterOptions,
    setRatio,
    setGrowth,
    setYear,
    setStatementId,
    setRatioRecords,
    setGrowthRecords,
  };
}
