import * as React from 'react';
import {
  Card, Col, Form, Input, Row, Button, Checkbox,
} from 'antd';

import { useLang } from '@/hooks';

import { useContainer } from './container';

function Register(): JSX.Element {
  const {
    form, isStudent, onSubmit, loading, errors,
  } = useContainer();
  const { getTranslation, keys } = useLang();

  return (
    <Row style={{ marginTop: '2rem' }}>
      <Col span={10} offset={7}>
        <Card title={getTranslation(keys.register)}>
          <Form layout="vertical" form={form}>
            {
              isStudent ? (
                <>
                  <Form.Item
                    label={getTranslation(keys.first_name)}
                    name="first_name"
                    rules={[{ required: true }]}
                    help={errors.first_name}
                    validateStatus={errors.first_name ? 'error' : undefined}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={getTranslation(keys.last_name)}
                    name="last_name"
                    rules={[{ required: true }]}
                    help={errors.last_name}
                    validateStatus={errors.last_name ? 'error' : undefined}
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  label={getTranslation(keys.company_name)}
                  name="title"
                  rules={[{ required: true }]}
                  help={errors.title}
                  validateStatus={errors.title ? 'error' : undefined}
                >
                  <Input />
                </Form.Item>
              )
            }
            <Form.Item
              label={getTranslation(keys.id_number)}
              name="id_code"
              rules={[{ required: true }]}
              help={errors.id_code}
              validateStatus={errors.id_code ? 'error' : undefined}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={getTranslation(keys.phone)}
              name="phone"
              rules={[{ required: true }]}
              help={errors.phone}
              validateStatus={errors.phone ? 'error' : undefined}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={getTranslation(keys.email)}
              name="email"
              rules={[{ required: true }]}
              help={errors.email}
              validateStatus={errors.email ? 'error' : undefined}
            >
              <Input />
            </Form.Item>
            {
              isStudent ? (
                <Form.Item
                  label={getTranslation(keys.university_name)}
                  name="university"
                  rules={[{ required: true }]}
                  help={errors.university}
                  validateStatus={errors.university ? 'error' : undefined}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item
                  label={getTranslation(keys.receive_invoice)}
                  name="invoice"
                  help={errors.invoice}
                  validateStatus={errors.invoice ? 'error' : undefined}
                >
                  <Checkbox />
                </Form.Item>
              )
            }
            <Form.Item
              label={getTranslation(keys.password)}
              name="password1"
              rules={[{ required: true }]}
              help={errors.password1}
              validateStatus={errors.password1 ? 'error' : undefined}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label={getTranslation(keys.confirm_password)}
              name="password2"
              rules={[{ required: true }]}
              help={errors.password2}
              validateStatus={errors.password2 ? 'error' : undefined}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={onSubmit} loading={loading}>
                { getTranslation(keys.submit) }
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default Register;
