import * as React from 'react';
import { Table } from 'antd';

import { useContainer } from './container';
import { Layout } from '../components';

const Audit = (): JSX.Element => {
  const { columns, dataSource } = useContainer();

  return (
    <Layout>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </Layout>
  );
};

export default Audit;
