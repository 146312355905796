import { sessionKeys } from '@/constants';

enum languages {
  en = 'en',
  ka = 'ka',
  ru = 'ru',
}

type Data = {
  en: Record<string, any>;
  ka: Record<string, any>;
  ru: Record<string, any>;
};

interface State {
  active: languages;
  initialized: boolean;
  data: Data;
}

interface Reducers {
  initialize: (state: State, data: Data) => State;
  changeLang: (state: State, activateLang: languages) => State;
}

const lang = {
  state: {
    active: localStorage.getItem(sessionKeys.USER_SELECTED_LANGUAGE) || 'ka',
    data: {
      en: {},
      ka: {},
      ru: {},
    },
    initialized: false,
  } as State,
  reducers: {
    initialize(state, data) {
      if (!state.initialized) {
        return {
          ...state,
          data,
          initialized: true,
        };
      }

      return state;
    },
    changeLang(state, activateLang) {
      localStorage.setItem(sessionKeys.USER_SELECTED_LANGUAGE, activateLang);
      window.location.reload();

      return {
        ...state,
        active: activateLang,
      };
    },
  } as Reducers,
};

export default lang;
