import { useState, useCallback, useEffect } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';

import { useQuery, useService } from '@/hooks';

export interface Props {
  singleIndustry?: boolean;
}

interface Container {
  form: FormInstance;
  onFilter: () => void;
  onFilterReset: () => void;
  onFormChange: (changedValue) => void;
  formState: Record<string, any>;
  filterOptions: Record<string, any>;
  filterItemWidth: number;
}

export const useContainer = (props: Props): Container => {
  const { get } = useService();
  const [form] = Form.useForm();
  const history = useHistory();
  const { query, updateQuery } = useQuery();
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [filterOptions, setFilterOptions] = useState({
    sector: [],
    industries: [],
    sphere: [],
    category: [],
    years: [],
  });
  const onFilter = useCallback((): void => {
    const filterKeys = Object.keys(formState);
    const filtersWithValues = {};

    filterKeys.forEach((filterKey) => {
      if (formState[filterKey] && formState[filterKey].length) {
        filtersWithValues[filterKey] = formState[filterKey];
      }
    });

    updateQuery({ target: 'filters', value: JSON.stringify(filtersWithValues) });
  }, [formState, history]);

  const onFilterReset = useCallback((): void => {
    form.resetFields();
    onFilter();
  }, []);

  const setDataForFilter = useCallback(({ data: { results } }, type): void => {
    setFilterOptions((st) => ({
      ...st,
      [type]: results,
    }));
  }, []);

  const setIndustriesFilterData = useCallback((sector) => {
    get({ url: 'finance/industries/filters', query: { sector } })
      .then((res) => setDataForFilter(res, 'industries'));
  }, [setDataForFilter]);

  const setSphereFilterData = useCallback((industries) => {
    get({ url: 'finance/spheres/filters', query: { industry: industries,sector: form.getFieldsValue().sector } })
      .then((res) => setDataForFilter(res, 'sphere'));
  }, [setDataForFilter]);

  const onFormChange = useCallback((changedValue): void => {
    if (changedValue.sector) {
      form.resetFields();
      form.setFieldsValue({ sector: props.singleIndustry ? [changedValue.sector] : changedValue.sector, category: formState.category });
      setIndustriesFilterData(props.singleIndustry ? [changedValue.sector] : changedValue.sector);
    } else if (changedValue.industries) {
      form.resetFields();
      form.setFieldsValue({ ...formState, industries: props.singleIndustry ? [changedValue.industries] : changedValue.industries, sphere: undefined });
      setSphereFilterData(props.singleIndustry ? [changedValue.industries] : changedValue.industries);
    } else if (changedValue.sphere) {
      form.resetFields();
      form.setFieldsValue({ ...formState, sphere: props.singleIndustry ? [changedValue.sphere] : changedValue.sphere });
    }
    setFormState(form.getFieldsValue());
  }, [formState, props.singleIndustry]);

  useEffect(() => {
    if (query.filters) {
      const formValues = JSON.parse(query.filters);

      if (formValues.sector) {
        setIndustriesFilterData(formValues.sector);
      }

      if (formValues.industries) {
        setSphereFilterData(formValues.industries);
      }

      form.setFieldsValue(formValues);
      setFormState(formValues);
    } else {
      form.resetFields();
      setFormState({});
    }
  }, [query]);

  useEffect(() => {
    get({ url: 'finance/spheres/filters' }).then((res) => setDataForFilter(res, 'sphere'));
    get({ url: 'finance/industries/filters' }).then((res) => setDataForFilter(res, 'industries'));
    get({ url: 'finance/sectors/filters' }).then((res) => setDataForFilter(res, 'sector'));
    get({ url: 'finance/categories' }).then((res) => setDataForFilter(res, 'category'));
  }, []);

  return {
    form,
    onFilter,
    onFilterReset,
    onFormChange,
    formState,
    filterItemWidth: 6,
    filterOptions,
  };
};
