import * as React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';

import { useLang } from '@/hooks';
import { routes } from '@/router';
import './styles.less';

const Sidebar = (): JSX.Element => {
  const { getTranslation } = useLang();

  return (
    <Layout.Sider
      width={250}
      theme="light"
      className="charts-sidebar"
    >
      <Menu>
        <Menu.Item>
          <Link to={routes.charts.routes.companies.path}>
            { getTranslation(routes.charts.routes.companies.titleKey) }
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={routes.charts.routes.industries.path}>
            { getTranslation(routes.charts.routes.industries.titleKey) }
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={routes.charts.routes.compare.path}>
            { getTranslation(routes.charts.routes.compare.titleKey) }
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
