import * as React from 'react';

import { MainTable } from '@/components';

import { DataExport } from '@/components/CompaniesList/components/Table/DataExport';
import { useContainer, Props } from './container';

const Table = (props: Props): JSX.Element => {
  const { columns, dataSource } = useContainer(props);
  const { style } = props;

  return (
    <>
      <div className="data-export-clmn">
        <DataExport columns={columns} data={dataSource} />
      </div>
      <MainTable
        style={style}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </>
  );
};

export default Table;
