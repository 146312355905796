import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { comparisonType } from '@/@types/general';
import { useLang } from '@/hooks';

export interface Props {
  selectedYears: Array<string>;
  selectedRecords: Array<string>;
  pageData: Record<string, any>;
  activeRatioName?: string;
  showGrowthRates?: boolean;
  tableTitle?: string;
}

export const useContainer = (props: Props) => {
  const { showGrowthRates } = props;
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [isGrowthRatiosShown, setIsGrowthRatiosShown] = useState(false);
  const { general } = useSelector((st) => ({ general: st.general }));
  const { getTranslation } = useLang();
  const location = useLocation();
  const [multiTableData, setMultiTableData] = useState({});

  const mergeObjsByYear = useCallback((data) => {
    const mergedObj = {};

    Object.keys(data).forEach((year) => {
      Object.keys(data[year] || {}).forEach((key) => {
        mergedObj[key] = {
          ...mergedObj[key],
          title: key,
          [year]: data[year][key],
        };
      });
    });

    return mergedObj;
  }, []);

  const filterDataBySelectedRecords = useCallback((selectedRecords, data) => {
    if (selectedRecords.length) {
      const filteredData = {};

      selectedRecords.forEach((recordKey) => {
        filteredData[recordKey] = data[recordKey];
      });

      return filteredData;
    }

    return data;
  }, []);

  const prepareDataByYear = useCallback(() => {
    const filteredData = {};

    props.selectedYears.forEach((year) => {
      filteredData[year] = props.pageData[year];

      if (!showGrowthRates) {
        delete filteredData[year]['Growth Rate'];
      } else {
        filteredData[year] = filteredData[year]['Growth Rate'];
      }
    });

    // there is some active ratio
    if (props.activeRatioName) {
      const necessaryData = {};

      props.selectedYears.forEach((year) => {
        necessaryData[year] = filteredData[year][props.activeRatioName];
      });

      const mergedData = mergeObjsByYear(necessaryData);
      const filteredBySelectedRecords = filterDataBySelectedRecords(props.selectedRecords, mergedData);

      setDataSource(Object.values(filteredBySelectedRecords));
    } else { // there is all ratios selected
      const flattenedDataByRatio = {};
      const mergedByRatio = {};

      props.selectedYears.forEach((year) => {
        Object.keys(filteredData[year]).forEach((ratio) => {
          if (!flattenedDataByRatio[ratio]) flattenedDataByRatio[ratio] = {};

          flattenedDataByRatio[ratio][year] = { ...flattenedDataByRatio[ratio][year], ...filteredData[year][ratio] };
        });
      });

      Object.keys(flattenedDataByRatio).forEach((ratio) => {
        mergedByRatio[ratio] = mergeObjsByYear(flattenedDataByRatio[ratio]);
      });

      // filter data if there is selected specific records
      if (props.selectedRecords.length) {
        Object.keys(mergedByRatio).forEach((ratio) => {
          Object.keys(mergedByRatio[ratio]).forEach((record) => {
            if (!props.selectedRecords.includes(record)) {
              delete mergedByRatio[ratio][record];
            }
          });
        });
      }

      setMultiTableData(mergedByRatio);
    }

    const cols = [{ dataIndex: 'title', title: props.tableTitle }];

    props.selectedYears.forEach((year) => {
      cols.push({
        dataIndex: year,
        title: year,
      });
    });

    setColumns(cols);
  }, [props.pageData, props.selectedYears, props.activeRatioName, props.selectedRecords]);

  const prepareDataByIndustry = useCallback(() => {
    const selectedYear = Array.isArray(props.selectedYears) ? props.selectedYears[0] : props.selectedYears;
    let filteredData = [];

    // filter data by year
    props.pageData.forEach((item) => {
      filteredData.push({
        ...item,
        data: item.data[selectedYear],
      });
    });

    // filter data by show or not growth rate
    if (!showGrowthRates) {
      filteredData = filteredData.map((item) => {
        const newItem = { ...item };

        delete newItem.data['Growth Rate'];

        return newItem;
      });
    } else {
      filteredData = filteredData.map((item) => {
        const newItem = { ...item };

        newItem.data = newItem.data['Growth Rate'];

        return newItem;
      });
    }

    // filter and flatten data by active ratio name
    if (props.activeRatioName) {
      filteredData = filteredData.map((item) => {
        const newItem = { ...item };

        newItem.data = newItem.data[props.activeRatioName];

        return newItem;
      });

      // filter data if there is selected specific records
      if (props.selectedRecords.length) {
        filteredData = filteredData.map((item) => {
          const newItem = { ...item };
          const filteredItemData = {};

          props.selectedRecords.forEach((record) => {
            filteredItemData[record] = newItem.data[record];
          });

          newItem.data = filteredItemData;

          return newItem;
        });
      }

      // create columns for table
      const newColumns: Array<any> = [{ dataIndex: 'title', title: props.tableTitle }];

      filteredData.forEach((item) => {
        newColumns.push({
          dataIndex: item.target.titleKey,
          title: (
            <div style={{ textAlign: 'center' }}>
              {getTranslation(item.target.titleKey)}
              <br />
              { item.target.meta }
            </div>
          ),
        });
      });

      setColumns(newColumns);

      // prepare data
      const tableRow = {};

      filteredData.forEach((item) => {
        const newItem = { ...item };

        Object.keys(newItem.data).forEach((recordTitle) => {
          tableRow[recordTitle] = {
            ...tableRow[recordTitle],
            title: recordTitle,
            [newItem.target.titleKey]: newItem.data[recordTitle],
          };
        });
      });

      setDataSource(Object.values(tableRow));
    } else { // there is all data
      const dataByCategory = {};
      const tables = {};

      filteredData.forEach((item) => {
        const newItem = { ...item };

        dataByCategory[item.target.titleKey] = newItem.data;

        Object.keys(newItem.data).forEach((ratio) => {
          tables[ratio] = {};
        });
      });

      // fill tables with category data
      Object.keys(tables).forEach((ratio) => {
        Object.keys(dataByCategory).forEach((category) => {
          tables[ratio] = { ...tables[ratio], [category]: dataByCategory[category][ratio] };
        });
      });

      const flattenDataByRatio = {};
      Object.keys(tables).forEach((ratio) => {
        const firstItemKeyInTable = Object.keys(tables[ratio])[0];
        const rows = {};

        Object.keys(tables[ratio][firstItemKeyInTable]).forEach((rowKey) => {
          rows[rowKey] = { title: rowKey };
        });

        // fill rows with category data
        Object.keys(rows).forEach((row) => {
          Object.keys(tables[ratio]).forEach((category) => {
            // filter data if there is selected specific records
            if (props.selectedRecords.length && props.selectedRecords.includes(row)) {
              rows[row] = { ...rows[row], [category]: tables[ratio][category][row] };
            } else if (props.selectedRecords.length === 0) {
              rows[row] = { ...rows[row], [category]: tables[ratio][category][row] };
            } else {
              delete rows[row];
            }
          });
        });

        flattenDataByRatio[ratio] = rows;
      });

      // create columns for table
      const newColumns: Array<any> = [{ dataIndex: 'title', title: null }];
      Object.keys(tables[Object.keys(tables)[0]]).forEach((column) => {
        newColumns.push({ dataIndex: column, title: getTranslation(column) });
      });

      setColumns(newColumns);
      setMultiTableData(flattenDataByRatio);
    }
  }, [props.pageData, props.selectedYears, showGrowthRates, props.activeRatioName, props.selectedRecords]);

  useEffect(() => {
    if (props.pageData && props.selectedYears) {
      if (general.comparisonType === comparisonType.BY_INDUSTRY && Array.isArray(props.pageData)) {
        prepareDataByIndustry();
      } else if (!Array.isArray(props.pageData)) {
        prepareDataByYear();
      }
    }
  }, [props.pageData, props.selectedYears, general.comparisonType, props.selectedRecords, props.activeRatioName, showGrowthRates]);

  useEffect(() => {
    setIsGrowthRatiosShown(location.pathname.split('/')[3] === 'growth-rate');
  }, [location.pathname]);

  return {
    columns,
    dataSource,
    isGrowthRatiosShown,
    multiTableData,
  };
};
