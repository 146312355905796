import { useSelector, useDispatch } from 'react-redux';

export interface Props {
  onCompareIndustriesClick?: () => void;
  onAddIndustriesClick?: () => void;
}

export function useContainer() {
  const industries = useSelector(({ compareIndustries }) => compareIndustries.selectedIndustries);
  const { compareIndustries: { removeIndustry } } = useDispatch();

  return {
    unselectIndustry: removeIndustry,
    industries,
  };
}
