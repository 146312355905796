import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as React from 'react';
import ReactExport from 'react-data-export';

interface Props {
  columns: Array<any>;
  data: Record<any, any>;
  pages: Array<any>;
}

export const DataExport = ({ columns, data, pages }: Props) => {
  const dataset = [];
  let temporaryData = [];

  const columnSet = [];
  for (const c of columns) {
    columnSet.push({ title: c.title.toString(), width: { wpx: 80 } });
  }

  for (const pagesData in data) {
    for (const d of data[pagesData]) {
      const obj = [];

      for (let j = 0; j < columns.length; j++) {
        const val = d[columns[j].dataIndex];
        if (typeof val === 'object' && val !== null) {
          obj.push({ value: val.title, style: { fill: { fgColor: { rgb: 'FFF86B00' } }, font: { sz: '12', bold: true } } });
        } else {
          obj.push({ value: val || '', style: { font: { sz: '12' } } });
        }
      }
      temporaryData.push(obj);
    }
    dataset.push({ data: temporaryData, columns: columnSet });
    temporaryData = [];
  }

  return (
    <ReactExport.ExcelFile
      element={<Button type="primary" icon={<DownloadOutlined />} size="large">Download</Button>}
    >
      {dataset.map((item, index) => <ReactExport.ExcelSheet dataSet={[item]} name={pages[index].record} />)}
    </ReactExport.ExcelFile>
  );
};
