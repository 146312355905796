import * as React from 'react';

import { MainTable } from '@/components';

import { DataExport } from '@/components/CompaniesList/components/Table/DataExport';
import { useContainer, Props } from './container';

function SectorRatiosTable(props: Props) {
  const { columns, tablesData, loading } = useContainer(props);

  if (!Object.keys(tablesData).length) {
    return null;
  }

  return (
    <>
      {
        Object.keys(tablesData).sort().map((tableKey) => (
          <>
            <div className="data-export-clmn">
              <DataExport columns={columns} data={Object.values(tablesData[tableKey])} />
            </div>
            <MainTable
              scroll={{ x: 500 }}
              columns={columns}
            // columns={columns.map((col, i) => (i === 0 ? ({ ...col, title: tableKey }) : col))}
              pagination={false}
              dataSource={Object.values(tablesData[tableKey])}
              loading={loading}
              style={{ marginBottom: '0.9rem' }}
              size="small"
            />
          </>
        ))
      }
    </>
  );
}

export default SectorRatiosTable;
