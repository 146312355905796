import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { useService } from '@/hooks';
import { financialStatementsTypes } from '@/constants';
import { objKeysOnlyWithVal } from '@/helpers';

export function useContainer() {
  const [form] = Form.useForm();
  const filterData = useSelector((st) => st.compareCompaniesFilter);
  const {
    compareCompaniesFilter: {
      setSector,
      setIndustry,
      setSphere,
      setCategoryIds,
      setRatio,
      setGrowth,
      setYear,
      setStatementId,
      setRatioRecords,
      setGrowthRecords,
      resetFilters,
    },
  } = useDispatch();
  const { get } = useService();
  const [filterOptions, setFilterOptions] = useState({
    categories: [],
    sectors: [],
    industries: [],
    spheres: [],
    years: [],
    statements: Object.values(financialStatementsTypes),
    ratios: {},
    ratioRecords: [],
    growth: {},
    growthRecords: [],
  });

  const namedResponse = useCallback((res, name) => ({ data: res.data.results, name }), []);

  useEffect(() => {
    const filterDataFromApi = {};

    Promise.all([
      get({ url: 'finance/categories' }).then((res) => namedResponse(res, 'categories')),
      get({ url: 'finance/sectors/filters' }).then((res) => namedResponse(res, 'sectors')),
    ]).then((values): void => {
      values.forEach(({ data, name }, i) => {
        filterDataFromApi[name] = data;

        if (i === 1) {
          setFilterOptions((st) => ({ ...st, ...filterDataFromApi }));
        }
      });
    });
  }, []);

  useEffect(() => {
    if (filterData.sectorId) {
      get({ url: 'finance/industries/filters', query: objKeysOnlyWithVal({ sector: [filterData.sectorId] }) }).then(({ data: { results } }) => {
        setFilterOptions((st) => ({ ...st, industries: results }));
      });
    }
  }, [filterData.sectorId]);

  useEffect(() => {
    if (filterData.industryId) {
      get({ url: 'finance/spheres/filters', query: objKeysOnlyWithVal({ industry: [filterData.industryId] }) }).then(({ data: { results } }) => {
        setFilterOptions((st) => ({ ...st, spheres: results }));
      });
    }
  }, [filterData.industryId]);

  useEffect(() => {
    get({ url: 'finance/ratios' }).then(({ data: { results } }) => {
      const ratios = {
        ALL: null,
      };

      Object.keys(results).forEach((ratio) => {
        ratios[ratio] = Object.keys(results[ratio]);
      });

      setFilterOptions((st) => ({
        ...st,
        ratios,
        growth: ratios,
      }));
    });
  }, []);

  useEffect(() => {
    get({ url: 'finance/years' })
      .then(({ data: { results } }) => {
        setFilterOptions((st) => ({ ...st, years: results }));
        setYear(results[results.length - 1]);
      });
  }, []);

  useEffect(() => {
    if (filterData.ratio && filterData.ratio !== 'ALL') {
      setFilterOptions((st) => ({ ...st, ratioRecords: filterOptions.ratios[filterData.ratio] || [] }));
    }
  }, [filterData.ratio, filterOptions.ratios]);

  useEffect(() => {
    if (filterData.growth && filterData.growth !== 'ALL') {
      setFilterOptions((st) => ({ ...st, growthRecords: filterOptions.growth[filterData.growth] || [] }));
    }
  }, [filterData.growth, filterOptions.growth]);

  useEffect(() => () => {
    resetFilters();
  }, []);

  return {
    form,
    filterData,
    filterOptions,
    setSector,
    setIndustry,
    setSphere,
    setCategoryIds,
    setRatio,
    setGrowth,
    setYear,
    setStatementId,
    setRatioRecords,
    setGrowthRecords,
  };
}
