import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useService } from '@/hooks';

export const useContainer = (): void => {
  const { get } = useService();
  const { company: { setCompanyId, setCompanyInfo } } = useDispatch();
  const location = useLocation();
  const companyId = location.pathname.split('/')[2];

  useEffect(() => {
    if (companyId) {
      get({ url: `finance/companies/${companyId}` }).then(({ data: { results: { company } } }) => {
        setCompanyInfo(company);
      });
    }
  }, [companyId]);


  useEffect(() => {
    setCompanyId(companyId);
  }, [location]);
};
