import * as React from 'react';
import { Row, Col, Card } from 'antd';

import { ComparisonTypeTabs } from '@/components';
import { Filters, Table } from './components';
import { useContainer } from './container';
import './styles.less';

const Ratios = (): JSX.Element => {
  const {
    availableYears,
    selectedYears,
    setSelectedYears,
    pageData,
    activeRatioName,
    setSelectedRecords,
    selectedRecords,
    showGrowthRates,
  } = useContainer();

  return (
    <Card className="financial-highlights-home">
      <ComparisonTypeTabs />
      <Row gutter={24}>
        <Col span={16}>
          <Filters
            availableYears={availableYears}
            selectedYears={selectedYears}
            onYearsChange={setSelectedYears}
            pageData={pageData}
            activeRatioName={activeRatioName}
            onRecordsChange={setSelectedRecords}
            showGrowthRates={showGrowthRates}
          />
        </Col>
      </Row>
      <div className="financial-highlights-home__content">
        <Table
          tableTitle={activeRatioName}
          pageData={pageData}
          activeRatioName={activeRatioName}
          selectedYears={selectedYears}
          selectedRecords={selectedRecords}
          showGrowthRates={showGrowthRates}
        />
      </div>
    </Card>
  );
};

export default Ratios;
