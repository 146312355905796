import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';

import { userTypes } from '@/constants';
import { useService } from '@/hooks';

export function useContainer() {
  const history = useHistory();
  const [form] = Form.useForm();
  const { post } = useService(false);
  const [userType, setUserType] = useState<number>();
  const [typeId, setTypeId] = useState<number>();
  const [isStudent, setIsStudent] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const onSubmit = async () => {
    setErrors({});

    try {
      const values = await form.validateFields();

      setLoading(true);
      setErrors({});

      post({ url: 'account/registration', data: { ...values, invoice: values.invoice || false, package: typeId } })
        .then(({ data: { rel_approve } }) => {
          window.location.href = rel_approve;
        })
        .catch(({ response: { data: { data } } }) => {
          const formErrors = {};

          Object.entries(data).forEach(([field, errors]) => {
            formErrors[field] = errors[0];
          });

          setErrors(formErrors);
          setLoading(false);
        });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  useEffect(() => {
    const splitedHistory = history.location.pathname.split('/');

    setUserType(Number(splitedHistory[2]));
    setTypeId(Number(splitedHistory[3]));
  }, [history.location.pathname]);

  useEffect(() => {
    setIsStudent(userType === userTypes.STUDENT);
  }, [userType]);

  return {
    form,
    isStudent,
    onSubmit,
    loading,
    errors,
  };
}
