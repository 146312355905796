const urlWithQuery = (url: string, obj?: Record<string, any>, strictMode = true): string => {
  if (!obj || typeof obj !== 'object') {
    return `${url}/`;
  }
  let query = '';
  const params = Object.keys(obj);

  params.forEach((param, i) => {
    const value = typeof obj[param] === 'object' ? JSON.stringify(obj[param]) : obj[param];

    if (i === 0) {
      query += `?${param}=${value}`;
    } else {
      query += `&${param}=${value}`;
    }
  });

  return `${url}${strictMode ? '/' : ''}${query}`;
};

export default urlWithQuery;
