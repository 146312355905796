import * as React from 'react';
import { Collapse, Alert } from 'antd';

import { ColumnChart, LineChart } from '@/components';
import { useLang } from '@/hooks';

import { Filter } from './components';
import { useContainer } from './container';

function IndustriesChart(): JSX.Element {
  const {
    onFiltersSubmit, showLine, chartData, loading, onAlertClose, alertClosed, filters,
  } = useContainer();
  const { getTranslation, keys } = useLang();

  return (
    <div>
      <Collapse defaultActiveKey="filters">
        <Collapse.Panel key="filters" header="AddIndustry">
          <Filter onFormSubmit={onFiltersSubmit} loading={loading} />
        </Collapse.Panel>
      </Collapse>
      {
            chartData && !alertClosed && (
            <Alert
              message={getTranslation(keys.alert_message_industries_chart)}
              type="warning"
              closable
              onClose={onAlertClose}
            />
            )
        }
      {
          // eslint-disable-next-line no-nested-ternary
          chartData ? showLine ? (
            <LineChart
              title={`${filters.ratio}, ${filters.ratioRecord}`}
              linesDataKeys={['value']}
              padding={{ left: 30, right: 30 }}
              data={chartData}
            />
          ) : (
            <ColumnChart
              title={`${filters.ratio}, ${filters.ratioRecord}`}
              data={chartData}
              withY
            />
          ) : null
        }
    </div>
  );
}

export default IndustriesChart;
