import * as React from 'react';
import {
  Form, Select, Col, Row,
} from 'antd';

import { useLang } from '@/hooks';
import { routes } from '@/router';

import { useContainer, Props } from './container';

const Filters = (props: Props): JSX.Element => {
  const {
    availableYears, selectedYears, onYearsChange, onRecordsChange, showGrowthRates,
  } = props;
  const {
    selectMultipleYear, recordsOptions, companyId, selectedPage, changeSelectedPage,
  } = useContainer(props);
  const { getTranslation, keys } = useLang();
  const routesForSelect = routes.company.routes[showGrowthRates ? 'growthRate' : 'ratios'];

  return (
    <Form
      name="companies_filters"
      layout="vertical"
      className="financial-highlights-filters"
    >
      <Row gutter={10}>
        <Col span={6}>
          <Select
            style={{ width: '100%' }}
            mode={selectMultipleYear ? 'multiple' : undefined}
            placeholder={getTranslation(keys.year)}
            value={selectedYears}
            onChange={onYearsChange}
          >
            {
              availableYears.map((year) => (
                <Select.Option value={year} key={year}>
                  {year}
                </Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            value={selectedPage}
            onChange={changeSelectedPage}
          >
            {
              Object.values(routesForSelect).map((route) => (
                <Select.Option value={route.open({ id: companyId })} key={route.path}>
                  {getTranslation(route.titleKey)}
                </Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={10}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            placeholder={getTranslation(keys.records)}
            onChange={onRecordsChange}
          >
            {
              recordsOptions.map((record) => (
                <Select.Option value={record.value} key={record.value}>
                  {record.title}
                </Select.Option>
              ))
            }
          </Select>
        </Col>
      </Row>
    </Form>
  );
};

export default Filters;
