import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sessionKeys } from '@/constants';

export function useContainer() {
  const {
    compareIndustries: {
      clearIndustries,
    },
  } = useDispatch();
  const [filtersAndCompany, setFiltersAndCompany] = useState<any>();
  const selectedIndustries = useSelector((st) => st.compareIndustries.selectedIndustries);
  const [alertClosed, setAlertClosed] = useState(false);

  const onFilterSubmit = (value) => {
    setFiltersAndCompany(value);
  };

  const onAlertClose = () => {
    sessionStorage.setItem(sessionKeys.USER_CLOSED_COMPARE_CHART_ALERT, '1');
  };

  useEffect(() => () => {
    clearIndustries();
  }, []);

  useEffect(() => {
    setAlertClosed(!!Number(sessionStorage.getItem(sessionKeys.USER_CLOSED_COMPARE_CHART_ALERT)));
  }, [sessionStorage.getItem(sessionKeys.USER_CLOSED_COMPARE_CHART_ALERT)]);

  return {
    onFilterSubmit,
    onAlertClose,
    alertClosed,
    selectedIndustries,
    filtersAndCompany,
  };
}
