import * as React from 'react';
import { Table } from 'antd';

import { MainTable } from '@/components';

import { useContainer } from './container';

const KeyHighlights = (): JSX.Element => {
  const {
    contactCols, contactsTable, keyData, keyCols,
  } = useContainer();

  return (
    <>
      <br />
      <MainTable
        columns={keyCols}
        dataSource={keyData}
        pagination={false}
        size="small"
      />
      <br />
      {
        contactsTable.map((contactData) => (
          <>
            <Table
              showHeader={false}
              columns={contactCols}
              dataSource={contactData}
              pagination={false}
              size="small"
            />
            <br />
          </>
        ))
      }
    </>
  );
};

export default KeyHighlights;
