import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useService } from '@/hooks';
import { objKeysOnlyWithVal } from '@/helpers';

export function useContainer() {
  const filterData = useSelector((st) => st.compareCompaniesFilter);
  const { get } = useService();
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [tablesData, setTablesData] = useState({});

  const namedResponse = useCallback((res, target) => ({ data: res.data.results, target }), []);

  useEffect(() => {
    const requestTargets = [
      {
        type: 'sector',
        url: 'reports/fs-industries',
        target: filterData.sectorId,
        title: filterData.sectorTitle,
        query: objKeysOnlyWithVal({
          category: filterData.categoryIds.length ? filterData.categoryIds : null,
          sector: [filterData.sectorId],
        }),
      },
    ];

    if (filterData.industryId) {
      requestTargets.push({
        type: 'industry',
        url: 'reports/fs-industries',
        target: filterData.industryId,
        title: filterData.industryTitle,
        query: objKeysOnlyWithVal({
          sector: [filterData.sectorId],
          industry: [filterData.industryId],
          category: filterData.categoryIds.length ? filterData.categoryIds : null,
        }),
      });
    }

    if (filterData.sphereId) {
      requestTargets.push({
        type: 'sphere',
        url: 'reports/fs-industries',
        target: filterData.sphereId,
        title: filterData.sphereTitle,
        query: objKeysOnlyWithVal({
          sector: [filterData.sectorId],
          industry: [filterData.industryId],
          sphere: [filterData.sphereId],
          category: filterData.categoryIds.length ? filterData.categoryIds : null,
        }),
      });
    }

    setLoading(true);

    Promise.all(requestTargets.map((item) => get({ url: item.url, query: item.query })
      .then((res) => namedResponse(res, item))))
      .then((res) => {
        setRawData(res);
        setLoading(false);
      });
  }, [filterData.sectorId, filterData.categoryIds, filterData.industryId, filterData.sphereId]);

  useEffect(() => {
    let filtered = {};

    // modify and filer by year
    rawData.forEach((item) => {
      filtered[item.target.target] = item.data[filterData.year] || {};
    });

    // filter if there is selected statement
    if (filterData.statementId && filterData.statementId !== 0) {
      const newFiltered = {};

      Object.keys((filtered)).forEach((sectorId) => {
        newFiltered[sectorId] = {};

        Object.keys(filtered[sectorId]).forEach((statementId) => {
          if (statementId === String(filterData.statementId)) {
            newFiltered[sectorId][statementId] = filtered[sectorId][statementId];
          }
        });
      });

      filtered = newFiltered;
    } else if (filterData.statementId === undefined || filterData.statementId === null) {
      filtered = {};
    }

    const tables = {};

    // prepare table blueprint
    Object.keys(filtered).forEach((sectorId) => {
      Object.keys(filtered[sectorId]).forEach((statementId) => {
        tables[statementId] = {};

        filtered[sectorId][statementId].forEach((record) => {
          tables[statementId] = { ...tables[statementId], [record.title]: { record: record.title } };
        });
      });
    });

    // fill tables with data
    Object.keys(filtered).forEach((sectorId) => {
      Object.keys(filtered[sectorId]).forEach((statementId) => {
        filtered[sectorId][statementId].forEach((record) => {
          tables[statementId][record.title] = {
            ...tables[statementId][record.title],
            [sectorId]: filtered[sectorId][statementId].find((item) => item.title === record.title).value,
            cssType: tables[statementId][record.title].cssType && tables[statementId][record.title].cssType.classNamesForTable ? tables[statementId][record.title].cssType : {
              classNamesForTable: filtered[sectorId][statementId].find((item) => item.title === record.title).param,
            },
          };
        });
      });
    });

    // create columns
    const cols = [];

    // uncomment if you want side by side check tables
    // cols.push({
    //   dataIndex: 'record',
    //   title: 'RECORD',
    // });

    rawData.forEach((item) => {
      cols.push({
        dataIndex: item.target.target,
        title: item.target.title,
      });
    });

    setTablesData(tables);
    setColumns(cols);
  }, [rawData, filterData]);

  return {
    loading,
    columns,
    tablesData,
  };
}
