import * as React from 'react';
import { Collapse, Divider } from 'antd';

import { ComparisonTypeTabs, IndustriesFilters } from '@/components';

import { useContainer } from './container';
import { Table, Filters } from './components';
import './styles.less';

const Ratios = (): JSX.Element => {
  const {
    ratiosData,
    isGrowthRatiosShown,
    isDataLoading,
    activeRatioName,
    selectedYears,
    selectedRecords,
    setSelectedYears,
    setSelectedRecords,
  } = useContainer();

  return (
    <div className="industries-ratios">
      <Collapse defaultActiveKey="filters">
        <Collapse.Panel key="filters" header="AddIndustry">
          <IndustriesFilters />
        </Collapse.Panel>
      </Collapse>
      <Divider />
      <ComparisonTypeTabs />
      <div className="industries-ratios__content">
        <Filters
          isGrowthRatiosShown={isGrowthRatiosShown}
          activeRatioName={activeRatioName}
          onRecordsChange={setSelectedRecords}
          onYearsChange={setSelectedYears}
        />
        <br />

        <Table
          isGrowthRatiosShown={isGrowthRatiosShown}
          activeRatioName={activeRatioName}
          isDataLoading={isDataLoading}
          ratiosData={ratiosData}
          selectedYears={selectedYears}
          selectedRecords={selectedRecords}
        />
      </div>
    </div>
  );
};

export default Ratios;
