import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { urlWithQuery } from '@/helpers';

interface UseQuery {
  query: Record<string, any>;
  updateQuery: ({ target, value }: {
    target: string;
    value: string;
  }) => void;
  queryStringToObj: (queryString: string) => Record<string, any>;
}

const useQuery = (): UseQuery => {
  const queryStringToObj = useCallback((qS) => JSON.parse(`{"${decodeURI(qS).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`), []);
  const history = useHistory();
  const location = useLocation();
  const [queryObj, setQueryObj] = useState(location.search ? queryStringToObj(location.search.substring(1)) : {});

  const updateQuery = useCallback(({ target, value }) => {
    const updatedQuery = { ...queryObj };
    updatedQuery[target] = value;

    history.push(urlWithQuery(`${history.location.pathname}`, updatedQuery, false));
  }, [queryObj, history.location.pathname]);

  useEffect(() => {
    const queryStr = location.search.substring(1);

    if (queryStr) {
      setQueryObj(queryStringToObj(queryStr));
    } else {
      setQueryObj({});
    }
  }, [location.search, location.pathname]);

  return {
    query: queryObj,
    updateQuery,
    queryStringToObj,
  };
};

export default useQuery;
