import * as React from 'react';

import { MainTable } from '@/components';
import { useLang } from '@/hooks';
import { financialStatementsTypes } from '@/constants';

import { DataExport } from '@/components/CompaniesList/components/Table/DataExport';
import { Props, useContainer } from './container';

function StatementsTable(props: Props) {
  const { columns, tablesData, loading } = useContainer(props);
  const { getTranslation } = useLang();

  if (!Object.keys(tablesData).length) {
    return null;
  }

  return (
    <>
      {
                Object.keys(tablesData).map((statementId) => (
                  <>
                    <div className="data-export-clmn">
                      <DataExport columns={columns} data={Object.values(tablesData[statementId])} />
                    </div>
                    <MainTable
                      scroll={{ x: 1500 }}
                      loading={loading}
                      columns={columns.map((col, i) => (i === 0 ? ({
                        ...col,
                        title: getTranslation(financialStatementsTypes[statementId].titleKey),
                      }) : col))}
                      dataSource={Object.values(tablesData[statementId])}
                      pagination={false}
                      size="small"
                    />
                    <br />
                  </>
                ))
            }
    </>
  );
}

export default StatementsTable;
