import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useService } from '@/hooks';

export function useContainer() {
  const { post } = useService();
  const { refreshToken, isAuthorized, whenSendRefreshToken } = useSelector((st) => st.user);
  const { user: { setAuthInfo } } = useDispatch();

  useEffect(() => {
    let refreshTokenInterval;

    if (isAuthorized) {
      refreshTokenInterval = setInterval(() => {
        const sendRefreshToken = Date.now() - whenSendRefreshToken > 0;

        if (sendRefreshToken) {
          clearTimeout(refreshTokenInterval);

          post({ url: 'auth/token-refresh', data: { refresh: refreshToken } }).then(({ data }) => {
            setAuthInfo({ token: data.access });
          });
        }
      }, 5000);
    }

    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, [isAuthorized, whenSendRefreshToken]);

  return {};
}
