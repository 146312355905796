import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useService } from '@/hooks';
import { comparisonType } from '@/@types/general';
import { translationKeys } from '@/constants';

export const useContainer = () => {
  const location = useLocation();
  const { get } = useService();
  const [ratioId, setRatioId] = useState<string>();
  const [availableYears, setAvailableYears] = useState<Array<string>>([]);
  const [selectedYears, setSelectedYears] = useState<Array<string>>([]);
  const [selectedRecords, setSelectedRecords] = useState<Array<string>>([]);
  const [pageData, setPageData] = useState<any>();
  const [activeRatioNameFromPath, setActiveRatioNameFromPath] = useState<string>();
  const [activeRatioName, setActiveRatioName] = useState<string>();
  const {
    companyId, general, companyInfo, showConsolidated,
  } = useSelector((st) => ({
    companyId: st.company.companyId,
    general: st.general,
    companyInfo: st.company.companyInfo,
    showConsolidated: st.company.showConsolidated,
  }));
  const [showGrowthRates, setShowGrowthRates] = useState(false);

  const cleanState = useCallback(() => {
    setAvailableYears([]);
    setSelectedYears([]);
    setPageData(null);
  }, []);

  const getDataByYears = useCallback(() => {
    cleanState();
    const query: Record<string, any> = {
      company_id: companyId,
      report_type: showConsolidated ? 2 : 1,
    };

    if (ratioId !== 'all') {
      query.ratio = ratioId;
    }

    get({
      url: 'reports/ratios',
      query,
    }).then(({ data }) => {
      const years = Object.keys(data.results);

      setAvailableYears(years);
      setSelectedYears(years.slice(Math.max(years.length - 2, 1)));
      setPageData(data.results);
    });
  }, [companyId, ratioId, showConsolidated]);

  const getDataByIndustry = useCallback(() => {
    cleanState();
    const query: Record<string, any> = {
      company_id: companyId,
      report_type: showConsolidated ? 2 : 1,
    };

    if (ratioId !== 'all') {
      query.ratio = ratioId;
    }

    const targets = {
      company: {
        url: 'reports/ratios',
        query,
        titleKey: translationKeys.company,
      },
      bySector: {
        url: 'reports/ratio-industries',
        query: {
          sector: companyInfo.sector.id,
        },
        titleKey: translationKeys.sector,
        meta: companyInfo.sector.title,
      },
      byIndustry: {
        url: 'reports/ratio-industries',
        query: {
          sector: companyInfo.sector.id,
          industry: companyInfo.industry.id,
        },
        titleKey: translationKeys.industry,
        meta: companyInfo.industry.title,
      },
      bySphere: {
        url: 'reports/ratio-industries',
        query: {
          sector: companyInfo.sector.id,
          industry: companyInfo.industry.id,
          sphere: companyInfo.sphere.id,
        },
        titleKey: translationKeys.sphere,
        meta: companyInfo.sphere.title,
      },
    };
    const promises = Object.values(targets)
      .map((target) => get({ url: target.url, query: target.query }).then(({ data }) => ({ data: data.results, target })));

    Promise.all(promises).then((values) => {
      const years = Object.keys(values[0].data);

      setAvailableYears(years);
      setSelectedYears(years.slice(Math.max(years.length - 1, 1)));
      setPageData(values);
    });
  }, [cleanState, companyInfo, ratioId, companyId, showConsolidated]);

  useEffect(() => {
    const ratioId = location.pathname.split('/')[4];
    let ratioName = location.pathname.split('/')[4];

    if (ratioName) {
      if (ratioName.includes('|')) {
        ratioName = ratioName.replace('|', '/');
      }

      ratioName = ratioName.replace(/-/g, ' ').toUpperCase();

      setActiveRatioNameFromPath(ratioName);
    }

    setShowGrowthRates(!(location.pathname.split('/')[3] === 'ratios'));

    setRatioId(ratioId);
  }, [location]);

  useEffect(() => {
    if (ratioId && companyId) {
      if (general.comparisonType === comparisonType.BY_INDUSTRY) {
        getDataByIndustry();
      } else {
        getDataByYears();
      }
    }
  }, [getDataByYears, general.comparisonType, ratioId, showConsolidated, companyId]);

  useEffect(() => {
    if (activeRatioNameFromPath && pageData && selectedYears) {
      if (general.comparisonType === comparisonType.BY_INDUSTRY && pageData[0] && pageData[0].data) {
        Object.keys(pageData[0].data[availableYears[0]]).forEach((key) => {
          if (key.toUpperCase() === activeRatioNameFromPath) {
            setActiveRatioName(key);
          }
        });
      } else if (!Array.isArray(pageData)) {
        Object.keys(pageData[availableYears[0]]).forEach((key) => {
          if (key.toUpperCase() === activeRatioNameFromPath) {
            setActiveRatioName(key);
          }
        });
      }
    }
  }, [activeRatioNameFromPath, pageData, selectedYears, availableYears, general.comparisonType]);

  return {
    availableYears,
    selectedYears,
    pageData,
    setSelectedYears,
    activeRatioName,
    selectedRecords,
    setSelectedRecords,
    showGrowthRates,
  };
};
