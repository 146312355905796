import * as React from 'react';
import {
  Form, Select, Col, Row,
} from 'antd';

import { useLang } from '@/hooks';
import { routes } from '@/router';

import { Props, useContainer } from './container';

const Filters = ({ availableYears, selectedYears, setSelectedYears }: Props): JSX.Element => {
  const { getTranslation, keys } = useLang();
  const {
    companyId, selectedPage, changeSelectedPage, showConsolidated,
  } = useContainer();
  const routesForSelect = routes.company.routes.financialStatements;

  return (
    <Form
      name="financial_statements_filters"
      layout="vertical"
    >
      <Row gutter={10}>
        <Col span={6}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={getTranslation(keys.year)}
            value={selectedYears}
            onChange={setSelectedYears}
          >
            {
              availableYears.map((year) => (
                <Select.Option value={year} key={year}>
                  {year}
                </Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            value={selectedPage}
            onChange={changeSelectedPage}
          >
            {
              Object.values(routesForSelect).map((route) => (
                <Select.Option value={route.open({ id: companyId })} key={route.path}>
                  {getTranslation(route.titleKey)}
                </Select.Option>
              ))
            }
          </Select>
        </Col>
      </Row>
      <br />
      <Row>
        { getTranslation(showConsolidated ? keys.consolidated_financial_statement : keys.individual_financial_statement) }
      </Row>
    </Form>
  );
};

export default Filters;
