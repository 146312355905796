import * as React from 'react';
import {
  Card, Col, Row, Popover, Button, Spin, PageHeader,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { userTypes } from '@/constants';
import { useService, useLang } from '@/hooks';
import { routes } from '@/router';

import './styles.less';

function Pricing(): JSX.Element {
  const { get, post } = useService();
  const user = useSelector((st) => st.user);
  const history = useHistory();
  const { getTranslation, keys } = useLang();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const onButtonClick = (price) => {
    if (price.packet === userTypes.BUSINESS) {
      const targetUrl = routes.contactSales.path;
      history.push(targetUrl);
    } else if (!user.isAuthorized) {
      const targetUrl = routes.register.open({ userType: price.packet, typeId: price.id });
      history.push(targetUrl);
    } else {
      setLoading(true);

      post({ url: 'payment/checkout', data: { package: price.packet } })
        .then((res) => {
          window.location.href = res.data.rel_approve;
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    get({ url: 'm/packages' }).then(({ data: { results } }) => {
      setData(results);
    });
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      <Row>
        <Col offset={3} span={18}>
          <PageHeader title={getTranslation(keys.prices_page_title)} ghost={false} />
        </Col>
      </Row>
      <br />
      <Row className="pricing">
        {
          data.map((price, i) => (
            <Col offset={i === 0 ? 3 : 0} span={18 / data.length} key={price.id}>
              <Card
                title={(
                  <div className="pricing__header">
                    <h3 className="pricing__header__title">{price.title}</h3>
                    <span className="pricing__header__target">{price.target}</span>
                    <div className="pricing__header__price">
                      <h2>{price.price}</h2>
                      <span>{price.pop}</span>
                    </div>
                    <p className="pricing__header__desc" dangerouslySetInnerHTML={{ __html: price.desc }} />
                    <Button
                      onClick={() => onButtonClick(price)}
                      type="primary"
                    >
                      {price.packet === userTypes.BUSINESS ? getTranslation(keys.contact_us) : getTranslation(keys.buy_now)}
                    </Button>
                  </div>
                )}
                extra={price.help && (
                  <Popover content={price.help}>
                    <InfoCircleOutlined />
                  </Popover>
                )}
              >
                {
                    price.features.map((feature) => (
                      <div className="pricing__list-item" key={feature.title}>
                        <span>{feature.title}</span>
                        {
                            feature.help && (
                            <Popover content={feature.help}>
                              <InfoCircleOutlined />
                            </Popover>
                            )
                          }
                      </div>
                    ))
                  }
              </Card>
            </Col>
          ))
        }
      </Row>
    </>
  );
}

export default Pricing;
