import * as React from 'react';

import { MainTable } from '@/components';
import { useLang } from '@/hooks';
import { financialStatementsTypes } from '@/constants';

import { useContainer } from './container';

function StatementsTable(): JSX.Element {
  const { columns, tablesData, loading } = useContainer();
  const { getTranslation } = useLang();

  if (!Object.keys(tablesData).length) {
    return null;
  }

  return (
    <>
      {
        Object.keys(tablesData).map((statementId) => (
          <MainTable
            scroll={{ x: 500 }}
            loading={loading}
            columns={columns.map((col, i) => (i === 0 ? ({ ...col, title: getTranslation(financialStatementsTypes[statementId].titleKey) }) : col))}
            dataSource={Object.values(tablesData[statementId])}
            pagination={false}
            style={{ marginBottom: '0.9rem' }}
            size="small"
          />
        ))
      }
    </>
  );
}

export default StatementsTable;
