import { useSelector } from 'react-redux';

import { translationKeys } from '@/constants';

interface UseLang {
  getTranslation: (key: string) => string;
  keys: Record<string, string>;
}

const useLang = (): UseLang => {
  const { data, active } = useSelector((st) => st.lang);

  const getTranslation = (key): string => data[active][key.toLowerCase()] || key;

  return {
    getTranslation,
    keys: translationKeys,
  };
};

export default useLang;
