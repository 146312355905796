import * as React from 'react';
import { Card } from 'antd';

import { financialStatementsTypes } from '@/constants';
import { useLang } from '@/hooks';

import { Filters, Table } from './components';
import { useContainer } from './container';

const Balance = (): JSX.Element => {
  const { getTranslation } = useLang();
  const {
    availableYears, selectedYears, setSelectedYears, tableData, financialStatementId, multiTableData, singleTableTitle,
  } = useContainer();

  return (
    <Card>
      <Filters
        availableYears={availableYears}
        selectedYears={selectedYears}
        setSelectedYears={setSelectedYears}
      />
      <br />
      {
        financialStatementId !== '0' ? (
          <Table
            selectedYears={selectedYears}
            tableData={tableData}
            tableTitle={singleTableTitle}
          />
        ) : (
          Object.keys(multiTableData).map((key, i) => (
            <Table
              key={`multi-data-${i}`}
              selectedYears={selectedYears}
              tableData={multiTableData[key]}
              style={{ marginBottom: '3rem' }}
              tableTitle={getTranslation(financialStatementsTypes[key].titleKey)}
            />
          ))
        )
      }
    </Card>
  );
};

export default Balance;
