import * as React from 'react';
import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';

import { useContainer } from './container';
import './styles.less';

interface Props extends TableProps<any> {
  columns: Array<Record<string, any>>;
  dataSource: Array<Record<string, any>>;
  showNumbersArrow?: boolean;
  className?: string;
  key?: string;
}

const Table = (props: Props): JSX.Element => {
  const {
    columns = [], dataSource = [], showNumbersArrow, className = '', key, size, ...rest
  } = props;
  const { formattedColumns } = useContainer({ columns, showNumbersArrow });

  return (
    <AntTable
      {...rest}
      key={key}
      className={`stylized-table size-${size} ${className}`}
      columns={formattedColumns}
      dataSource={dataSource}
      size={size}
    />
  );
};

export default Table;
