import * as React from 'react';
import {
  Form, Select, Col, Row,
} from 'antd';

import { useLang } from '@/hooks';
import { useContainer, Props } from './container';

const Filters = (props: Props): JSX.Element => {
  const {
    years,
    selectMultipleYear,
    ratios,
    records, selectedRatio,
    selectedYears,
    onYearsChange,
    onRecordsChange,
    onRatioChange,
  } = useContainer(props);
  const { getTranslation, keys } = useLang();

  return (
    <Form
      layout="vertical"
      className="financial-highlights-filters"
    >
      <Row gutter={10}>
        <Col span={6}>
          <Select
            style={{ width: '100%' }}
            mode={selectMultipleYear ? 'multiple' : undefined}
            placeholder={getTranslation(keys.year)}
            value={selectedYears.map((item) => item.value)}
            onChange={onYearsChange}
          >
            {
              years.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.title}
                </Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            placeholder={getTranslation(keys.ratios)}
            value={selectedRatio}
            onChange={onRatioChange}
          >
            {
              ratios.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.title}
                </Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={10}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            placeholder={getTranslation(keys.record)}
            onChange={onRecordsChange}
          >
            {
              records.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.title}
                </Select.Option>
              ))
            }
          </Select>
        </Col>
      </Row>
    </Form>
  );
};

export default Filters;
