import { useState, useEffect } from 'react';

import { useQuery } from '@/hooks';

interface Container {
  filters: string;
}

export const useContainer = (): Container => {
  const { query } = useQuery();
  const [filters, setFilters] = useState('');

  useEffect(() => {
    setFilters(query.filters);
  }, [query.filters]);

  return {
    filters,
  };
};
