import * as React from 'react';
import { PageHeader } from 'antd';

import { useLang } from '@/hooks';

import { useContainer } from './container';

function MetaInfo(): JSX.Element {
  const { getTranslation, keys } = useLang();
  const { companyInfo } = useContainer();

  return (
    <PageHeader
      ghost={false}
      title={companyInfo.org_name}
    >
      <p>
        <span>
          {getTranslation(keys.sector)}
          {' - '}
          {companyInfo.sector?.title}
        </span>
        { ' / ' }
        <span>
          {getTranslation(keys.industry)}
          {' - '}
          {companyInfo.industry?.title}
        </span>
        { ' / ' }
        <span>
          {getTranslation(keys.sphere)}
          {' - '}
          {companyInfo.sphere?.title}
        </span>
      </p>
    </PageHeader>
  );
}

export default MetaInfo;
