import * as React from 'react';
import { Route as DomRoute } from 'react-router-dom';

import { Route } from '@/router';

const renderRouteObj = (routeObj: Record<string, Route>): JSX.Element[] => {
  const [routes, setRoutes] = React.useState([]);

  React.useEffect(() => {
    const routeKeys = Object.keys(routeObj);
    const routeComponents = [];

    routeKeys.forEach((routeKey) => {
      const RouteComponent = routeObj[routeKey].component;

      routeComponents.push((
        <DomRoute path={routeObj[routeKey].path} exact>
          <RouteComponent />
        </DomRoute>
      ));
    });

    setRoutes(routeComponents);
  }, [routeObj]);

  return routes;
};

export default renderRouteObj;
