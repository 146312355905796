import * as React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useLang, useService } from '@/hooks';
import { routes } from '@/router';

interface Container {
  columns: Array<Record<string, any>>;
  dataSource: Array<Record<string, any>>;
}

export const useContainer = (): Container => {
  const { getTranslation, keys } = useLang();
  const { get } = useService();
  const { companyId } = useSelector((st) => ({ companyId: st.company.companyId }));
  const [dataSource, setDataSource] = React.useState([]);

  React.useEffect(() => {
    if (companyId) {
      get({ url: `finance/companies/${companyId}/affiliates` }).then(({ data: { results } }) => {
        setDataSource(results);
      });
    }
  }, [companyId]);

  const columns = [
    {
      title: getTranslation(keys.name),
      dataIndex: 'title',
      render: (value, record): JSX.Element => (
        <Link
          to={(): string => routes.company.open({ id: record.id })}
        >
          {value}
        </Link>
      ),
    },
    {
      title: getTranslation(keys.percent),
      dataIndex: 'percentage',
    },
  ];

  return {
    columns,
    dataSource,
  };
};
