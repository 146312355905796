import * as React from 'react';
import {
  PieChart as RePieChart, Pie, Cell, Legend, ResponsiveContainer,
} from 'recharts';
import { Card } from 'antd';

import { CHART_COLORS } from '@/constants';

import './styles.less';

type DataItem = {
  name: string;
  value: number;
};

interface Props {
  data: Array<DataItem>;
  innerRadius?: number;
  title?: string;
  showPercent?: boolean;
}

const LabelWithPercent = (p) => <text {...p}>{`${Number(p.percent * 100).toFixed(2)}%`}</text>;
const LabelWithValue = (p) => <text {...p}>{Number(p.value).toFixed(2)}</text>;

function PieChart({
  data, innerRadius, title, showPercent,
}: Props): JSX.Element {
  return (
    <Card title={title} className="main-pie-chart">
      <ResponsiveContainer width="100%" height="100%" aspect={4 / 3}>
        <RePieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            paddingAngle={5}
            labelLine={false}
            label={showPercent ? LabelWithPercent : LabelWithValue}
            // label
          >
            {
              data.map((entry, index) => <Cell fill={CHART_COLORS[index % CHART_COLORS.length]} />)
            }
          </Pie>
          <Legend />
          {/* <Tooltip content={<CustomTooltip />} /> */}
        </RePieChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default PieChart;
