import * as React from 'react';

import { routes } from '@/router';
import { renderRouteObj } from '@/helpers';

const CompareAnalyse = (): JSX.Element => (
  <div>
    { renderRouteObj(routes.compareAnalyse.routes) }
  </div>
);

export default CompareAnalyse;
