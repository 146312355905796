interface State {
  ratios: Record<string, any>;
  growth: Record<string, any>;
  statements: Record<string, any>;
}

interface Reducers {
  setRatios: (state: State, payload: Record<string, any>) => State;
  setGrowth: (state: State, payload: Record<string, any>) => State;
  setStatements: (state: State, payload: Record<string, any>) => State;
}

const compareCompaniesData = {
  state: {
    ratios: {},
    growth: {},
    statements: {},
  } as State,
  reducers: {
    setRatios(state, payload) {
      return {
        ...state,
        ratios: payload,
      };
    },
    setGrowth(state, payload) {
      return {
        ...state,
        growth: payload,
      };
    },
    setStatements(state, payload) {
      return {
        ...state,
        statements: payload,
      };
    },
  } as Reducers,
};

export default compareCompaniesData;
