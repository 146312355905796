import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';

import { useLang } from '@/hooks';
import { routes } from '@/router';
import './styles.less';

const Sidebar = (): JSX.Element => {
  const { getTranslation } = useLang();
  const location = useLocation();

  return (
    <div className="compare-sidebar">
      <Menu selectedKeys={[location.pathname]}>
        <Menu.Item key={routes.compareAnalyse.routes.companyCompare.path}>
          <Link to={routes.compareAnalyse.routes.companyCompare.path}>
            { getTranslation(routes.compareAnalyse.routes.companyCompare.titleKey) }
          </Link>
        </Menu.Item>
        <Menu.Item key={routes.compareAnalyse.routes.industryCompare.path}>
          <Link to={routes.compareAnalyse.routes.industryCompare.path}>
            { getTranslation(routes.compareAnalyse.routes.industryCompare.titleKey) }
          </Link>
        </Menu.Item>
        <Menu.Item key={routes.charts.path}>
          <Link to={routes.charts.path}>
            { getTranslation(routes.charts.titleKey) }
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Sidebar;
