import * as React from 'react';
import { Provider } from 'react-redux';

import { Router } from '@/router';
import store from '@/store';
import '@/styles/all.less';

const App = (): JSX.Element => (
  <Provider store={store}>
    <Router />
  </Provider>
);

export default App;
