import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as React from 'react';
import ReactExport from 'react-data-export';

interface Props {
  columns: Array<any>;
  data: Array<any>;
}

export const DataExport = ({ columns, data }: Props) => {
  const dataset = [];
  for (const d of data) {
    const obj = [];
    for (let j = 0; j < columns.length; j++) {
      const val = d[columns[j].dataIndex];
      if (typeof val === 'object' && val !== null) {
        obj.push({ value: val.title, style: { fill: { fgColor: { rgb: 'FFF86B00' } }, font: { sz: '12', bold: true } } });
      } else {
        obj.push({ value: val || '', style: { font: { sz: '12' } } });
      }
    }
    dataset.push(obj);
  }
  const columnSet = [];
  for (const c of columns) {
    columnSet.push({ title: typeof c.title === 'string' ? c.title : c.dataIndex, width: { wpx: 80 }, fill: { fgColor: { rgb: 'FFF86B00' } } });
  }
  const multiDataSet = [
    {
      columns: columnSet,
      data: dataset,
    },
  ];
  return (
    <ReactExport.ExcelFile
      element={<Button type="primary" icon={<DownloadOutlined />} size="large">Download</Button>}
    >
      <ReactExport.ExcelSheet dataSet={multiDataSet} name="Companies" />
    </ReactExport.ExcelFile>
  );
};
