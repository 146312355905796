import * as React from 'react';
import {
  Tag, Button, Col, Row, Card,
} from 'antd';

import { useLang } from '@/hooks';

import { Props, useContainer } from './container';

function SelectedCompanies({
  onCompareCompaniesClick, onAddCompaniesClick, onCompanyUnselect, setSelectedCompanies,
}: Props): JSX.Element {
  const { getTranslation, keys } = useLang();
  const { unselectCompany, companies } = useContainer({ onCompanyUnselect, setSelectedCompanies });

  return (
    <Card>
      <Row>
        <Col span={16}>
          {
            companies.map((company) => <Tag key={company.id} closable onClose={() => unselectCompany(company)}>{company.name}</Tag>)
          }
        </Col>
        <Col offset={3} span={4}>
          {
            onCompareCompaniesClick && (
              <Button type="primary" onClick={() => onCompareCompaniesClick(companies)}>{getTranslation(keys.compare_companies)}</Button>
            )
          }
          {
            onAddCompaniesClick && (
              <Button type="primary" onClick={onAddCompaniesClick}>{getTranslation(keys.add_company)}</Button>
            )
          }
        </Col>
      </Row>
    </Card>
  );
}

export default SelectedCompanies;
