import { useState, useEffect } from 'react';

import { useService } from '@/hooks';
import { sessionKeys } from '@/constants';

export function useContainer() {
  const { get } = useService();
  const [rawData, setRawData] = useState<any>({});
  const [filters, setFilters] = useState<any>({});
  const [showLine, setShowLine] = useState(false);
  const [chartData, setChartData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [alertClosed, setAlertClosed] = useState(false);

  const onFiltersSubmit = (filtersValue) => {
    let responseTargetId = filtersValue.sector[0];
    const target: any = {
      url: 'finance/sectors/ratios',
      query: {
        sector: filtersValue.sector,
      },
    };

    if (filtersValue.category) {
      target.query = {
        ...target.query,
        category: filtersValue.category,
      };
    }

    if (filtersValue.sphere) {
      target.url = 'finance/spheres/ratios';
      target.query = {
        ...target.query,
        industry: filtersValue.industries,
        sphere: filtersValue.sphere,
      };

      [responseTargetId] = filtersValue.sphere;
    } else if (filtersValue.industries) {
      target.url = 'finance/industries/ratios';
      target.query = {
        ...target.query,
        industry: filtersValue.industries,
      };

      [responseTargetId] = filtersValue.industries;
    }

    setLoading(true);

    get(target).then(({ data: { results } }) => {
      const selectedIndustry = results.find((item) => String(item.id) === String(responseTargetId));

      setRawData(selectedIndustry);
      setFilters(filtersValue);
      setLoading(false);
    });
  };

  const onAlertClose = () => {
    sessionStorage.setItem(sessionKeys.USER_CLOSED_INDUSTRIES_CHART_ALERT, '1');
  };

  useEffect(() => {
    if (rawData.ratios && filters.ratio) {
      const filteredRatios = [];

      Object.keys(rawData.ratios).forEach((year) => {
        if (filters.years.includes(Number(year))) {
          const rawValue = rawData.ratios[year][filters.ratio][filters.ratioRecord];

          filteredRatios.push({ name: year, value: typeof rawValue === 'string' ? Number(rawValue.replace('%', '')) : rawValue });
        }
      });

      setChartData(filteredRatios);
      setShowLine(filteredRatios.length > 1);
    }
  }, [rawData, filters]);

  useEffect(() => {
    setAlertClosed(!!Number(sessionStorage.getItem(sessionKeys.USER_CLOSED_INDUSTRIES_CHART_ALERT)));
  }, [sessionStorage.getItem(sessionKeys.USER_CLOSED_INDUSTRIES_CHART_ALERT)]);

  return {
    onFiltersSubmit,
    chartData,
    showLine,
    loading,
    onAlertClose,
    alertClosed,
    filters,
  };
}
