import * as React from 'react';

import { MainTable } from '@/components';

import { useContainer, Props } from './container';
import './styles.less';
import { DataExport } from '@/components/CompaniesList/components/Table/DataExport';

const IndustriesList = (props: Props): JSX.Element => {
  const {
    columns, dataSource, isLoading, rowSelection,
  } = useContainer(props);

  return (
    <>
      <div className="data-export-clmn">
        <DataExport columns={columns} data={dataSource} />
      </div>
      <MainTable
        className="industries-table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={isLoading}
        rowSelection={rowSelection}
      />
    </>
  );
};

export default IndustriesList;
