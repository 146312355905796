import * as React from 'react';
import {
  Col, Row, Card, Collapse,
} from 'antd';

import { Sidebar } from '@/layouts/CompareAnalyse'
import { CompaniesList, SelectedCompanies } from '@/components';

import {
  RatiosTable, StatementsTable, DataFilters, SectorRatiosTable, SectorStatementsTable,
} from './components';
import { useContainer } from './container';

function CompareCompany() {
  const { setSelectedCompanies, selectedCompanies, isSectorsTableShown } = useContainer();

  if (selectedCompanies.length > 0) {
    return (
      <>
        <Row>
          <Col span={4}>
            <Sidebar />
          </Col>
          <Col span={20}>
            <Collapse defaultActiveKey="filters">
              <Collapse.Panel key="filters" header="AddIndustry">
                <DataFilters />
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
        <br />
        <SelectedCompanies
          onCompanyUnselect={setSelectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
          onAddCompaniesClick={() => setSelectedCompanies([])}
        />
        <br />
        <Row gutter={10} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col span={isSectorsTableShown ? 17 : 24}>
            <RatiosTable selectedCompanies={selectedCompanies} />
            <br />
            <RatiosTable
              isGrowthShown
              selectedCompanies={selectedCompanies}
            />
            <br />
            <StatementsTable selectedCompanies={selectedCompanies} />
          </Col>
          {
            isSectorsTableShown && (
              <Col span={7}>
                <SectorRatiosTable />
                <br />
                <SectorRatiosTable isGrowthShown />
                <br />
                <SectorStatementsTable />
              </Col>
            )
          }
        </Row>
      </>
    );
  }

  return <CompaniesList withCompare onCompareCompaniesClick={setSelectedCompanies} />;
}

export default CompareCompany;
