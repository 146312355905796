import * as React from 'react';
import { Collapse } from 'antd';

import { CompaniesFilterWithRatios } from '@/components';

import { Charts } from './components';
import { useContainer } from './container';

function CompaniesChart(): JSX.Element {
  const { onFilterSubmit, ratios, filterValues } = useContainer();

  return (
    <div>
      <Collapse defaultActiveKey="filters">
        <Collapse.Panel key="filters" header="Filters">
          <CompaniesFilterWithRatios onFormSubmit={onFilterSubmit} />
        </Collapse.Panel>
      </Collapse>
      <Charts ratios={ratios} filterValues={filterValues} />
    </div>
  );
}

export default CompaniesChart;
