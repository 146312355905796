import general from './general';
import lang from './lang';
import company from './company';
import compareCompaniesFilter from './compareCompaniesFilter';
import compareCompaniesData from './compareCompaniesData';
import compareIndustries from './compareIndustries';
import user from './user';

export default {
  general,
  lang,
  company,
  compareCompaniesFilter,
  compareCompaniesData,
  compareIndustries,
  user,
};
