import * as React from 'react';
import { Alert, Collapse } from 'antd';

import { IndustrySelector, SelectedIndustries, CompaniesFilterWithRatios } from '@/components';
import { useLang } from '@/hooks';

import { useContainer } from './container';
import { Charts } from './components';

function CompareCharts(): JSX.Element {
  const {
    onFilterSubmit, onAlertClose, alertClosed, selectedIndustries, filtersAndCompany,
  } = useContainer();
  const { getTranslation, keys } = useLang();

  return (
    <div>
      <Collapse defaultActiveKey="filters">
        <Collapse.Panel key="filters" header="Add Industry">
          <IndustrySelector />
        </Collapse.Panel>
      </Collapse>
      <br />
      <SelectedIndustries />
      <br />
      <Collapse defaultActiveKey="select-company">
        <Collapse.Panel key="select-company" header="Select Company">
          <CompaniesFilterWithRatios onFormSubmit={onFilterSubmit} />
        </Collapse.Panel>
      </Collapse>
      <br />
      {
            !alertClosed && (
            <Alert
              message={getTranslation(keys.alert_message_compare_chart)}
              type="warning"
              closable
              onClose={onAlertClose}
            />
            )
        }
      <br />
      <Charts selectedIndustries={selectedIndustries} filtersAndCompany={filtersAndCompany} />
    </div>
  );
}

export default CompareCharts;
