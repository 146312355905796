import * as React from 'react';
import {
  Form, Row, Button, Col, Select,
} from 'antd';

import { useLang } from '@/hooks';

import { useContainer } from './container';

function Filter({ onFormSubmit, loading }: { onFormSubmit: (data) => void; loading: boolean }): JSX.Element {
  const { getTranslation, keys } = useLang();
  const {
    form,
    onSubmit,
    selectedRatio,
    selectedRatioRecord,
    onRatioChange,
    setSelectedRatioRecord,
    filterOptions,
    formState,
    onFormChange,
  } = useContainer({ onFormSubmit });

  return (
    <Form
      name="industries_filters"
      layout="vertical"
      className="industries-filters"
      form={form}
      onValuesChange={onFormChange}
    >
      <Row gutter={10}>
        <Col span={6}>
          <Form.Item label={getTranslation(keys.sector)} name="sector" rules={[{ required: true }]}>
            <Select>
              {
                filterOptions.sector.map((item) => (
                  <Select.Option value={item.id} key={`${item.id}-${item.title}`}>
                    { item.title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={getTranslation(keys.industries)} name="industries">
            <Select disabled={!formState.sector || formState.sector.length !== 1}>
              {
                filterOptions.industries.map((item) => (
                  <Select.Option value={item.id} key={`${item.id}-${item.title}`}>
                    { item.title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={getTranslation(keys.sphere)} name="sphere">
            <Select disabled={!formState.industries || formState.industries.length !== 1}>
              {
                filterOptions.sphere.map((item) => (
                  <Select.Option value={item.id} key={`${item.id}-${item.title}`}>
                    { item.title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={getTranslation(keys.category)}
            name="category"
          >
            <Select mode="multiple">
              {
                filterOptions.category.map((item) => (
                  <Select.Option value={item.id} key={`${item.id}-${item.title}`}>
                    { item.title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={4}>
          <Form.Item label={getTranslation(keys.years)} name="years" required rules={[{ required: true }]}>
            <Select mode="multiple">
              {
                filterOptions.years.map((year) => (
                  <Select.Option value={year} key={year}>
                    { year }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={getTranslation(keys.ratios)} name="ratio" required rules={[{ required: true }]}>
            <Select value={selectedRatio} onChange={(newVal) => onRatioChange(newVal)}>
              {
                filterOptions.ratios.map((ratio) => (
                  <Select.Option value={ratio} key={ratio}>
                    { ratio }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={getTranslation(keys.record)} name="ratioRecord" required rules={[{ required: true }]}>
            <Select value={selectedRatioRecord} onChange={(newVal) => setSelectedRatioRecord(newVal)}>
              {
                filterOptions.ratioRecords.map((ratio) => (
                  <Select.Option value={ratio} key={ratio}>
                    { ratio }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
          loading={loading}
        >
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Filter;
