import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export function useContainer() {
  const filterData = useSelector((st) => st.compareCompaniesFilter);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [isSectorsTableShown, setIsSectorsTableShown] = useState(false);

  useEffect(() => {
    setIsSectorsTableShown(filterData.sectorId);
  }, [filterData.sectorId]);

  return {
    selectedCompanies,
    setSelectedCompanies,
    isSectorsTableShown,
  };
}
