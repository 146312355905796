import { useState, useCallback } from 'react';

export interface Props {
  withCompare?: boolean;
  onCompareCompaniesClick?: (selectedCompanies) => void;
}

export const useContainer = () => {
  const [filterData, setFilterData] = useState<Record<any, any>>();
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);

  const onFilterReset = useCallback(() => {
    setFilterData(null);
  }, []);

  return {
    filterData,
    setFilterData,
    onFilterReset,
    setSelectedCompanies,
    selectedCompanies,
  };
};
