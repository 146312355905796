import * as React from 'react';
import {
  Form,
  Input,
  Select,
  Popover,
  Button,
  Row,
  Col,
  AutoComplete,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { useLang } from '@/hooks';
import { useContainer, Props } from './container';

const Filters = (props: Props): JSX.Element => {
  const { getTranslation, keys } = useLang();
  const {
    filterData, form, onSubmit, onReset, onAutocomplete, companiesForAutocomplete, onIndustryChange,
  } = useContainer(props);

  return (
    <Form
      name="companies_filters"
      layout="vertical"
      className="companies-filters"
      form={form}
    >
      <Row gutter={10}>
        <Col span={16}>
          <Form.Item label={getTranslation(keys.company_name_or_id)} name="q">
            <AutoComplete
              onSearch={onAutocomplete}
              options={companiesForAutocomplete}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={getTranslation(keys.category)} name="category">
            <Select mode="multiple">
              {
                filterData.categories.map(({ title, id }) => (
                  <Select.Option value={id} key={id}>
                    { title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={8}>
          <Form.Item label={getTranslation(keys.sector)} name="sector">
            <Select mode="multiple" onChange={(selected) => onIndustryChange(selected, 'sector')}>
              {
                filterData.sectors.map(({ title, id }) => (
                  <Select.Option value={id} key={id}>
                    { title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={getTranslation(keys.industry)} name="industry">
            <Select mode="multiple" onChange={(selected) => onIndustryChange(selected, 'industries')}>
              {
                filterData.industries.map(({ title, id }) => (
                  <Select.Option value={id} key={id}>
                    { title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={getTranslation(keys.sphere)} name="sphere">
            <Select mode="multiple">
              {
                filterData.spheres.map(({ title, id }) => (
                  <Select.Option value={id} key={id}>
                    { title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={10} align="bottom"> */}
      {/*  <Col span={12}> */}
      {/*    <Form.Item */}
      {/*      label={( */}
      {/*        <div> */}
      {/*          {getTranslation(keys.fs_filters)} */}
      {/*          <Popover content={<div>Info about reports</div>}> */}
      {/*            <InfoCircleOutlined /> */}
      {/*          </Popover> */}
      {/*        </div> */}
      {/*    )} */}
      {/*      name="fs" */}
      {/*    > */}
      {/*      <Select> */}
      {/*        { */}
      {/*          filterData.fsFilters.map(({ title, id }) => ( */}
      {/*            <Select.Option value={id} key={id}> */}
      {/*              { title } */}
      {/*            </Select.Option> */}
      {/*          )) */}
      {/*        } */}
      {/*      </Select> */}
      {/*    </Form.Item> */}
      {/*  </Col> */}
      {/*  <Col span={4}> */}
      {/*    <Form.Item name="operator"> */}
      {/*      <Select> */}
      {/*        <Select.Option value="gt"> */}
      {/*          { '>' } */}
      {/*        </Select.Option> */}
      {/*        <Select.Option value="ls"> */}
      {/*          { '<' } */}
      {/*        </Select.Option> */}
      {/*      </Select> */}
      {/*    </Form.Item> */}
      {/*  </Col> */}
      {/*  <Col span={8}> */}
      {/*    <Form.Item name="fs_value"> */}
      {/*      <Input type="number" /> */}
      {/*    </Form.Item> */}
      {/*  </Col> */}
      {/* </Row> */}
      <Row gutter={10}>
        <Col span={2}>
          <Button onClick={onReset}>Reset</Button>
        </Col>
        <Col span={2}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={onSubmit}
          >
            Filter
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Filters;
