import * as React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Switch } from 'antd';

import { useLang } from '@/hooks';
import { routes } from '@/router';
import { useContainer } from './container';
import './styles.less';

const Sidebar = (): JSX.Element => {
  const { getTranslation, keys } = useLang();
  const {
    companyId, toggleShowConsolidated, showConsolidated, generateKeyForSidebarItem,
  } = useContainer();

  return (
    <Layout.Sider
      width={250}
      className="company-sidebar"
      theme="light"
    >
      <Menu>
        <Menu.Item className="company-sidebar__type-switcher">
          { getTranslation(keys.show_consolidated) }
          <Switch onChange={toggleShowConsolidated} defaultChecked={showConsolidated} />
        </Menu.Item>
        <Menu.Divider />
        <Menu.SubMenu title={getTranslation(keys.profile)}>
          {
            Object.values(routes.company.routes.profile).map((routeItem) => (
              <Menu.Item key={generateKeyForSidebarItem(routeItem)}>
                <Link to={routeItem.open({ id: companyId })}>
                  { getTranslation(routeItem.titleKey) }
                </Link>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
        <Menu.SubMenu title={getTranslation(keys.ratios)}>
          {
            Object.values(routes.company.routes.ratios).map((routeItem) => (
              <Menu.Item key={generateKeyForSidebarItem(routeItem)}>
                <Link to={routeItem.open({ id: companyId })}>
                  { getTranslation(routeItem.titleKey) }
                </Link>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
        <Menu.SubMenu title={getTranslation(keys.growth_rate)}>
          {
            Object.values(routes.company.routes.growthRate).map((routeItem) => (
              <Menu.Item key={generateKeyForSidebarItem(routeItem)}>
                <Link to={routeItem.open({ id: companyId })}>
                  { getTranslation(routeItem.titleKey) }
                </Link>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
        <Menu.SubMenu title={getTranslation(keys.financial_statements)}>
          {
            Object.values(routes.company.routes.financialStatements).map((routeItem) => (
              <Menu.Item key={generateKeyForSidebarItem(routeItem)}>
                <Link to={routeItem.open({ id: companyId })}>
                  { getTranslation(routeItem.titleKey) }
                </Link>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
        <Menu.Item key={keys.compare_company}>
          <Link to={routes.compareAnalyse.routes.companyCompare.open({ companyId })}>
            { getTranslation(keys.compare_company) }
          </Link>
        </Menu.Item>
        <Menu.Item key={keys.charts}>
          <Link to={routes.charts.path}>
            { getTranslation(keys.charts) }
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
