import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export interface Props {
  children: JSX.Element | JSX.Element[];
}

export const useContainer = () => {
  const [activeTabKey, setActiveTabKey] = useState<string>();
  const { companyId } = useSelector((st) => ({ companyId: st.company.companyId }));
  const history = useHistory();

  const openTab = useCallback((path) => {
    history.push(path);
  }, []);

  useEffect(() => {
    setActiveTabKey(history.location.pathname);
  }, [history.location.pathname]);

  return {
    companyId,
    openTab,
    activeTabKey,
  };
};
