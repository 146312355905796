import { useEffect, useState } from 'react';

import { useService, useLang } from '@/hooks';

export function useContainer() {
  const { get } = useService();
  const { getTranslation, keys } = useLang();
  const [rawAnnualCategoryData, setRawAnnualCategoryData] = useState();
  const [annualCategoryData, setAnnualCategoryData] = useState<any>({
    INCOME: [],
    NET_INCOME: [],
    NUMBER_OF_COMPANIES: [],
    NUMBER_OF_EMPLOYEES: [],
  });
  const [annualCategoryTableData, setAnnualCategoryTableData] = useState<any>({
    columns: [],
    data: [],
  });
  const [rawAnnualSectorsData, setRawAnnualSectorsData] = useState();
  const [annualSectorsData, setAnnualSectorsData] = useState<any>({
    INCOME: [],
    NET_INCOME: [],
    NUMBER_OF_COMPANIES: [],
    NUMBER_OF_EMPLOYEES: [],
  });
  const [annualSectorsTableData, setAnnualSectorsTableData] = useState<any>({
    columns: [],
    incomeData: [],
    infoData: [],
  });
  const [rawAnnualSectorYear, setRawAnnualSectorYear] = useState();
  const [annualSectorYear, setAnnualSectorYear] = useState<any>({
    ebitda_margin: [],
    net_profit_margin: [],
    roe: [],
    debt_equity: [],
    total_debt_to_ebitda: [],
    total_debt_to_total_capitalization: [],
    dscr: [],
  });
  const [rawIncomeDynamic, setRawIncomeDynamic] = useState<any>({
    INCOME: {},
    GROWTH_RATES: {},
  });
  const [incomeDynamic, setIncomeDynamic] = useState<any>({
    income: {
      data: [],
      percent: null,
    },
    growthRates: [],
  });

  useEffect(() => {
    get({ url: 'reports/charts/annual-economic' }).then(({ data: { results } }) => {
      setRawAnnualCategoryData(results);
    });
  }, []);

  useEffect(() => {
    if (rawAnnualCategoryData) {
      const preparedData = {};
      Object.entries(rawAnnualCategoryData).forEach(([chartName, chartValue]) => {
        preparedData[chartName] = [];

        Object.entries(chartValue).forEach(([categoryName, categoryData]) => {
          if (categoryName !== 'total') {
            preparedData[chartName].push({
              name: categoryName,
              value: categoryData.value,
            });
          }
        });
      });
      setAnnualCategoryData(preparedData);
    }
  }, [rawAnnualCategoryData]);


  useEffect(() => {
    if (rawAnnualCategoryData) {
      const preparedData = [];
      const tableColumns = [
        {
          title: getTranslation(keys.record),
          dataIndex: 'name',
        },
      ];

      Object.entries(rawAnnualCategoryData).forEach(([recordTranslationKey, record]) => {
        const itemData: any = {};
        itemData.name = getTranslation(recordTranslationKey);

        Object.entries(record).forEach(([recordTitle, recordValue]) => {
          itemData[recordTitle] = recordValue.value ?? recordValue;
        });

        preparedData.push(itemData);
      });

      Object.keys(preparedData[0]).forEach((columnKey) => {
        if (columnKey !== 'name' && columnKey !== 'total') {
          tableColumns.push({
            title: columnKey,
            dataIndex: columnKey,
          });
        }
      });

      tableColumns.push({
        title: getTranslation(keys.total),
        dataIndex: 'total',
      });

      setAnnualCategoryTableData({
        columns: tableColumns,
        data: preparedData,
      });
    }
  }, [rawAnnualCategoryData]);

  useEffect(() => {
    get({ url: 'reports/charts/annual-sectors' }).then(({ data: { results } }) => {
      setRawAnnualSectorsData(results);
    });
  }, []);

  useEffect(() => {
    if (rawAnnualSectorsData) {
      const preparedData: any = {};

      Object.entries(rawAnnualSectorsData).forEach(([chartName, chartValue]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        preparedData[chartName] = chartValue.sectors.map((sector) => ({
          name: sector.title,
          value: sector.value,
        }));
      });

      setAnnualSectorsData(preparedData);
    }
  }, [rawAnnualSectorsData]);

  useEffect(() => {
    if (rawAnnualSectorsData) {
      const preparedData = {};

      Object.entries(rawAnnualSectorsData).forEach(([columnKey, columnValue]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        columnValue.sectors.forEach((sector) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          preparedData[sector.title] = { ...preparedData[sector.title], [columnKey]: columnValue.sectors.find((s) => s.title === sector.title).value };
        });
      });

      const sharedCols = [{ title: getTranslation(keys.record), dataIndex: 'title' }];

      Object.keys(preparedData).forEach((sector) => {
        sharedCols.push({ title: sector, dataIndex: sector });
      });

      const rows = {
        INCOME: {
          title: getTranslation(keys.income),
        },
        NET_INCOME: {
          title: getTranslation(keys.net_income),
        },
        NUMBER_OF_COMPANIES: {
          title: getTranslation(keys.number_of_companies),
        },
        NUMBER_OF_EMPLOYEES: {
          title: getTranslation(keys.number_of_employees),
        },
      };

      Object.entries(preparedData).forEach(([sector, value]) => {
        Object.entries(value).forEach(([record, recordVal]) => {
          rows[record] = { ...rows[record], [sector]: recordVal };
        });
      });

      setAnnualSectorsTableData({
        columns: sharedCols,
        incomeData: [rows.INCOME, rows.NET_INCOME],
        infoData: [rows.NUMBER_OF_COMPANIES, rows.NUMBER_OF_EMPLOYEES],
      });
    }
  }, [rawAnnualSectorsData]);

  useEffect(() => {
    get({ url: 'reports/charts/annual-ratios' }).then(({ data: { results } }) => {
      setRawAnnualSectorYear(results);
    });
  }, []);

  useEffect(() => {
    if (rawAnnualSectorYear) {
      const preparedData = {
        ebitda_margin: [],
        net_profit_margin: [],
        roe: [],
        debt_equity: [],
        total_debt_to_ebitda: [],
        total_debt_to_total_capitalization: [],
        dscr: [],
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      rawAnnualSectorYear.forEach((sector) => {
        preparedData.ebitda_margin.push({ name: sector.title, value: sector.ratios.ebitda_margin, p: `${sector.ratios.ebitda_margin}%` });
        preparedData.net_profit_margin.push({ name: sector.title, value: sector.ratios.net_profit_margin, p: `${sector.ratios.net_profit_margin}%` });
        preparedData.roe.push({ name: sector.title, value: sector.ratios.roe, p: `${sector.ratios.roe}%` });
        preparedData.debt_equity.push({ name: sector.title, value: sector.ratios.debt_equity, p: `${sector.ratios.debt_equity}%` });
        preparedData.total_debt_to_ebitda.push({ name: sector.title, value: sector.ratios.total_debt_to_ebitda, p: `${sector.ratios.total_debt_to_ebitda}%` });
        preparedData.total_debt_to_total_capitalization.push({ name: sector.title, value: sector.ratios.total_debt_to_total_capitalization, p: `${sector.ratios.total_debt_to_total_capitalization}%` });
        preparedData.dscr.push({ name: sector.title, value: sector.ratios.dscr, p: `${sector.ratios.dscr}%` });
      });

      setAnnualSectorYear(preparedData);
    }
  }, [rawAnnualSectorYear]);

  // useEffect(() => {
  //   get({ url: 'reports/charts/annual-income' }).then(({ data: { results } }) => {
  //     setRawIncomeDynamic(results);
  //   });
  // }, []);

  // useEffect(() => {
  //   const preparedIncome = {
  //     data: [],
  //     percent: null,
  //   };
  //
  //   const preparedGrowth = [];
  //
  //   Object.entries(rawIncomeDynamic.INCOME).forEach(([year, value]) => {
  //     if (Number(year)) {
  //       preparedIncome.data.push({ name: year, value });
  //     } else {
  //       preparedIncome.percent = value;
  //     }
  //   });
  //
  //   Object.entries(rawIncomeDynamic.GROWTH_RATES).forEach(([ratio, value]) => {
  //     preparedGrowth.push({ name: ratio, value, p: `${value}%` });
  //   });
  //
  //   setIncomeDynamic({
  //     income: preparedIncome,
  //     growthRates: preparedGrowth,
  //   });
  // }, [rawIncomeDynamic]);

  return {
    annualCategoryData,
    annualCategoryTableData,
    annualSectorsData,
    annualSectorsTableData,
    annualSectorYear,
    incomeDynamic,
  };
}
