import { comparisonType } from '@/@types/general';

interface State {
  comparisonType: comparisonType;
}

interface Reducers {
  setComparisonType: (state: State, payload: comparisonType) => State;
}

const general = {
  state: {
    comparisonType: comparisonType.BY_YEARS,
  } as State,
  reducers: {
    setComparisonType(state, payload) {
      return {
        ...state,
        comparisonType: payload,
      };
    },
  } as Reducers,
};

export default general;
