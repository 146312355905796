import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery, useService } from '@/hooks';

export interface Props {
  onCompareCompaniesClick?: (selectedCompanies) => void;
  onAddCompaniesClick?: () => void;
  onCompanyUnselect?: (selectedCompanies) => void;
  setSelectedCompanies?: (selectedCompanies) => void;
}

export function useContainer({ onCompanyUnselect, ...rest }: Props) {
  const history = useHistory();
  const { query } = useQuery();
  const { get } = useService();
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  function unselectCompany(company) {
    const newSelectedCompaniesIds = JSON.parse(query.selectedCompanies).filter((companyId) => companyId !== company.id);
    const newSelectedCompanies = newSelectedCompaniesIds.map((companyId) => companies.find((comp) => comp.id === companyId));

    if (onCompanyUnselect) {
      onCompanyUnselect(newSelectedCompanies);
    }

    history.push(`${history.location.pathname}?selectedCompanies=${JSON.stringify(newSelectedCompaniesIds)}`);
  }

  useEffect(() => {
    if (query.selectedCompanies) {
      setSelectedCompanies(JSON.parse(query.selectedCompanies));
    } else {
      setSelectedCompanies([]);
      rest.setSelectedCompanies([]);
    }
  }, [query.selectedCompanies, rest.setSelectedCompanies]);

  useEffect(() => {
    Promise.all(selectedCompanies.map((companyId) => get({ url: `finance/companies/${companyId}` })))
      .then((res) => {
        const comps = [];

        res.forEach(({ data: { results: { company } } }) => {
          comps.push({ id: company.id, name: company.org_name });
        });

        setCompanies(comps);
      });
  }, [selectedCompanies]);

  return {
    unselectCompany,
    companies,
  };
}
