import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { useLang, useService, useQuery } from "@/hooks";
import { objKeysOnlyWithVal } from "@/helpers";
import { routes } from "@/router";

export interface Props {
  filterData?: Record<string, any>;
  withCompare?: boolean;
  onSelectedCompaniesChange?: (selectedCompanies) => void;
}

export const useContainer = ({
  filterData,
  onSelectedCompaniesChange,
}: Props) => {
  const history = useHistory();
  const { getTranslation, keys } = useLang();
  const { get } = useService();
  const { query } = useQuery();
  const [dataSource, setDataSource] = React.useState([]);
  const [pagination, setPagination] = React.useState<any>();
  const [apiSorter, setApiSorter] = React.useState<string>(null);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

  const onTableChange = React.useCallback((paging, filters, sorter): void => {
    const { field, order } = sorter;

    if (
      order &&
      (field === "org_name" || field === "sector" || field === "category")
    ) {
      let sorterStr = "";

      if (order === "descend") {
        sorterStr = "-";
      }

      sorterStr += field;

      setApiSorter(sorterStr);
    } else {
      setApiSorter(null);
    }
  }, []);

  const columns = [
    {
      title: getTranslation(keys.id),
      dataIndex: "id_code",
    },
    {
      title: getTranslation(keys.name),
      dataIndex: "org_name",
      sorter: (a, b) => a.org_name - b.org_name,
      render: (value, record): JSX.Element => (
        <Link to={(): string => routes.company.open({ id: record.id })}>
          {value}
        </Link>
      ),
    },
    {
      title: getTranslation(keys.sector),
      dataIndex: "sector",
      sorter: (a, b) => {
        if (a.sector && b.sector) {
          if (a.sector.title < b.sector.title) {
            return -1;
          }
          if (a.sector.title > b.sector.title) {
            return 1;
          }
        }

        return 0;
      },
      render: (value) => {
        if (value) {
          return value.title;
        }

        return null;
      },
    },
    {
      title: getTranslation(keys.revenue),
      dataIndex: "revenue",
      sorter: (a, b) => a.revenue - b.revenue,
    },
    {
      title: getTranslation(keys.total_assets),
      dataIndex: "total_assets",
      sorter: (a, b) => a.total_assets - b.total_assets,
    },
    {
      title: getTranslation(keys.category),
      dataIndex: "category",
      sorter: (a, b) => {
        if (a.category < b.category) {
          return -1;
        }
        if (a.category > b.category) {
          return 1;
        }

        return 0;
      },
    },
    {
      title: getTranslation(keys.employees),
      dataIndex: "empl",
      sorter: (a, b) => a.empl - b.empl,
    },
  ];

  const changePage = React.useCallback(
    (nextPage): void => {
      get({
        url: "finance/companies",
        query: objKeysOnlyWithVal({
          page: nextPage,
          order_by: apiSorter,
          ...filterData,
        }),
      }).then(({ data }) => {
        setDataSource(data.results.map((item) => ({ ...item, key: item.id })));
      });
    },
    [apiSorter, filterData]
  );

  const rowSelection = {
    selectedRowKeys: selectedCompanies,
    renderCell: (checked, record) => {
      const newSelectedCompanies = new Set(selectedCompanies);

      const onBtnClick = (): void => {
        if (newSelectedCompanies.has(record.id)) {
          newSelectedCompanies.delete(record.id);
        } else {
          newSelectedCompanies.add(record.id);
        }

        history.push(
          `${history.location.pathname}?selectedCompanies=${JSON.stringify([
            ...newSelectedCompanies,
          ])}`
        );
        setSelectedCompanies([...newSelectedCompanies]);
      };

      return (
        <div onClick={onBtnClick}>
          {checked ? (
            <Button
              size="small"
              style={{
                backgroundColor: "gray",
                color: "white",
                width: "10rem",
              }}
            >
              {getTranslation(keys.unselect)}
            </Button>
          ) : (
            <Button
              size="small"
              style={{
                backgroundColor: "#008080",
                color: "white",
                width: "10rem",
              }}
            >
              {getTranslation(keys.select)}
            </Button>
          )}
        </div>
      );
    },
  };

  React.useEffect(() => {
    const q = { ...filterData, order_by: apiSorter };
    // dynamic request url
    const querySet =
      `finance${history.location.pathname}${history.location.search}`.replace(
        "industries",
        "industry"
      );
    // fixed component request url
    const allData = `finance${history.location.pathname}/`;
    get({ url: "finance/companies", query: objKeysOnlyWithVal(q) }).then(
      (res) => {
        if (
          querySet.includes("compare/company") ||
          querySet === res.config.url
        ) {
          setDataSource(
            res.data.results.map((item) => ({ ...item, key: item.id }))
          );
        } else {
          if (!history.location.search) {
            setDataSource(
              res.data.results.map((item) => ({ ...item, key: item.id }))
            );
          }
        }
        // if dynamic request url is equal request sended url
        setPagination({
          total:
            querySet === res.config.url ||
            querySet === "finance/compare/company"
              ? res.data.count
              : allData === res.config.url
              ? res.data.count
              : res.config.url.length > querySet.length && res.data.count,
          pageSize: 15,
          onChange: changePage,
        });
      }
    );
  }, [JSON.stringify(filterData), JSON.stringify(apiSorter)]);

  React.useEffect(() => {
    setSelectedCompanies(
      query.selectedCompanies ? JSON.parse(query.selectedCompanies) : []
    );
  }, [query]);

  React.useEffect(() => {
    onSelectedCompaniesChange(selectedCompanies);
  }, [selectedCompanies]);

  return {
    columns,
    dataSource,
    pagination,
    onTableChange,
    rowSelection,
    selectedCompanies,
  };
};
