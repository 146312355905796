import * as React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Legend,
} from 'recharts';
import { Card } from 'antd';

import { CHART_COLORS } from '@/constants';

import './styles.less';

type DataItem = {
  name: string;
  value: number;
  p?: string;
};

interface Props {
  data: Array<DataItem>;
  title?: string;
  showPercent?: boolean;
  withY?: boolean;
  withLegend?: boolean;
  bars?: [{
    dataKey: string;
  }];
}

const CustomTick = (p) => (
  <g transform={`translate(${p.x},${p.y})`}>
    <text
      {...p}
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      transform="rotate(-45)"
    >
      {p.payload.value}
    </text>
  </g>
);

function ColumnChart({
  data, title, showPercent, withY, bars = [{ dataKey: 'value' }], withLegend,
}: Props): JSX.Element {
  const renderCustomizedLabel = ({
    value, index,
  }: any) => (showPercent ? data[index].p : value);

  return (
    <Card title={title} className="column-charts">
      <ResponsiveContainer>
        <BarChart data={data} margin={{ bottom: 100 }}>
          <CartesianGrid strokeDasharray="3" />
          <XAxis dataKey="name" tick={CustomTick} interval={0} />
          { withY && <YAxis /> }
          { withLegend && <Legend /> }
          {
            bars.map((bar, i) => <Bar dataKey={bar.dataKey} label={renderCustomizedLabel} fill={CHART_COLORS[i]} />)
          }
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default ColumnChart;
