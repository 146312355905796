import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useService } from '@/hooks';

export function useContainer() {
  const industriesState = useSelector((st) => st.compareIndustries);
  const { get } = useService();
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [tablesData, setTablesData] = useState({});

  const namedResponse = useCallback((res, target) => ({ data: res.data.results, target }), []);

  useEffect(() => {
    const requestTargets = [];

    industriesState.selectedIndustries.forEach((industry) => {
      if (industry.type === 'category') {
        return null;
      }

      const target: any = {
        type: industry.type,
        target: industry.id,
        title: industry.title,
        url: 'reports/fs-industries',
      };

      if (industry.type === 'sector') {
        target.query = {
          sector: [industry.id],
          category: industry.category ? industry.category.map((cat) => cat.id) : [],
        };
      } else if (industry.type === 'industry') {
        target.query = {
          industry: [industry.id],
          category: industry.category ? industry.category.map((cat) => cat.id) : [],
        };
      } else if (industry.type === 'sphere') {
        target.query = {
          sphere: [industry.id],
          category: industry.category ? industry.category.map((cat) => cat.id) : [],
        };
      }

      requestTargets.push(target);
    });

    setLoading(true);

    Promise.all(requestTargets.map((item) => get({ url: item.url, query: item.query })
      .then((res) => namedResponse(res, item))))
      .then((res) => {
        setRawData(res);
        setLoading(false);
      });
  }, [industriesState.selectedIndustries]);

  useEffect(() => {
    let filtered = {};

    // modify and filer by year
    rawData.forEach((item) => {
      filtered[item.target.target] = item.data[industriesState.year] || {};
    });

    // filter if there is selected statement
    if (industriesState.statementId && industriesState.statementId !== 0) {
      const newFiltered = {};

      Object.keys((filtered)).forEach((sectorId) => {
        newFiltered[sectorId] = {};

        Object.keys(filtered[sectorId]).forEach((statementId) => {
          if (statementId === String(industriesState.statementId)) {
            newFiltered[sectorId][statementId] = filtered[sectorId][statementId];
          }
        });
      });

      filtered = newFiltered;
    } else if (industriesState.statementId === undefined || industriesState.statementId === null) {
      filtered = {};
    }

    const tables = {};

    // prepare table blueprint
    Object.keys(filtered).forEach((sectorId) => {
      Object.keys(filtered[sectorId]).forEach((statementId) => {
        tables[statementId] = {};

        filtered[sectorId][statementId].forEach((record) => {
          tables[statementId] = { ...tables[statementId], [record.title]: { record: record.title } };
        });
      });
    });

    // fill tables with data
    Object.keys(filtered).forEach((sectorId) => {
      Object.keys(filtered[sectorId]).forEach((statementId) => {
        filtered[sectorId][statementId].forEach((record) => {
          tables[statementId][record.title] = {
            ...tables[statementId][record.title],
            [sectorId]: filtered[sectorId][statementId].find((item) => item.title === record.title).value,
            cssType: tables[statementId][record.title].cssType && tables[statementId][record.title].cssType.classNamesForTable ? tables[statementId][record.title].cssType : {
              classNamesForTable: filtered[sectorId][statementId].find((item) => item.title === record.title).param,
            },
          };
        });
      });
    });

    // create columns
    const cols = [];
    cols.push({
      dataIndex: 'record',
      title: 'RECORD',
    });

    rawData.forEach((item) => {
      cols.push({
        dataIndex: item.target.target,
        title: item.target.title,
      });
    });

    setTablesData(tables);
    setColumns(cols);
  }, [rawData, industriesState]);

  return {
    loading,
    columns,
    tablesData,
  };
}
