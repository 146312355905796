import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { comparisonType } from '@/@types/general';
import { useService, useQuery } from '@/hooks';
import { routes } from '@/router';

export interface Props {
  isGrowthRatiosShown: boolean;
  activeRatioName: string;
  onYearsChange: (years) => void;
  onRecordsChange: (years) => void;
}

interface Container {
  years: Array<{
    value: string;
    title: string;
  }>;
  ratios: Array<{
    value: string;
    title: string;
  }>;
  records: Array<{
    value: string;
    title: string;
  }>;
  selectedYears: Array<{
    value: string;
    title: string;
  }> | Record<string, any>;
  selectMultipleYear: boolean;
  selectedRatio: string;
  onYearsChange: (selected) => void;
  onRecordsChange: (selected) => void;
  onRatioChange: (selected) => void;
}

export const useContainer = (props: Props): Container => {
  const { get } = useService();
  const history = useHistory();
  const { query } = useQuery();
  const { general } = useSelector((st) => ({ general: st.general }));
  const [selectMultipleYear, setSelectMultipleYear] = useState(false);
  const [years, setYears] = useState([]);
  const [ratios, setRatios] = useState([]);
  const [records, setRecords] = useState([]);
  const [selectedRatio, setSelectedRatio] = useState<string>();
  const [fullRatios, setFullRatios] = useState({});
  const [selectedYears, setSelectedYears] = useState<any>([]);

  const onYearsChange = (selected): void => {
    if (Array.isArray(selected)) {
      setSelectedYears(years.filter((item) => selected.includes(item.value)));
    } else {
      setSelectedYears([years.find((item) => item.value === selected)]);
    }
  };

  const onRecordsChange = (selected): void => {
    if (selected.length) {
      props.onRecordsChange(selected);
    } else {
      props.onRecordsChange(records.map((item) => item.value));
    }
  };

  const onRatioChange = (selected): void => {
    let selectedRoutes = null;
    const pathname = history.location.pathname.toUpperCase();
    const selectedRatioEndPoint = selectedRatio.replace('/', '|');
    const nextRatioEndPoint = selected.replace('/', '|');
    const nextPathname = pathname.replace(selectedRatioEndPoint, nextRatioEndPoint);

    if (props.isGrowthRatiosShown) {
      selectedRoutes = routes.industries.routes.growthRate;
    } else {
      selectedRoutes = routes.industries.routes.ratios;
    }

    const nextRoute: Record<string, any> = Object.values(selectedRoutes).find((route: any) => route.path.toUpperCase() === nextPathname.toUpperCase());

    history.push(nextRoute.open({ filters: query.filters }));
    setSelectedRatio(selected);
  };

  useEffect(() => {
    const availableRecords = [];

    get({ url: 'finance/years' })
      .then(({ data: { results } }) => {
        setYears(results.map((item) => ({ title: item, value: item })));
      });

    get({ url: 'finance/ratios' })
      .then(({ data: { results } }) => {
        if (props.isGrowthRatiosShown) {
          setRatios(Object.keys(results).filter((ratio) => !ratio.toUpperCase().includes('VERTICAL')).map((ratio) => ({ title: ratio, value: ratio })));
        } else {
          setRatios(Object.keys(results).map((ratio) => ({ title: ratio, value: ratio })));
        }
        setFullRatios(results);
      });

    setRecords(availableRecords.map((item) => ({ title: item, value: item })));
  }, [props.isGrowthRatiosShown]);

  useEffect(() => {
    setSelectedRatio(props.activeRatioName);
  }, [props.activeRatioName]);

  useEffect(() => {
    if (props.activeRatioName && Object.keys(fullRatios).length) {
      const activeRatioName = Object.keys(fullRatios).find((ratio) => ratio.toUpperCase() === props.activeRatioName.toUpperCase());
      setRecords(Object.keys(fullRatios[activeRatioName]).map((record) => ({ title: record, value: record })));
    }
  }, [fullRatios, props.activeRatioName]);

  useEffect(() => {
    setSelectMultipleYear(general.comparisonType === comparisonType.BY_YEARS);

    if (general.comparisonType === comparisonType.BY_INDUSTRY) {
      setSelectedYears((st) => {
        if (st.length) {
          return [st[st.length - 1]];
        }
        return [];
      });
    }
  }, [general.comparisonType]);

  useEffect(() => {
    if (years.length) {
      if (selectMultipleYear) {
        setSelectedYears(years.slice(Math.max(years.length - 2, 1)));
      } else {
        setSelectedYears([years[years.length - 1]]);
      }
    }
  }, [selectMultipleYear, years]);

  useEffect(() => {
    props.onYearsChange(selectedYears);
  }, [selectedYears]);

  useEffect(() => {
    if (records.length) {
      onRecordsChange(records.map((item) => item.value));
    }
  }, [records]);

  return {
    selectMultipleYear,
    years,
    ratios,
    records,
    selectedRatio,
    selectedYears,
    onYearsChange,
    onRecordsChange,
    onRatioChange,
  };
};
