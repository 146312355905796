import { Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { useService } from '@/hooks';

export const useContainer = ({ onFormSubmit }) => {
  const [form] = Form.useForm();
  const { get } = useService();
  const [selectedRatio, setSelectedRatio] = useState<string>();
  const [selectedRatioRecord, setSelectedRatioRecord] = useState<string>();
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [filterOptions, setFilterOptions] = useState({
    sector: [],
    industries: [],
    sphere: [],
    category: [],
    years: [],
    ratios: [],
    ratioRecords: [],
  });

  const onSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      onFormSubmit(values);
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }, [form]);

  const onRatioChange = (newVal) => {
    setSelectedRatio(newVal);
    setSelectedRatioRecord(undefined);
    form.setFieldsValue({ ...form.getFieldsValue, ratioRecord: undefined });

    get({ url: 'finance/ratios' })
      .then(({ data: { results } }) => {
        setFilterOptions((st) => ({
          ...st,
          ratioRecords: Object.keys(results[newVal]),
        }));
      });
  };

  const setDataForFilter = useCallback(({ data: { results } }, type): void => {
    setFilterOptions((st) => ({
      ...st,
      [type]: results,
    }));
  }, []);

  const setIndustriesFilterData = useCallback((sector) => {
    get({ url: 'finance/industries/filters', query: { sector } })
      .then((res) => setDataForFilter(res, 'industries'));
  }, [setDataForFilter]);

  const setSphereFilterData = useCallback((industries) => {
    get({ url: 'finance/spheres/filters', query: { industry: industries } })
      .then((res) => setDataForFilter(res, 'sphere'));
  }, [setDataForFilter]);

  const onFormChange = useCallback((changedValue): void => {
    if (changedValue.sector) {
      form.setFieldsValue({
        ...formState, sector: [changedValue.sector], industries: undefined, sphere: undefined,
      });
      setIndustriesFilterData([changedValue.sector]);
    } else if (changedValue.industries) {
      form.setFieldsValue({ ...formState, industries: [changedValue.industries], sphere: undefined });
      setSphereFilterData([changedValue.industries]);
    } else if (changedValue.sphere) {
      form.setFieldsValue({ ...formState, sphere: [changedValue.sphere] });
    }

    setFormState(form.getFieldsValue());
  }, [formState]);

  useEffect(() => {
    get({ url: 'finance/ratios' })
      .then(({ data: { results } }) => {
        const rawRatios = Object.keys(results);

        setFilterOptions((st) => ({
          ...st,
          ratios: rawRatios,
          ratioRecords: Object.keys(results[rawRatios[0]]),
        }));
        setSelectedRatio(rawRatios[0]);
      });

    get({ url: 'finance/years' })
      .then(({ data: { results } }) => {
        setFilterOptions((st) => ({
          ...st,
          years: results.map((item) => item),
        }));
      });

    get({ url: 'finance/spheres/filters' }).then((res) => setDataForFilter(res, 'sphere'));
    get({ url: 'finance/industries/filters' }).then((res) => setDataForFilter(res, 'industries'));
    get({ url: 'finance/sectors/filters' }).then((res) => setDataForFilter(res, 'sector'));
    get({ url: 'finance/categories' }).then((res) => setDataForFilter(res, 'category'));
  }, []);

  return {
    form,
    onSubmit,
    selectedRatio,
    selectedRatioRecord,
    setSelectedRatio,
    setSelectedRatioRecord,
    onRatioChange,
    filterOptions,
    formState,
    onFormChange,
  };
};
