import * as React from 'react';
import { Divider } from 'antd';

import { useLang } from '@/hooks';

import { useContainer } from './container';
import { Layout } from '../components';

const About = (): JSX.Element => {
  const { getTranslation, keys } = useLang();
  const { companyInfo } = useContainer();

  return (
    <Layout>
      <h1>{getTranslation(keys.about)}</h1>
      <p>{companyInfo.about}</p>
      <Divider />
      <h1>{getTranslation(keys.description)}</h1>
      <p>{companyInfo.desc}</p>
    </Layout>
  );
};

export default About;
