type CompanyInfo = {
  sector: {
    id: number;
    title: string;
  };
  industry: {
    id: number;
    title: string;
  };
  sphere: {
    id: number;
    title: string;
  };
  org_name: string;
  desc: string;
  about: string;
};

interface State {
  companyId: string | number;
  showConsolidated: boolean;
  companyInfo: CompanyInfo;
}

interface Reducers {
  setCompanyId: (state: State, payload: string | number) => State;
  toggleShowConsolidated: (state: State, payload: boolean) => State;
  setCompanyInfo: (state: State, payload: CompanyInfo) => State;
}

const company = {
  state: {
    companyId: null,
    showConsolidated: false,
    companyInfo: {
      sector: {},
      industry: {},
      sphere: {},
    },
  } as State,
  reducers: {
    setCompanyId(state, payload) {
      return {
        ...state,
        companyId: payload,
      };
    },
    toggleShowConsolidated(state, payload) {
      return {
        ...state,
        showConsolidated: payload,
      };
    },
    setCompanyInfo(state, payload) {
      return {
        ...state,
        companyInfo: payload,
      };
    },
  } as Reducers,
};

export default company;
