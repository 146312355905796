type Industry = { type: 'sector' | 'industry' | 'sphere'; id: string; title: string };
type StatementId = 0 | 1 | 2 | 3;

interface State {
  selectedIndustries: Industry[];
  ratio?: string;
  ratioRecords: string[];
  growth?: string;
  growthRecords: string[];
  statementId?: StatementId;
  year?: string;
}

interface Reducers {
  addIndustry: (state: State, payload: Industry[]) => State;
  removeIndustry: (state: State, payload: Industry) => State;
  clearIndustries: (state: State) => State;
  setRatio: (state: State, payload?: string) => State;
  setRatioRecords: (state: State, payload?: string[]) => State;
  setGrowth: (state: State, payload?: string) => State;
  setGrowthRecords: (state: State, payload?: string[]) => State;
  setYear: (state: State, payload: string) => State;
  setStatementId: (state: State, payload: StatementId) => State;
  resetFilters: (state: State) => State;
}

const company = {
  state: {
    selectedIndustries: [],
    ratioRecords: [],
    growthRecords: [],
    ratio: 'ALL',
  } as State,
  reducers: {
    addIndustry(state, payload) {
      const filteredPayload = payload.filter((item) => {
        const isInState = state.selectedIndustries.find((industry) => industry.id === item.id && industry.type === item.type);

        return !isInState;
      });

      return {
        ...state,
        selectedIndustries: [...state.selectedIndustries, ...filteredPayload],
      };
    },
    removeIndustry(state, payload) {
      const newIndustries = state.selectedIndustries.filter((industry) => !(industry.id === payload.id && industry.type === payload.type));

      return {
        ...state,
        selectedIndustries: newIndustries,
      };
    },
    clearIndustries(state) {
      return {
        ...state,
        selectedIndustries: [],
      };
    },
    setRatio(state, payload) {
      return {
        ...state,
        ratio: payload || 'CLEARED',
        ratioRecords: [],
      };
    },
    setRatioRecords(state, payload) {
      return {
        ...state,
        ratioRecords: payload,
      };
    },
    setGrowth(state, payload) {
      return {
        ...state,
        growth: payload,
        growthRecords: [],
      };
    },
    setGrowthRecords(state, payload) {
      return {
        ...state,
        growthRecords: payload,
      };
    },
    setYear(state, payload) {
      return {
        ...state,
        year: payload,
      };
    },
    setStatementId(state, payload) {
      return {
        ...state,
        statementId: payload,
      };
    },
    resetFilters(state) {
      return {
        selectedIndustries: state.selectedIndustries,
        ratioRecords: [],
        growthRecords: [],
        ratio: 'ALL',
      };
    },
  } as Reducers,
};

export default company;
