import * as React from 'react';
import {
  Tag, Button, Col, Row, Card,
} from 'antd';

import { useLang } from '@/hooks';

import { Props, useContainer } from './container';

function SelectedIndustries({
  onCompareIndustriesClick, onAddIndustriesClick,
}: Props): JSX.Element {
  const { getTranslation, keys } = useLang();
  const { unselectIndustry, industries } = useContainer();

  if (!industries.length) return null;

  return (
    <Card>
      <Row>
        <Col span={16}>
          {
            industries.filter((industry) => industry.type !== 'category').map((industry) => <Tag key={industry.id} closable onClose={() => unselectIndustry(industry)}>{industry.title}</Tag>)
          }
        </Col>
        <Col offset={3} span={4}>
          {
            onCompareIndustriesClick && (
              <Button type="primary" onClick={onCompareIndustriesClick}>{getTranslation(keys.compare_industries)}</Button>
            )
          }
          {
            onAddIndustriesClick && (
              <Button type="primary" onClick={onAddIndustriesClick}>{getTranslation(keys.add_industries)}</Button>
            )
          }
        </Col>
      </Row>
    </Card>
  );
}

export default SelectedIndustries;
