import * as React from 'react';
import { Card } from 'antd';

import { useLang } from '@/hooks';

import { Table, Filters } from './components';
import { useContainer } from './container';

const FinancialStatements = (): JSX.Element => {
  const {
    years, selectedYears, setSelectedYears, rawData, activeStatement, isLoading,
  } = useContainer();
  const { getTranslation, keys } = useLang();

  return (
    <Card>
      <Filters
        availableYears={years}
        selectedYears={selectedYears}
        setSelectedYears={setSelectedYears}
      />
      <br />
      <div>{ getTranslation(keys.individual_financial_statement) }</div>
      <br />
      <Table
        selectedYears={selectedYears}
        rawData={rawData}
        activeStatement={activeStatement}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default FinancialStatements;
