import * as React from 'react';
import {
  Form, Row, AutoComplete, Button, Col, Select,
} from 'antd';

import { useLang } from '@/hooks';

import { useContainer } from './container';

function CompaniesFilterWithRatios({ onFormSubmit, maxSelectedYears, withCategory }: { onFormSubmit: (data) => void; maxSelectedYears?: number; withCategory?: boolean }): JSX.Element {
  const { getTranslation, keys } = useLang();
  const {
    form,
    onAutocomplete,
    companiesForAutocomplete,
    onSubmit,
    ratios,
    ratioRecords,
    selectedRatio,
    selectedRatioRecord,
    onRatioChange,
    setSelectedRatioRecord,
    years,
    onFormValuesChange,
    disableYears,
    categories,
  } = useContainer({ onFormSubmit, maxSelectedYears, withCategory });

  return (
    <Form
      name="companies_filters"
      layout="vertical"
      className="companies-filters"
      form={form}
      onValuesChange={onFormValuesChange}
    >
      <Row gutter={10}>
        <Col span={withCategory ? 16 : 24}>
          <Form.Item label={getTranslation(keys.company_name_or_id)} name="q" required rules={[{ required: true }]}>
            <AutoComplete
              onSearch={onAutocomplete}
              options={companiesForAutocomplete}
            />
          </Form.Item>
        </Col>
        {
          withCategory && (
          <Col span={8}>
            <Form.Item label={getTranslation(keys.category)} name="category">
              <Select mode="multiple">
                {
                      categories.map((category) => (
                        <Select.Option value={category.id} key={category.id}>
                          { category.title }
                        </Select.Option>
                      ))
                    }
              </Select>
            </Form.Item>
          </Col>
          )
        }
      </Row>
      <Row gutter={10}>
        <Col span={4}>
          <Form.Item label={getTranslation(keys.years)} name="years" required rules={[{ required: true }]}>
            <Select mode="multiple" disabled={disableYears}>
              {
                years.map((year) => (
                  <Select.Option value={year} key={year}>
                    { year }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={getTranslation(keys.ratios)} name="ratio" required rules={[{ required: true }]}>
            <Select value={selectedRatio} onChange={(newVal) => onRatioChange(newVal)}>
              {
                ratios.map((ratio) => (
                  <Select.Option value={ratio} key={ratio}>
                    { ratio }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={getTranslation(keys.record)} name="ratioRecord" required rules={[{ required: true }]}>
            <Select value={selectedRatioRecord} onChange={(newVal) => setSelectedRatioRecord(newVal)}>
              {
                ratioRecords.map((ratio) => (
                  <Select.Option value={ratio} key={ratio}>
                    { ratio }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
        >
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CompaniesFilterWithRatios;
