import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useService } from '@/hooks';

export const useContainer = () => {
  const { lang: { initialize } } = useDispatch();
  const { get } = useService();

  useEffect(() => {
    get({ url: 'm/translations' })
      .then(({ data: { results } }) => {
        initialize(results);
      });
  }, []);

  return {};
};
