import * as React from 'react';

import { MainTable } from '@/components';

import { useContainer, Props } from './container';
import './styles.less';
import { DataExport } from './DataExport';


const Table = (props: Props): JSX.Element => {
  const { withCompare } = props;
  const {
    columns, dataSource, pagination, onTableChange, rowSelection,
  } = useContainer(props);
  return (
    <>
      <div className="data-export-clmn">
        <DataExport columns={columns} data={dataSource} />
      </div>
      <MainTable
        className="companies-table"
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        onChange={onTableChange}
        rowSelection={withCompare && rowSelection}
      />
    </>
  );
};

export default Table;
