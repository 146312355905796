export const API_HOST = process.env.API_HOST || 'https://ebita.ge/';
export const NEED_AUTH_STATUS_CODE = 401;
export const PACKAGE_EXPIRED_STATUS_CODE = 403;


export const translationKeys = {
  home: 'HOME',
  companies: 'COMPANIES',
  industries: 'INDUSTRIES',
  compare_analyse: 'COMPARE_ANALYSE',
  market_analyse: 'MARKET_ANALYSE',
  benchmark: 'BENCHMARK',
  rating: 'RATING',
  prices: 'PRICES',
  charts: 'CHARTS',
  sign_in: 'SIGN_IN',
  log_in: 'LOG_IN',
  company_name_or_id: 'COMPANY_NAME_OR_ID',
  legal_form: 'LEGAL_FORM',
  category: 'CATEGORY',
  industry: 'INDUSTRY',
  sector: 'SECTOR',
  sphere: 'SPHERE',
  report_articles: 'REPORT_ARTICLES',
  id: 'ID',
  name: 'NAME',
  incomes: 'INCOMES',
  profile: 'PROFILE',
  ratios: 'RATIOS',
  growth_rates: 'GROWTH_RATES',
  growth_rate: 'GROWTH_RATE',
  asset_management_ratio: 'ASSET_MANAGEMENT_RATIO',
  liquidity_ratio: 'LIQUIDITY_RATIO',
  profitability_ratios: 'PROFITABILITY_RATIOS',
  cash_flow_ratios: 'CASH_FLOW_RATIOS',
  solvency_ratios: 'SOLVENCY_RATIOS',
  coverage_ratios: 'COVERAGE_RATIOS',
  vertical_analysis: 'VERTICAL_ANALYSIS',
  financial_statements: 'FINANCIAL_STATEMENTS',
  balance: 'BALANCE',
  profit_loss: 'PROFIT_LOSS',
  cash_flow: 'CASH_FLOW',
  capital_movement: 'CAPITAL_MOVEMENT',
  compare_company: 'COMPARE_COMPANY',
  compare_industry: 'COMPARE_INDUSTRY',
  single_company_carts: 'SINGLE_COMPANY_CARTS',
  industry_charts: 'INDUSTRY_CHARTS',
  compare_company_charts: 'COMPARE_COMPANY_CHARTS',
  show_consolidated: 'SHOW_CONSOLIDATED',
  description: 'DESCRIPTION',
  about: 'ABOUT',
  founders_directors: 'FOUNDERS_DIRECTORS',
  affiliates_companies: 'AFFILIATES_COMPANIES',
  audit: 'AUDIT',
  contacts: 'CONTACTS',
  surname: 'SURNAME',
  percent: 'PERCENT',
  all: 'ALL',
  by_year: 'BY_YEAR',
  by_industry: 'BY_INDUSTRY',
  year: 'YEAR',
  records: 'RECORDS',
  company: 'COMPANY',
  record: 'RECORD',
  columns: 'COLUMNS',
  years: 'YEARS',
  values: 'VALUES',
  sub_categories: 'SUB_CATEGORIES',
  employees: 'EMPLOYEES',
  key_ratios: 'KEY_RATIOS',
  leverage_ratios: 'LEVERAGE_RATIOS',
  vertical_analysis_of_balance_sheet: 'VERTICAL_ANALYSIS_OF_BALANCE_SHEET',
  vertical_analysis_of_profit_loss: 'VERTICAL_ANALYSIS_OF_PROFIT_LOSS',
  title: 'TITLE',
  income: 'INCOME',
  total_assets: 'TOTAL_ASSETS',
  individual_financial_statement: 'INDIVIDUAL_FINANCIAL_STATEMENT',
  consolidated_financial_statement: 'CONSOLIDATED_FINANCIAL_STATEMENT',
  revenue: 'REVENUE',
  fs_filters: 'FS_FILTERS',
  type_of_opinion: 'TYPE_OF_OPINION',
  founders: 'FOUNDERS',
  directors: 'DIRECTORS',
  ebitda_margin: 'EBITDA_MARGIN',
  net_profit_margin: 'NET_PROFIT_MARGIN',
  debt_equity: 'DEBT_EQUITY',
  roe: 'ROE',
  sectorSlashRatio: 'SECTOR / RATIO',
  compare_companies: 'COMPARE_COMPANIES',
  add_company: 'ADD_COMPANY',
  select: 'SELECT',
  unselect: 'UNSELECT',
  add: 'ADD',
  compare_industries: 'COMPARE_INDUSTRIES',
  add_industries: 'ADD_INDUSTRIES',
  back: 'BACK',
  charts_main_displayed_year_title: 'CHARTS_MAIN_DISPLAYED_YEAR_TITLE',
  net_income: 'NET_INCOME',
  number_of_employees: 'NUMBER_OF_EMPLOYEES',
  number_of_companies: 'NUMBER_OF_COMPANIES',
  total: 'TOTAL',
  sectors_data: 'SECTORS_DATA',
  charts_main_sector_data_by_year: 'CHARTS_MAIN_SECTOR_DATA_BY_YEAR',
  charts_main_ebitda_margin: 'CHARTS_MAIN_EBITDA_MARGIN',
  charts_main_net_profit_margin: 'CHARTS_MAIN_NET_PROFIT_MARGIN',
  charts_main_roe: 'CHARTS_MAIN_ROE',
  charts_main_debt_equity: 'CHARTS_MAIN_DEBT_EQUITY',
  charts_main_total_debt_to_ebitda: 'CHARTS_MAIN_TOTAL_DEBT_TO_EBITDA',
  charts_main_total_debt_to_total_capitalization: 'CHARTS_MAIN_TOTAL_DEBT_TO_TOTAL_CAPITALIZATION',
  charts_main_dscr: 'CHARTS_MAIN_DSCR',
  charts_main_income_dynamic_by_sector_and_year: 'CHARTS_MAIN_INCOME_DYNAMIC_BY_SECTOR_AND_YEAR',
  income_dynamic: 'INCOME_DYNAMIC',
  companies_chart: 'COMPANIES_CHART',
  industries_chart: 'INDUSTRIES_CHART',
  alert_message_industries_chart: 'ALERT_MESSAGE_INDUSTRIES_CHART',
  alert_message_compare_chart: 'ALERT_MESSAGE_COMPARE_CHART',
  email: 'EMAIL',
  password: 'PASSWORD',
  submit: 'SUBMIT',
  sign_up: 'SIGN_UP',
  log_out: 'LOG_OUT',
  need_authorization_title: 'NEED_AUTHORIZATION_TITLE',
  need_authorization_text: 'NEED_AUTHORIZATION_TEXT',
  user_expired_title: 'USER_EXPIRED_TITLE',
  user_expired_text: 'USER_EXPIRED_TEXT',
  contact_us: 'CONTACT_US',
  buy_now: 'BUY_NOW',
  register: 'REGISTER',
  first_name: 'FIRST_NAME',
  last_name: 'LAST_NAME',
  company_name: 'COMPANY_NAME',
  id_number: 'ID_NUMBER',
  phone: 'PHONE',
  university_name: 'UNIVERSITY_NAME',
  receive_invoice: 'RECEIVE_INVOICE',
  confirm_password: 'CONFIRM_PASSWORD',
  contact_sales: 'CONTACT_SALES',
  or: 'OR',
  prices_page_title: 'PRICES_PAGE_TITLE',
  message: 'MESSAGE',
  document: "DOCUMENT"
};

export const classNamesForTable = {
  1: 'gray-bg',
  11: 'gray-bg gray-bg__underline',
  12: 'gray-bg gray-bg__underline gray-bg__bold',
  2: 'bold',
  21: 'bold bold__underline',
  3: 'underline',
};

export const financialStatementsTypes = {
  1: {
    type: 'balance',
    titleKey: translationKeys.balance,
    key: 1,
  },
  2: {
    type: 'profit_loss',
    titleKey: translationKeys.profit_loss,
    key: 2,
  },
  3: {
    type: 'cash_flow',
    titleKey: translationKeys.cash_flow,
    key: 3,
  },
  0: {
    type: 'all',
    titleKey: translationKeys.all,
    key: 0,
  },
};

export const CHART_COLORS = [
  '#4491A0', '#58B6C0', '#3494BA', '#75BDA7', '#3B7C68', '#495456', '#84ACB6', '#2683C6', '#1F5970', '#7A8C8E', '#2E737A',
  '#0064cb', '#00C49F', '#FFBB28', '#ff4242', '#4D8000',
  '#ff900b', '#009242', '#b133ff', '#808000', '#00B3E6',
  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF',
];

export const sessionKeys = {
  USER_CLOSED_INDUSTRIES_CHART_ALERT: 'USER_CLOSED_INDUSTRIES_CHART_ALERT',
  USER_CLOSED_COMPARE_CHART_ALERT: 'USER_CLOSED_COMPARE_CHART_ALERT',
  USER_TOKEN: 'USER_TOKEN',
  USER_REFRESH_TOKEN: 'USER_REFRESH_TOKEN',
  WHEN_SEND_REFRESH_TOKEN: 'WHEN_SEND_REFRESH_TOKEN',
  USER_SELECTED_LANGUAGE: 'USER_SELECTED_LANGUAGE',
};

export const userTypes = {
  STUDENT: 0,
  STANDARD: 1,
  BUSINESS: 2,
};

export const sessionDuration = 1000;
